import { createSlice } from '@reduxjs/toolkit';

export const initialState: boolean = false;

export const uiSlice = createSlice({
	name: 'uiState',
	initialState,
	reducers: {
		setLoading: (state, action) => {
			return action.payload;
		},
	},
});

export const { setLoading } = uiSlice.actions;

export default uiSlice.reducer;
