import { createClient } from '@supabase/supabase-js';

const supabaseUrl: string =
	process.env.NODE_ENV === 'production'
		? (process.env.REACT_APP_SUPABASE_URL as string)
		: (process.env.REACT_APP_SUPABASE_URL_DEV as string);
const supabaseAnonKey: string =
	process.env.NODE_ENV === 'production'
		? (process.env.REACT_APP_SUPABASE_ANON_KEY as string)
		: (process.env.REACT_APP_SUPABASE_ANON_KEY_DEV as string);

export const supabase = createClient(supabaseUrl, supabaseAnonKey);
