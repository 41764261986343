import { useState, useEffect } from 'react';

const useScreenSize = () => {
	const [width, setWidth] = useState<number>(window.innerWidth);
	const [height, setHeight] = useState<number>(window.innerHeight);

	useEffect(() => {
		setWidth(window.innerWidth);
		setHeight(window.innerHeight);

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const handleResize = () => {
		setWidth(window.innerWidth);
		setHeight(window.innerHeight);
	};

	return { width, height };
};
export default useScreenSize;
