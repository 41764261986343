import { supabase } from '../database/supabase';

export const getAllUsersCountService = async () => {
	return await supabase.from('users').select('*', { count: 'exact' });
};

export const getAllUsersService = async (
	order: 'asc' | 'desc',
	page: number,
	items_per_page: number
) => {
	return await supabase
		.from('users')
		.select()
		.order('first_name', { ascending: order === 'asc' })
		.range((page - 1) * items_per_page, page * items_per_page - 1);
};

export const getUserById = async (id: string) => {
	return await supabase.from('users').select().eq('id', id);
};

/* --- Tipados de las funciones de Supabase --- */

// Conteo de todos los usuarios
export type AllUsersCountResponse = Awaited<
	ReturnType<typeof getAllUsersCountService>
>;

// Petición de todos los usuarios
export type GetAllUsersResponse = Awaited<
	ReturnType<typeof getAllUsersService>
>;

// Petición de un usuario por Id
export type GetUserByIdResponse = Awaited<ReturnType<typeof getUserById>>;
