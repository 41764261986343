import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import Loader from '../../components/loader/Loader';
import { PublicRoutes } from '../../models';
import styles from './_noticePage.css';
import {
	getNewsWithRelations,
	getNoticeByIdController,
} from '../../services/news.controller';
import dayjs from 'dayjs';

const NoticePage = () => {
	const { id } = useParams();

	const [isLoading, setIsLoading] = useState(false);

	const allNews = useAppSelector((state: RootState) => state.news.data);
	const notice = useAppSelector((state: RootState) => state.notice.data);

	const newsIds = allNews?.map((notice) => notice.id);
	const lastNews = newsIds?.includes(id ? +id : 1)
		? allNews?.filter((notice) => notice.id !== (id ? +id : 1))
		: allNews?.slice(0, 3);

	const dispatch = useAppDispatch();

	const navigate = useNavigate();

	useEffect(() => {
		setIsLoading(true);
		getNoticeByIdController(dispatch, id ? +id : 1).catch((e) =>
			console.error(e)
		);

		getNewsWithRelations(dispatch, {
			page: 1,
			items_per_page: 4,
			sort_by: 'created_at',
			order: 'desc',
		})
			.catch((e) => console.error(e))
			.finally(() => setIsLoading(false));
	}, [id]);

	const handlGoToNotice = (id: number) => {
		navigate(`/${PublicRoutes.NEWS}/${id}`);
	};

	return (
		<div className={styles.container_notice}>
			{isLoading && <Loader />}
			<section className={styles.main}>
				<article className={styles.article}>
					<img src={notice?.hero_img_url ?? ''} alt={notice?.title ?? ''} />
					<div className='today-date'>
						<br />

						{notice?.created_at && (
							<div className={styles.date}>
								{dayjs(notice.created_at).format('DD/MM/YYYY')}
							</div>
						)}
					</div>
					<h2 className={styles.titulo}>{notice?.title ?? ''}</h2>

					{notice?.news_topics?.map((topic, index) => (
						<div key={index}>
							{topic?.subtitle && (
								<h3>
									<strong>{topic.subtitle}</strong>
								</h3>
							)}
							<hr />

							{topic?.content && (
								<p className={styles.parrafo}>{topic.content}</p>
							)}
							<br />

							{topic?.img_url && topic.img_url.includes('[') ? (
								<>
									{(JSON.parse(topic.img_url) as string[]).map((img, index) => (
										<img
											src={img ?? ''}
											alt=''
											className={styles.imgTopic}
											key={index}
										/>
									))}
								</>
							) : (
								<img
									src={topic?.img_url ?? ''}
									alt=''
									className={styles.imgTopic}
								/>
							)}
						</div>
					))}
				</article>
			</section>

			<aside className={styles.aside}>
				<h5> Ultimas novedades</h5>
				<hr />

				{lastNews?.length !== 0 && (
					<div className={styles.lastNews}>
						{lastNews?.map((notice) => (
							<div
								key={notice.id}
								className={styles.widget}
								onClick={() => handlGoToNotice(notice?.id ?? 0)}
							>
								<div className={styles.imagen}>
									<div className={styles.imgContainer}>
										<img src={notice?.hero_img_url ?? ''} alt='' />
									</div>
									<div className={styles.details}>{notice?.title ?? ''}</div>
								</div>
							</div>
						))}
					</div>
				)}
			</aside>
		</div>
	);
};
export default NoticePage;
