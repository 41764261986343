import React, { useEffect, useState } from 'react';
import {
	ConfigProvider,
	Result,
	Table,
	Card,
	Button,
	Space,
	Tooltip,
	message,
	Progress,
	Popconfirm,
} from 'antd';
import type { ColumnsType, TableProps } from 'antd/es/table';
import Icon from '@ant-design/icons/lib/components/Icon';
import { FcReading } from 'react-icons/fc';
import { LuFileEdit, LuTrash2, LuFilePlus2 } from 'react-icons/lu';
import dayjs from 'dayjs';
import { CustomError, Pagination } from '../../../../types/envelope.type';
import { RentWithAllRelations } from '../../../../models/rents.model';
import {
	deleteRent,
	getRentsWithTaxesAndContributions,
	getTaxesAndContributions,
} from '../../../../services/rents.controller';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { useNavigate } from 'react-router-dom';
import { PrivateRoutes, PublicRoutes } from '../../../../models';
import RentsUpdateForm from './components/RentsUpdateForm';
import { Database } from '../../../../types/database.types';

const RentsTable: React.FC = () => {
	const [pagination, setPagination] = useState<Pagination>({
		page: 1,
		items_per_page: 5,
	});
	const [sort, setSort] = useState<{
		sorted_by?: 'created_at' | 'updated_at' | 'id';
		order?: 'asc' | 'desc';
	}>({ sorted_by: 'created_at', order: 'desc' });
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [rentToUpdate, setRentToUpdate] = useState<
		RentWithAllRelations | undefined
	>();
	const [deleteLoading, setDeleteLoading] = useState<{
		loading: boolean;
		id: number | undefined;
	}>({ loading: false, id: undefined });
	const [messageApi, contextHolder] = message.useMessage();

	const rents = useAppSelector((state) => state.rents);
	const taxAndContributions = useAppSelector(
		(state) => state.taxAndContributions
	);

	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const successEvent = async (message: string) => {
		await messageApi.open({
			type: 'success',
			content: message,
		});
	};

	const errorEvent = async (message: string) => {
		await messageApi.open({
			type: 'error',
			content: message,
		});
	};

	useEffect(() => {
		setIsLoading(true);
		getTaxesAndContributions(dispatch).catch((e) => console.error(e));
		getRentsWithTaxesAndContributions(dispatch, {
			page: pagination.page,
			items_per_page: pagination.items_per_page,
			sort_by: sort.sorted_by,
			order: sort.order,
		})
			.catch((e) => console.error(e))
			.finally(() => setIsLoading(false));
	}, [pagination.page, pagination.items_per_page, sort.order, sort.sorted_by]);

	useEffect(() => {
		if (rents?.pagination)
			setPagination((prev) => ({
				...prev,
				total_items: rents.pagination?.total_items,
				total_pages: rents.pagination?.total_pages,
			}));
	}, [rents.pagination]);

	const handleRefetch = () => {
		setIsLoading(true);
		getRentsWithTaxesAndContributions(dispatch, {
			page: pagination.page,
			items_per_page: pagination.items_per_page,
			sort_by: sort.sorted_by,
			order: sort.order,
		})
			.catch((e) => console.error(e))
			.finally(() => setIsLoading(false));
	};

	const handleDeleteRent = async (id: number) => {
		setDeleteLoading({ loading: true, id });
		const response = await deleteRent(id);

		if (response.success) {
			await successEvent('Se ha eliminado 1 registro');
			handleRefetch();
			setDeleteLoading({ loading: false, id: undefined });
		} else {
			await errorEvent(
				(response?.error as CustomError)?.message ??
					'Hubo un error, por favor intenta nuevamente.'
			);
			setDeleteLoading({ loading: false, id: undefined });
		}
	};

	const columns: ColumnsType<RentWithAllRelations> = [
		{
			title: 'ID',
			dataIndex: 'id',
			align: 'center',
			sorter: true,
		},
		{
			title: 'RESPONSABLE',
			dataIndex: 'users',
			render: (_, record) =>
				`${record.users?.first_name} ${record.users?.last_name}`,
		},
		{
			title: 'MONTO TOTAL',
			dataIndex: 'rents_tax_and_contr',
			align: 'center',
			render: (_value, record) => {
				const totalAmount = record.rents_tax_and_contr.reduce((total, item) => {
					total += item?.amount ?? 0;
					return total;
				}, 0);
				return `$${totalAmount.toLocaleString('de-DE', {
					maximumFractionDigits: 2,
				})}`;
			},
		},
		{
			title: 'FECHA DE CREACIÓN',
			dataIndex: 'created_at',
			render: (created_at) => dayjs(created_at).format('DD/MM/YYYY HH:mm:ss'),
			align: 'center',
			sorter: true,
		},
		{
			title: 'ÚLTIMA MODIFICACIÓN',
			dataIndex: 'updated_at',
			render: (updated_at) => dayjs(updated_at).format('DD/MM/YYYY HH:mm:ss'),
			align: 'center',
			sorter: true,
		},
		{
			title: 'ACCIONES',
			align: 'center',
			render: (_, record) => (
				<Space>
					<Tooltip title='Actualizar'>
						<Button
							onClick={() => {
								setRentToUpdate(record);
							}}
						>
							<Icon rev='1.0.0' component={() => <LuFileEdit />} />
						</Button>
					</Tooltip>
					<Popconfirm
						title='Eliminar registro de Renta'
						description='¿Estás seguro de eliminar?'
						onConfirm={() => handleDeleteRent(record.id)}
						okText='Si'
						cancelText='No'
					>
						<Tooltip title='Eliminar'>
							<Button
								danger
								loading={
									deleteLoading.loading && deleteLoading.id === record.id
								}
							>
								<Icon rev='1.0.0' component={() => <LuTrash2 />} />
							</Button>
						</Tooltip>
					</Popconfirm>
				</Space>
			),
		},
	];

	const expandableColumns: ColumnsType<
		Database['public']['Tables']['rents_tax_and_contr']['Row'] & {
			totalAmount: number;
		}
	> = [
		{
			title: 'Tasa o Contribución',
			dataIndex: 'tax_and_contr_id',
			render: (value) =>
				taxAndContributions?.data?.find((tac) => tac.id === value)?.name ?? '-',
			width: '40%',
		},
		{
			title: 'Importe',
			dataIndex: 'amount',
			render: (value) =>
				`$${(value as number).toLocaleString('de-DE', {
					maximumFractionDigits: 2,
				})}`,
			width: '20%',
		},
		{
			title: 'Gráfico',
			dataIndex: 'amount',
			render: (value, record) => (
				<Progress
					strokeLinecap='square'
					percent={Math.round((value / record.totalAmount) * 100 * 100) / 100}
					size={[300, 20]}
				/>
			),
			width: '40%',
		},
	];

	const onChange: TableProps<RentWithAllRelations>['onChange'] = (
		pagination,
		filters,
		sorter,
		extra
	) => {
		setPagination({
			page: pagination.current ?? 1,
			items_per_page: pagination.pageSize ?? 5,
		});

		if (Object.keys(sorter).length) {
			setSort({
				sorted_by: (sorter as any)?.field,
				order: (sorter as any)?.order === 'ascend' ? 'asc' : 'desc',
			});
		}
	};

	return (
		<>
			{contextHolder}
			<RentsUpdateForm
				data={rentToUpdate}
				isOpen={!!rentToUpdate}
				onClose={() => {
					setRentToUpdate(undefined);
				}}
				onSuccess={successEvent}
				onError={errorEvent}
				refetch={handleRefetch}
			/>
			<Card
				title='Listado de Rentas'
				style={{ margin: '1.5em 0' }}
				extra={
					<Button
						type='primary'
						icon={<Icon rev='1.0.0' component={() => <LuFilePlus2 />} />}
						onClick={() =>
							navigate(
								`/${PrivateRoutes.PRIVATE}/${PrivateRoutes.EDIT}/${PublicRoutes.RENTS}`
							)
						}
					>
						Crear Registro
					</Button>
				}
			>
				<ConfigProvider
					renderEmpty={() => (
						<Result
							icon={<Icon rev='1.0.0' component={() => <FcReading />} />}
							title='No se han encontrado resultados'
							subTitle='Intentá cambiar los filtros o consultalo con tu desarrollador de confianza'
						/>
					)}
				>
					<Table
						rowKey={'id'}
						columns={columns}
						dataSource={rents.data ?? []}
						expandable={{
							expandedRowRender: (record) => {
								const totalAmount = record.rents_tax_and_contr.reduce(
									(total, item) => {
										total += item?.amount ?? 0;
										return total;
									},
									0
								);
								const dataFormatted = record.rents_tax_and_contr.map(
									(item) => ({ ...item, totalAmount })
								);
								return (
									<Table
										rowKey={'id'}
										dataSource={dataFormatted}
										columns={expandableColumns}
										pagination={false}
										size='small'
									/>
								);
							},
						}}
						onChange={onChange}
						loading={isLoading}
						pagination={{
							pageSize: pagination.items_per_page,
							pageSizeOptions: ['5', '10', '25'],
							showSizeChanger: true,
							total: pagination?.total_items ?? 0,
							current: pagination.page,
							showTotal: (total, range) => {
								return `${range[0]} - ${range[1]} de ${total}`;
							},
						}}
					/>
				</ConfigProvider>
			</Card>
		</>
	);
};

export default RentsTable;
