import React from 'react';
import { Button, Card, Flex, Form, Input, message, Typography } from 'antd';
import { updatePassword } from '../../services';

interface FormValues {
	newPassword: string;
}

const UpdatePassword: React.FC = () => {
	const onFinish = async (values: FormValues) => {
		const { newPassword } = values;

		const wasUpdated = await updatePassword(newPassword);

		if (wasUpdated) {
			await message.success(
				'Tu contraseña ha sido actualizada correctamente.',
				5
			);
		} else {
			await message.error(
				'Ocurrió un error al actualizar tu contraseña. Por favor intenta de nuevo.',
				5
			);
		}
	};

	return (
		<Flex
			style={{
				minHeight: 'calc(100vh - 80px - 200px)',
				maxWidth: '1024px',
				margin: '0 auto',
			}}
			align='center'
			justify='center'
		>
			<Card>
				<Typography.Title level={4} style={{ marginBottom: '1.5em' }}>
					Actualiza tu contraseña
				</Typography.Title>
				<Form<FormValues>
					name='update-password'
					style={{ maxWidth: 360 }}
					onFinish={onFinish}
				>
					<Form.Item
						name='newPassword'
						rules={[
							{
								required: true,
								message: 'Por favor ingresa tu nueva contraseña',
							},
						]}
					>
						<Input.Password placeholder='Nueva contraseña' />
					</Form.Item>

					<Form.Item>
						<Button block type='primary' htmlType='submit'>
							Actualizar
						</Button>
					</Form.Item>
				</Form>
			</Card>
		</Flex>
	);
};

export default UpdatePassword;
