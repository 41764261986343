import { supabase } from '../database/supabase';

export const uploadImage = async (
	bucketName: string,
	path: string,
	file: any
) => {
	try {
		const { data, error } = await supabase.storage
			.from(bucketName)
			.upload(path, file);

		if (error) console.error(error);

		if (data) return getUrlImg(bucketName, data.path);
	} catch (error) {
		console.error(error);
	}
};

export const getUrlImg = (bucketName: string, path: string) => {
	const { data } = supabase.storage.from(bucketName).getPublicUrl(path);

	return data.publicUrl;
};

export const updateImg = async (
	bucketName: string,
	path: string,
	file: any
) => {
	try {
		const { data, error } = await supabase.storage
			.from(bucketName)
			.update(path, file);

		if (error) console.error(error);

		if (data) return getUrlImg(bucketName, data.path);
	} catch (error) {
		console.error(error);
	}
};

export const deleteImg = async (bucketName: string, path: string[]) => {
	try {
		const { data, error } = await supabase.storage
			.from(bucketName)
			.remove(path);

		if (error) console.error(error);

		if (data) return data;
	} catch (error) {
		console.error(error);
	}
};

export const getUploadUrl = async (input: {
	bucketName: string;
	path: string;
}): Promise<{
	signedUrl: string;
	token: string;
	path: string;
	error?: string;
}> => {
	const { data, error } = await supabase.storage
		.from(input.bucketName)
		.createSignedUploadUrl(input.path);

	if (error) {
		console.error(error);
		const message =
			error.message === 'The resource already exists'
				? 'Ya existe un archivo con el mismo nombre.'
				: 'Algo salió mal, por favor intenta recargando la página.';
		return { signedUrl: '', token: '', path: '', error: message };
	}

	return data ?? { signedUrl: '', token: '', path: '' };
};

export const uploadFileByUrl = async (input: {
	bucketName: string;
	path: string;
	token: string;
	file: any;
}): Promise<string> => {
	const { bucketName, path, file, token } = input;

	const { data, error } = await supabase.storage
		.from(bucketName)
		.uploadToSignedUrl(path, token, file);

	if (error) console.error(error);

	return getUrlImg(bucketName, data?.path ?? '');
};
