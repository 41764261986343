import { useMemo } from 'react';
import styles from './PhoneMenuIcon.module.scss';

interface PhoneMenuIconProps {
	isOpen: boolean;
	onClick: () => any;
	size?: 'small' | 'medium' | 'large';
}

export const PhoneMenuIcon = (props: PhoneMenuIconProps) => {
	const barStyle = useMemo(() => {
		const classes = [styles.bar];

		switch (props.size) {
			case 'small': {
				classes.push(styles.size_small);
				break;
			}
			case 'large': {
				classes.push(styles.size_large);
				break;
			}
			default: {
				classes.push(styles.size_medium);
				break;
			}
		}

		return classes.join(' ');
	}, [props.size]);

	return (
		<label htmlFor='check' className={barStyle}>
			<input
				id='check'
				type='checkbox'
				checked={props.isOpen}
				onChange={props.onClick}
				className={styles.input}
			/>

			<span className={styles.top}></span>
			<span className={styles.middle}></span>
			<span className={styles.bottom}></span>
		</label>
	);
};
