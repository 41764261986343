// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
	--primaryPink: #f02389;
	--lightPink: #f2c2da;
	--darkPink: #a3185d;
	--primaryBlue: #059dd6;
	--lightBlue: #c2e5f2;
	--darkBlue: #1b71b2;
	--grayOne: #f4f4f4;
	--grayTwo: #e4e4e4;
	--grayThree: #c4c4c4;
	--grayFour: #8c8c8c;
	--grayFive: #666666;
	--graySix: #454546;

	--font-family: 'Inter', sans-serif;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	font-family: var(--font-family);
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;CACC,sBAAsB;CACtB,oBAAoB;CACpB,mBAAmB;CACnB,sBAAsB;CACtB,oBAAoB;CACpB,mBAAmB;CACnB,kBAAkB;CAClB,kBAAkB;CAClB,oBAAoB;CACpB,mBAAmB;CACnB,mBAAmB;CACnB,kBAAkB;;CAElB,kCAAkC;AACnC;;AAEA;CACC,SAAS;CACT,UAAU;CACV,sBAAsB;AACvB;;AAEA;CACC,iBAAiB;CACjB,aAAa;CACb,sBAAsB;CACtB,+BAA+B;AAChC","sourcesContent":[":root {\r\n\t--primaryPink: #f02389;\r\n\t--lightPink: #f2c2da;\r\n\t--darkPink: #a3185d;\r\n\t--primaryBlue: #059dd6;\r\n\t--lightBlue: #c2e5f2;\r\n\t--darkBlue: #1b71b2;\r\n\t--grayOne: #f4f4f4;\r\n\t--grayTwo: #e4e4e4;\r\n\t--grayThree: #c4c4c4;\r\n\t--grayFour: #8c8c8c;\r\n\t--grayFive: #666666;\r\n\t--graySix: #454546;\r\n\r\n\t--font-family: 'Inter', sans-serif;\r\n}\r\n\r\n* {\r\n\tmargin: 0;\r\n\tpadding: 0;\r\n\tbox-sizing: border-box;\r\n}\r\n\r\nbody {\r\n\tmin-height: 100vh;\r\n\tdisplay: flex;\r\n\tflex-direction: column;\r\n\tfont-family: var(--font-family);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
