import { PublicRoutes } from '../models';
import { SubMenuList } from '../types/headerTypes';

export const NavButtons: SubMenuList[] = [
	{
		title: 'INICIO',
		path: '',
		subItems: [],
	},
	{
		title: 'MUNICIPIO',
		path: PublicRoutes.GOBERMENT,
		subItems: [],
	},
	{
		title: 'RENTAS',
		path: PublicRoutes.RENTS,
		subItems: [],
	},
	{
		title: 'NOVEDADES',
		path: PublicRoutes.NEWS,
		subItems: [],
	},
	{
		title: 'TURISMO',
		path: PublicRoutes.TOURISM,
		subItems: [],
	},
];

export const POSITIONS = [
	'Administrador',
	'Prensa',
	'Rentas',
	'Hacienda',
	'Gobierno',
];
