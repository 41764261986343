import { supabase } from '../database/supabase';

export const getUser = async () => {
	try {
		const { data, error } = await supabase.auth.getUser();

		if (error) console.error(error);

		return data;
	} catch (error) {
		console.error(error);
	}
};

export const updatePassword = async (password: string) => {
	try {
		const { error } = await supabase.auth.updateUser({ password });

		if (error) {
			console.error(error);
			return false;
		}

		return true;
	} catch (error) {
		console.error(error);
		return false;
	}
};
