import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import Loader from '../../components/loader/Loader';
import NewsCard from '../../components/NewsCard/NewsCard';
import Title from '../../components/Titlte/Title';
import { PublicRoutes } from '../../models';
import styles from './_newsSection.css';
import { getNewsWithRelations } from '../../services/news.controller';
import { Pagination as PaginationType } from '../../types/envelope.type';
import { Pagination } from 'antd';
import { PaginationProps } from 'antd/lib';

const NewsSection = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [pagination, setPagination] = useState<PaginationType>({
		page: 1,
		items_per_page: 10,
	});

	const news = useAppSelector((state) => state.news);

	const dispatch = useAppDispatch();

	const navigate = useNavigate();

	useEffect(() => {
		setIsLoading(true);
		getNewsWithRelations(dispatch, {
			page: pagination.page,
			items_per_page: pagination.items_per_page,
			sort_by: 'created_at',
			order: 'desc',
		})
			.catch((e) => console.error(e))
			.finally(() => setIsLoading(false));
	}, [pagination.page, pagination.items_per_page]);

	useEffect(() => {
		if (news?.pagination)
			setPagination((prev) => ({
				...prev,
				total_items: news.pagination?.total_items,
				total_pages: news.pagination?.total_pages,
			}));
	}, [news.pagination]);

	const handleSeeMore = (id: number) => {
		navigate(`/${PublicRoutes.NEWS}/${id}`);
	};

	const onChange: PaginationProps['onChange'] = (page, pageSize) => {
		setPagination({
			page,
			items_per_page: pageSize,
		});
	};

	return (
		<div className={styles.container}>
			{isLoading && <Loader />}
			<Title text='Novedades' />
			<section className={styles.main}>
				{news?.data?.map((notice, index) => (
					<NewsCard
						data={notice}
						key={index}
						onClick={() => handleSeeMore(notice.id)}
					/>
				))}
				<Pagination
					pageSize={pagination.items_per_page}
					pageSizeOptions={['5', '10', '25']}
					showSizeChanger={true}
					total={pagination?.total_items ?? 0}
					current={pagination.page}
					showTotal={(total, range) => {
						return `${range[0]} - ${range[1]} de ${total}`;
					}}
					onChange={onChange}
				/>
			</section>
		</div>
	);
};

export default NewsSection;
