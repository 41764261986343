import { useForm, SubmitHandler } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createUser } from '../../app/states/user';
import { Button } from '../../components/button/Button';
import FormInput from '../../components/FormInput/FormInput';
import { supabase } from '../../database/supabase';
import { PrivateRoutes, PublicRoutes } from '../../models';
import { RegisterInputs } from '../Register/Register';
import styles from './_login.css';
import { message } from 'antd';

const Login = () => {
	const [messageApi, contextHolder] = message.useMessage();

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<RegisterInputs>();

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const errorEvent = async () => {
		await messageApi.open({
			type: 'error',
			content: 'Usuario y/o contraseña incorrectos.',
			duration: 4,
		});
	};

	const onSubmit: SubmitHandler<RegisterInputs> = async (input) => {
		try {
			const { data, error } = await supabase.auth.signInWithPassword({
				email: input.email,
				password: input.password,
			});

			if (error) throw error;

			if (data) {
				dispatch(
					createUser({
						id: data.user?.id,
						name: data.user?.user_metadata.firstName,
						lastName: data.user?.user_metadata.lastName,
						email: data.user?.email,
						cuil: data.user?.user_metadata.cuil,
						position: data.user?.user_metadata.position,
					})
				);
			}

			navigate(`/${PrivateRoutes.PRIVATE}`, { replace: true });
		} catch (error) {
			console.error(error);
			await errorEvent();
		}
	};

	const handleGoToRegister = () => {
		navigate(`/${PublicRoutes.REGISTER}`);
	};

	return (
		<div className={styles.login}>
			{contextHolder}
			<div className={styles.container}>
				<h3>INICIAR SESIÓN</h3>
				<p className={styles.precaution}>
					*Acceso únicamente para personal autorizado
				</p>
				<form onSubmit={handleSubmit(onSubmit)} style={{ width: '100% ' }}>
					<FormInput
						label='Email'
						name='email'
						type='text'
						register={register}
						rules={{ required: true }}
						error={errors.email}
						errorMessage='Este campo es requerido'
					/>
					<FormInput
						label='Contraseña'
						name='password'
						type='password'
						register={register}
						rules={{ required: true }}
						autoComplete='new-password'
						error={errors.password}
						errorMessage='Este campo es requerido'
					/>

					<div className={styles.btnContainer}>
						<Button
							onClick={handleGoToRegister}
							variant='primary_ghost'
							label='Darse de alta'
						/>
						<Button label='Iniciar Sesión' type='submit' />
					</div>
				</form>
			</div>
		</div>
	);
};

export default Login;
