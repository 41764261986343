import { Link } from 'react-router-dom';
import CommercialAuthIcon from '../../../../assets/svg/CommercialAuthIcon';
import DriversLicensesIcon from '../../../../assets/svg/DriversLicensesIcon';
// import LodgingIcon from '../../../../assets/svg/LodgingIcon';
import OurCityIcon from '../../../../assets/svg/OurCityIcon';
import PaycheckIcon from '../../../../assets/svg/PaycheckIcon';
import ResidueRecolectionIcon from '../../../../assets/svg/ResidueRecolectionIcon';
import styles from './_frequentServices.css';
import { PublicRoutes } from '../../../../models';

const FrequentServices = () => {
	return (
		<section className={styles.services}>
			<div className={styles.flex}>
				<div className={styles.button}>
					<a
						className={styles['cursor-pointer']}
						href='https://www.google.com/maps/@-30.357765,-66.3091604,15.79z/data=!5m1!1e4?hl=es'
						target='_blank'
						rel='noreferrer'
					>
						<OurCityIcon />
						<h3 className={styles['title-button']}>Nuestra Ciudad</h3>
					</a>
				</div>
				<div className={styles.button}>
					<a
						className={styles['cursor-pointer']}
						href='https://empleadospublicos.larioja.gob.ar/recibos/web/login'
						target='_blank'
						rel='noreferrer'
					>
						<PaycheckIcon />
						<h3 className={styles['title-button']}>Recibo de Sueldo</h3>
					</a>
				</div>
				<div className={styles.button}>
					<Link
						className={styles['cursor-pointer']}
						to={PublicRoutes.DRIVERS_LICENSES}
					>
						<DriversLicensesIcon />
						<h3 className={styles['title-button']}>
							Licencias de Conducir Nacional
						</h3>
					</Link>
				</div>
				<div className={styles.button}>
					<Link
						className={styles['cursor-pointer']}
						to={PublicRoutes.COMMERCIAL_AUTH}
					>
						<CommercialAuthIcon />
						<h3 className={styles['title-button']}>
							Habilitaciones Comerciales
						</h3>
					</Link>
				</div>
				<div className={styles.button}>
					<Link
						className={styles['cursor-pointer']}
						to={PublicRoutes.RESIDUE_RECOLECTION}
					>
						<ResidueRecolectionIcon />
						<h3 className={styles['title-button']}>Recolección de Residuos</h3>
					</Link>
				</div>
				{/* <div className={styles.button}>
					<a
						className={styles['cursor-pointer']}
						href='hospedajes.html'
						target='_blank'
					>
						<LodgingIcon />
						<h3 className={styles['title-button']}>Hospedajes</h3>
					</a>
				</div> */}
			</div>
		</section>
	);
};
export default FrequentServices;
