import Title from '../../../../components/Titlte/Title';
import styles from './_UsefulPhones.css';

const UsefulPhones = () => {
	return (
		<section className={styles.services}>
			<div className={styles.subServices}>
				<Title text='Teléfonos útiles' />
			</div>
			<div className={styles.containerS}>
				<div className={styles.boxS}>
					<h3> 103</h3>
					<h3>Defensa Civil</h3>
				</div>
				<div className={styles.boxS}>
					<h3>144 </h3>
					<h3>Violencia contra la mujer</h3>
				</div>
				<div className={styles.boxS}>
					<h3>107</h3>
					<h3>Emergencia Médica</h3>
				</div>
				<div className={styles.boxS}>
					<h3>108</h3>
					<h3>Primer Contacto</h3>
				</div>
				<div className={styles.boxS}>
					<h3>142</h3>
					<h3>Niños Extraviados</h3>
				</div>
				<div className={styles.boxS}>
					<h3>145</h3>
					<h3> Trata de Personas</h3>
				</div>
				<div className={styles.boxS}>
					<h3>105</h3>
					<h3>Emergencia Ambiental</h3>
				</div>
			</div>
		</section>
	);
};

export default UsefulPhones;
