import styles from './DriversLicenses.css';
import driverLicense from '../../assets/img/licencia-de-conducir.jpg';
import vigency from '../../assets/img/clases-vigencia.jpeg';

const DriversLicenses = () => {
	return (
		<section className={styles.licenses}>
			<img src={driverLicense} alt='' className={styles.img} />
			<br />
			<p>
				Es un servicio exclusivo para los vecinos con domicilio real en la
				ciudad de Chamical y Patquía.
			</p>
			<br />
			<h3>Lugar de atención para Solicitud de Licencia de Conducir</h3>
			<ul>
				<li>
					<b>Municipalidad del Dpto. Chamical: </b>Calle Peron N° 50.
				</li>
			</ul>
			<br />
			<h3>Dias y Horarios:</h3>
			<ul>
				<li>
					<b>Lunes a Viernes: </b>Mañana: 8:00 a 12:30hs. Tarde: 17:00 a
					21:00hs.
				</li>
			</ul>
			<br />
			<h3>Realizar el Curso de Seguridad Vial</h3>
			<ul>
				<li>
					<b>Hacer el curso vía online: </b>
					<a
						href='https://curso.seguridadvial.gob.ar/ansv/'
						target='_blank'
						rel='noreferrer'
					>
						<b>CURSO.SEGURIDADVIAL.GOB.AR.</b>
					</a>
				</li>
			</ul>
			<br />
			<p>
				Requisitos para el otorgamiento de la licencia nacional de conducir:
			</p>
			<br />
			<div className={styles.details}>
				<details className={styles['detail-box']}>
					<summary>
						Licencia de clase A y B de uso particular por primera vez:
					</summary>
					<p>
						Otorgamiento por primera vez o vencimiento superior a los 90 días.
					</p>
					<p className={styles['title-detail']}>PRESENTAR:</p>
					<ul>
						<li>1 fotocopia de DNI</li>
						<li>Constacia de Grupo Sanguineo</li>
						<li>Boleta-Ticket de pago del CENAT</li>
						<li>
							Generar boleta CENAT en{' '}
							<a
								href='https://www.argentina.gob.ar/seguridadvial/licencianacional/cenat'
								target='_blank'
								rel='noreferrer'
							>
								<b>ARGENTINA.GOB.AR</b>
							</a>
						</li>
						<li>Autorización legal (para menores de 18 años)</li>
						<li>Folio</li>
						<li>Curso de Educaión Vial aprobado</li>
					</ul>
					<p className={styles['title-detail']}>REQUISITOS:</p>
					<ul>
						<li>Contar con buen estado de salud para conducir</li>
						<li>Contar con domicilio en </li>
						<li>
							Disponer de vehículo para el curso de practica de Educación Vial
						</li>
						<li>Traer Cédula de el o los vehículos</li>
					</ul>
					<p className={styles['title-detail']}>CLASE A:</p>
					<ul>
						<li>Motovehículos</li>
						<li>Triciclos</li>
						<li>Cuatriciclos</li>
					</ul>
					<p className={styles['title-detail']}>CLASE B</p>
					<ul>
						<li>Autos</li>
						<li>Particulares</li>
					</ul>
				</details>
				<details className={styles['detail-box']}>
					<summary>Licencia de clase C, D, E y G de uso profesional:</summary>
					<p className={styles['title-detail']}>PRESENTAR:</p>
					<ul>
						<li>1 fotocopia de DNI</li>
						<li>Constacia de CUIL</li>
						<li>1 fotocopia de la licencia anterior</li>
						<li>Boleta-Ticket de pago del CENAT</li>
						<li>Folio</li>
						<li>
							Certificado de antecedentes penales (solo clase &quot;D&quot;)
						</li>
					</ul>
					<p className={styles['title-detail']}>REQUISITOS:</p>
					<ul>
						<li>Saber leer y escribir</li>
						<li>Contar con buen estado de salud para conducir</li>
						<li>Contar con domicilio en </li>
						<li>Aprobar el Curso Nacional de Educación Vial</li>
						<li>
							No se acepta el comprobante de proceso en tramite del Certificado
							de Antecedentes Personales
						</li>
						<li>Contar con licencia particular de auto mínimo un año</li>
						<li>Traer Cédula de el o los vehículos</li>
						<li>
							Generar boleta CENAT en{' '}
							<a
								href='https://www.argentina.gob.ar/seguridadvial/licencianacional/cenat'
								target='_blank'
								rel='noreferrer'
							>
								<b>ARGENTINA.GOB.AR</b>
							</a>
						</li>
					</ul>
				</details>
				<details className={styles['detail-box']}>
					<summary>Renovación:</summary>
					<p>
						Este trámite se realiza de que la licencia cumpla 90 días corridos
						desde la fecha de vencimiento.
					</p>
					<p className={styles['title-detail']}>PRESENTAR:</p>
					<ul>
						<li>1 fotocopia de DNI</li>
						<li>Constacia de CUIL</li>
						<li>1 fotocopia de la licencia anterior</li>
						<li>Boleta-Ticket de pago del CENAT</li>
						<li>Folio</li>
						<li>
							Certificado de antecedentes penales (solo clase &quot;D&quot;)
						</li>
						<li>
							Generar boleta CENAT en{' '}
							<a
								href='https://www.argentina.gob.ar/seguridadvial/licencianacional/cenat'
								target='_blank'
								rel='noreferrer'
							>
								<b>ARGENTINA.GOB.AR</b>
							</a>
						</li>
					</ul>
					<p className={styles['title-detail']}>REQUISITOS:</p>
					<ul>
						<li>Contar con buen estado de salud para conducir</li>
						<li>Contar con domicilio en </li>
						<li>Traer célula del o los vehículos</li>
						<li>Aprobar el Curso Nacional de Educación Vial</li>
						<li>
							No se acepta el comprobante de proceso en tramite del Certificado
							de Antecedentes Personales
						</li>
						<li>Contar con licencia particular de auto mínimo un año</li>
					</ul>
				</details>
				<details className={styles['detail-box']}>
					<summary>Duplicado y Triplicado:</summary>
					<p>
						Este trámite se realiza ante un extravio, robo o deterioro de la
						licencia de conducir vigente.
					</p>
					<p className={styles['title-detail']}>PRESENTAR:</p>
					<ul>
						<li>1 fotocopia de DNI</li>
						<li>Constacia de CUIL</li>
						<li>Denuncia de extravio</li>
						<li>Boleta-Ticket de pago del CENAT</li>
						<li>Folio</li>
						<li>Comprobante de pago de Rentas de la Licencia extraviada</li>
					</ul>
					<p className={styles['title-detail']}>REQUISITOS:</p>
					<ul>
						<li>Contar con buen estado de salud para conducir</li>
						<li>Contar con domicilio en </li>
						<li>Traer célula del o los vehículos</li>
						<li>Certificado de extravio de la Policia</li>
						<li>
							Comprobante de pago / comprobante de rentas (solictar en oficina
							de Rentas Municipal)
						</li>
						<li>
							Generar boleta CENAT en{' '}
							<a
								href='https://www.argentina.gob.ar/seguridadvial/licencianacional/cenat'
								target='_blank'
								rel='noreferrer'
							>
								<b>ARGENTINA.GOB.AR</b>
							</a>
						</li>
						<li>Disponer de vehículo para el curso práctico</li>
					</ul>
				</details>
			</div>
			<br />
			<p>
				<b>
					-Si sos menor de 18 años, tenés que estar autorizado por tu
					representante legal (padre, madre o tutor).
				</b>
			</p>
			<p>
				<b>
					-Los deudores alimentarios no pueden acceder a la licencia de
					conducir.
				</b>
			</p>
			<br />
			<h3>Pasos a Seguir: </h3>
			<ul>
				<li>
					Presentarse en el Cepar Chamical con toda la documentación requerida y
					solicitar turno para realizar el Curso Nacional de Seguridad Vial.
				</li>
				<li>
					Realizar{' '}
					<a
						href='https://boletadepago.seguridadvial.gob.ar/'
						target='_blank'
						rel='noreferrer'
					>
						el pago del CENAT.
					</a>
				</li>
				<li>
					Solicitar{' '}
					<a
						href='https://www.argentina.gob.ar/justicia/reincidencia/antecedentespenales'
						target='_blank'
						rel='noreferrer'
					>
						certificado de antecedentes penales.
					</a>{' '}
					(solo para licencia de conducir Clase D).
				</li>
				<li>
					<b>El trámite para Licencia de Conducir es por orden de llegada.</b>
				</li>
				<li>
					Presentar toda la documentación solicitada en el Centro de Emisión de
					Licencia.
				</li>
				<li>
					Abonar el sellado municipal y el importe del plástico en oficina de
					Tránsito.
				</li>
				<li>Realizar el examen psicofísico/psicológico.</li>
				<li>Examen practico de detección de fallas.</li>
				<li>Recepción de tarjeta-carnet Licencia de Conducir solicitada.</li>
				<li>¡Listo! Ya tienes tu Licencia de Conducir.</li>
			</ul>
			<br />
			<br />
			<div className={styles.vigencias}>
				<img src={vigency} alt='imagen de vigencias, clases y sub clases' />
			</div>
		</section>
	);
};
export default DriversLicenses;
