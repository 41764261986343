import styles from './_tourismCard.css';

interface Props {
	title: string;
	description: string;
	image: string;
	onClick: () => any;
}

const TourismCard = ({ title, description, image, onClick }: Props) => {
	return (
		<div className={styles.container} onClick={onClick}>
			<div className={styles.imgContainer}>
				<img src={image} alt={title} />
			</div>
			<div className={styles.textContainer}>
				<h3>{title}</h3>
				<p>
					{description}
				</p>
			</div>
		</div>
	);
};

export default TourismCard;
