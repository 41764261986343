import styles from './Rents.module.scss';
import rentsImg from '../../assets/img/Rentas.jpg';
import RentsGraph from './components/RentsGraph/RentsGraph';
import { Typography } from 'antd';

const Rents = () => {
	return (
		<div className={styles.page}>
			<div className={styles.img}>
				<img src={rentsImg} alt='rentas hero' />
			</div>

			<br />

			<Typography.Title level={3} underline className={styles.title}>
				Rentas del Municipio
			</Typography.Title>

			<br />

			<RentsGraph />
		</div>
	);
};
export default Rents;
