import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../app/store';
import { AnyAction } from 'redux';
import { Envelope } from '../types/envelope.type';
import { InsertTourismWithTopics, TourismWithRelations } from '../models';
import * as tourismServices from './tourism.service';
import { setTourism } from '../app/states/tourism';
import { setIndividualTourism } from '../app/states/individualTourism';
import dayjs from 'dayjs';

export const getTourismWithRelations = async (
	dispatch: ThunkDispatch<RootState, undefined, AnyAction>,
	queries?: {
		order?: 'asc' | 'desc';
		page?: number;
		items_per_page?: number;
		sort_by?: 'id' | 'created_at' | 'update_at';
	}
) => {
	const order = queries?.order ?? 'desc';
	const page = queries?.page ? Number(queries.page) : 1;
	const items_per_page = queries?.items_per_page
		? Number(queries.items_per_page)
		: 10;
	const sort_by =
		(queries?.sort_by as 'id' | 'created_at' | 'update_at') ?? 'created_at';

	const response: Envelope<TourismWithRelations[]> = {
		success: true,
		data: null,
		error: null,
		pagination: null,
	};

	try {
		const {
			count,
			error: countError,
			status: countStatus,
		}: tourismServices.GetAllTourismCountResponse = await tourismServices.getAllTourismCountService();

		if (countError) {
			response.success = false;
			response.error = {
				status: countStatus,
				message: 'Hubo un error, por favor intenta nuevamente.',
				other_info: countError,
			};
		}

		const { data, error, status }: tourismServices.GetAllTourismResponse =
			await tourismServices.getAllTourismService(
				order,
				page,
				items_per_page,
				sort_by
			);

		if (error) {
			response.success = false;
			response.error = {
				status,
				message: 'Hubo un error, por favor intenta nuevamente.',
				other_info: error,
			};
		}

		if (count && data) {
			response.data = data;
			response.pagination = {
				page,
				items_per_page,
				total_pages: Math.ceil(count / items_per_page),
				total_items: count,
			};

			return dispatch(setTourism(response));
		}

		return dispatch(setTourism(response));
	} catch (error) {
		console.error(error);
		response.success = false;
		response.error = {
			status: 500,
			message: 'Hubo un error de servidor, por favor intenta nuevamente.',
			other_info: error,
		};
		return dispatch(setTourism(response));
	}
};

export const getTourismByIdController = async (
	dispatch: ThunkDispatch<RootState, undefined, AnyAction>,
	id: number
) => {
	const response: Envelope<TourismWithRelations> = {
		success: true,
		data: null,
		error: null,
		pagination: null,
	};

	try {
		const {
			data,
			error,
			status,
		}: tourismServices.GetTourismByIdServiceResponse = await tourismServices.getTourismByIdService(
			id
		);

		if (error) {
			response.success = false;
			response.error = {
				status,
				message: 'Hubo un error, por favor intenta nuevamente.',
				other_info: error,
			};
		}

		if (data) {
			response.data = data[0];

			dispatch(setIndividualTourism(response));
		}

		dispatch(setIndividualTourism(response));
	} catch (error) {
		console.error(error);
		response.success = false;
		response.error = {
			status: 500,
			message: 'Hubo un error de servidor, por favor intenta nuevamente.',
			other_info: error,
		};
		return dispatch(setIndividualTourism(response));
	}
};

export const createTourismWithTopicsController = async (
	tourismData: InsertTourismWithTopics
) => {
	const { tourism_topics, ...tourism } = tourismData;

	const response: Envelope<{ status: number; statusText: string }> = {
		success: true,
		data: null,
		error: null,
		pagination: null,
	};

	try {
		const {
			data: lastTourismIdData,
			error: lastTourismIdError,
			status: lastTourismIdStatus,
		}: tourismServices.GetLastTourismIdService = await tourismServices.getLastTourismIdService();

		if (lastTourismIdError) {
			response.success = false;
			response.error = {
				status: lastTourismIdStatus,
				message: 'Hubo un error, por favor intenta nuevamente.',
				other_info: lastTourismIdError,
			};
			return response;
		}

		const tourismWithId = { ...tourism, id: lastTourismIdData[0].id + 1 };

		const {
			data: tourismData,
			error: tourismError,
			status: tourismStatus,
		}: tourismServices.CreateTourismServiceResponse = await tourismServices.createTourismService(
			{ ...tourismWithId, update_at: dayjs().toISOString() }
		);

		if (tourismError) {
			response.success = false;
			response.error = {
				status: tourismStatus,
				message: 'Hubo un error, por favor intenta nuevamente.',
				other_info: tourismError,
			};
			return response;
		}

		const {
			data: lastIdData,
			error: lastIdError,
			status: lastIdStatus,
		}: tourismServices.GetLastTourismTopicsIdServiceResponse = await tourismServices.getLastTourismTopicsIdService();

		if (lastIdError) {
			response.success = false;
			response.error = {
				status: lastIdStatus,
				message: 'Hubo un error, por favor intenta nuevamente.',
				other_info: lastIdError,
			};
			return response;
		}

		const formattedTopics = tourism_topics.map((topic, index) => ({
			...topic,
			id: lastIdData[0].id + (index + 1),
			id_tourism: tourismData[0].id,
		}));

		const {
			status,
			statusText,
			error,
		}: tourismServices.CreateTourismTopicsServiceResponse = await tourismServices.createTourismTopicsService(
			formattedTopics
		);

		if (error) {
			response.success = false;
			response.error = {
				status,
				message: 'Hubo un error, por favor intenta nuevamente.',
				other_info: error,
			};
			return response;
		}

		response.data = { status, statusText };

		return response;
	} catch (error) {
		console.error(error);
		response.success = false;
		response.error = {
			status: 500,
			message: 'Hubo un error de servidor, por favor intenta nuevamente.',
			other_info: error,
		};
		return response;
	}
};

export const deleteTourismWithTopics = async (id: number) => {
	const response: Envelope<{ status: number; statusText: string }> = {
		success: true,
		data: null,
		error: null,
		pagination: null,
	};

	try {
		const {
			error,
			status,
			statusText,
		}: tourismServices.DeleteTourismWithTopicsServiceResponse = await tourismServices.deleteTourismWithTopicsService(
			+id
		);

		if (error) {
			response.success = false;
			response.error = {
				status,
				message: 'Hubo un error, por favor intenta nuevamente.',
				other_info: error,
			};

			return response;
		}

		response.data = { status, statusText };

		return response;
	} catch (error) {
		console.error(error);
		response.success = false;
		response.error = {
			status: 500,
			message: 'Hubo un error de servidor, por favor intenta nuevamente.',
			other_info: error,
		};
		return response;
	}
};
