// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._login__login__saPyh {
	min-height: calc(100vh - 80px - 239px - 30px);
	width: 100%;
	max-width: 1024px;
	margin: 0 auto;
	display: flex;
	justify-content: center;
	padding: 3em 1em;
}

._login__container__RamMq {
	width: 100%;
	height: fit-content;
	background-color: white;
	font-family: var(--font-family);
	box-shadow: 0px 3px 14px rgba(0, 0, 0, 0.15);
	border-radius: 12px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 60px 30px;
}

._login__container__RamMq h3 {
	font-family: var(--font-family);
	font-size: 18px;
	margin: 0 0 20px;
}

._login__btnContainer__jjqmW {
	display: flex;
	justify-content: space-between;
}

small {
	display: block;
	color: #e74c3c;
	font-weight: 600;
	font-size: 12px;
	padding-top: 5px;
}

._login__precaution__SNBFP {
	font-family: var(--font-family);
	font-size: 14px;
	font-weight: 500;
	color: red;
	width: 100%;
	margin-bottom: 20px;
}

@media (min-width: 556px) {
	._login__login__saPyh {
		max-width: 556px;
	}
}

@media (min-width: 768px) {
	._login__login__saPyh {
		min-height: calc(100vh - 80px - 148px - 30px);
	}
}
`, "",{"version":3,"sources":["webpack://./src/pages/Login/_login.css"],"names":[],"mappings":"AAAA;CACC,6CAA6C;CAC7C,WAAW;CACX,iBAAiB;CACjB,cAAc;CACd,aAAa;CACb,uBAAuB;CACvB,gBAAgB;AACjB;;AAEA;CACC,WAAW;CACX,mBAAmB;CACnB,uBAAuB;CACvB,+BAA+B;CAC/B,4CAA4C;CAC5C,mBAAmB;CACnB,aAAa;CACb,sBAAsB;CACtB,uBAAuB;CACvB,kBAAkB;AACnB;;AAEA;CACC,+BAA+B;CAC/B,eAAe;CACf,gBAAgB;AACjB;;AAEA;CACC,aAAa;CACb,8BAA8B;AAC/B;;AAEA;CACC,cAAc;CACd,cAAc;CACd,gBAAgB;CAChB,eAAe;CACf,gBAAgB;AACjB;;AAEA;CACC,+BAA+B;CAC/B,eAAe;CACf,gBAAgB;CAChB,UAAU;CACV,WAAW;CACX,mBAAmB;AACpB;;AAEA;CACC;EACC,gBAAgB;CACjB;AACD;;AAEA;CACC;EACC,6CAA6C;CAC9C;AACD","sourcesContent":[".login {\r\n\tmin-height: calc(100vh - 80px - 239px - 30px);\r\n\twidth: 100%;\r\n\tmax-width: 1024px;\r\n\tmargin: 0 auto;\r\n\tdisplay: flex;\r\n\tjustify-content: center;\r\n\tpadding: 3em 1em;\r\n}\r\n\r\n.container {\r\n\twidth: 100%;\r\n\theight: fit-content;\r\n\tbackground-color: white;\r\n\tfont-family: var(--font-family);\r\n\tbox-shadow: 0px 3px 14px rgba(0, 0, 0, 0.15);\r\n\tborder-radius: 12px;\r\n\tdisplay: flex;\r\n\tflex-direction: column;\r\n\talign-items: flex-start;\r\n\tpadding: 60px 30px;\r\n}\r\n\r\n.container h3 {\r\n\tfont-family: var(--font-family);\r\n\tfont-size: 18px;\r\n\tmargin: 0 0 20px;\r\n}\r\n\r\n.btnContainer {\r\n\tdisplay: flex;\r\n\tjustify-content: space-between;\r\n}\r\n\r\nsmall {\r\n\tdisplay: block;\r\n\tcolor: #e74c3c;\r\n\tfont-weight: 600;\r\n\tfont-size: 12px;\r\n\tpadding-top: 5px;\r\n}\r\n\r\n.precaution {\r\n\tfont-family: var(--font-family);\r\n\tfont-size: 14px;\r\n\tfont-weight: 500;\r\n\tcolor: red;\r\n\twidth: 100%;\r\n\tmargin-bottom: 20px;\r\n}\r\n\r\n@media (min-width: 556px) {\r\n\t.login {\r\n\t\tmax-width: 556px;\r\n\t}\r\n}\r\n\r\n@media (min-width: 768px) {\r\n\t.login {\r\n\t\tmin-height: calc(100vh - 80px - 148px - 30px);\r\n\t}\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"login": `_login__login__saPyh`,
	"container": `_login__container__RamMq`,
	"btnContainer": `_login__btnContainer__jjqmW`,
	"precaution": `_login__precaution__SNBFP`
};
export default ___CSS_LOADER_EXPORT___;
