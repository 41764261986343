import { createSlice } from '@reduxjs/toolkit';
import { RentsTaxAndContrState } from '../../models/rents.model';
import { Envelope } from '../../types/envelope.type';

export const EmptyRentsRelationsState: Envelope<RentsTaxAndContrState> = {
	success: false,
	data: null,
	error: null,
	pagination: null,
};

export const rentsRelationsSlice = createSlice({
	name: 'rentsRelations',
	initialState: EmptyRentsRelationsState,
	reducers: {
		setRentsRelations: (_state, action) => {
			return action.payload;
		},
	},
});

export const { setRentsRelations } = rentsRelationsSlice.actions;

export default rentsRelationsSlice.reducer;
