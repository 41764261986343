import styles from './_hero.css';

const Hero = () => {
	return (
		<div className={styles.heroContainer}>
			<div className={styles.imgContainer} />
			<div className={styles.line} />
		</div>
	);
};

export default Hero;
