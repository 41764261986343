import { createSlice } from '@reduxjs/toolkit';
import { TourismWithRelations } from '../../models/news.model';
import { Envelope } from '../../types/envelope.type';

export const EmptyIndividualTourismWithRelationsState: Envelope<TourismWithRelations> =
	{
		success: false,
		data: null,
		error: null,
		pagination: null,
	};

export const individualTourismSlice = createSlice({
	name: 'individualTourism',
	initialState: EmptyIndividualTourismWithRelationsState,
	reducers: {
		setIndividualTourism: (_state, action) => {
			return action.payload;
		},
	},
});

export const { setIndividualTourism } = individualTourismSlice.actions;

export default individualTourismSlice.reducer;
