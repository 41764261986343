// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._register__register__SP_cx {
	width: 100%;
	min-height: calc(100vh - 80px - 239px - 30px);
	margin: 0 auto;
	padding: 3em 1em;
	display: flex;
	justify-content: center;
}

._register__container__YeEt0 {
	width: 100%;
	background-color: white;
	font-family: var(--font-family);
	box-shadow: 0px 3px 14px rgba(0, 0, 0, 0.15);
	border-radius: 12px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 60px 30px;
}

._register__container__YeEt0 h3 {
	font-family: var(--font-family);
	font-size: 18px;
	margin: 0 0 20px;
}

._register__container__YeEt0 h5 {
	font-family: var(--font-family);
	font-size: 14px;
	font-weight: 500;
	margin: 0 0 30px;
}

._register__container__YeEt0 form {
	width: 100%;
}

._register__btnContainer__MyglB {
	display: flex;
	justify-content: space-between;
}

@media (min-width: 556px) {
	._register__register__SP_cx {
		max-width: 556px;
	}
}
`, "",{"version":3,"sources":["webpack://./src/pages/Register/_register.css"],"names":[],"mappings":"AAAA;CACC,WAAW;CACX,6CAA6C;CAC7C,cAAc;CACd,gBAAgB;CAChB,aAAa;CACb,uBAAuB;AACxB;;AAEA;CACC,WAAW;CACX,uBAAuB;CACvB,+BAA+B;CAC/B,4CAA4C;CAC5C,mBAAmB;CACnB,aAAa;CACb,sBAAsB;CACtB,uBAAuB;CACvB,kBAAkB;AACnB;;AAEA;CACC,+BAA+B;CAC/B,eAAe;CACf,gBAAgB;AACjB;;AAEA;CACC,+BAA+B;CAC/B,eAAe;CACf,gBAAgB;CAChB,gBAAgB;AACjB;;AAEA;CACC,WAAW;AACZ;;AAEA;CACC,aAAa;CACb,8BAA8B;AAC/B;;AAEA;CACC;EACC,gBAAgB;CACjB;AACD","sourcesContent":[".register {\r\n\twidth: 100%;\r\n\tmin-height: calc(100vh - 80px - 239px - 30px);\r\n\tmargin: 0 auto;\r\n\tpadding: 3em 1em;\r\n\tdisplay: flex;\r\n\tjustify-content: center;\r\n}\r\n\r\n.container {\r\n\twidth: 100%;\r\n\tbackground-color: white;\r\n\tfont-family: var(--font-family);\r\n\tbox-shadow: 0px 3px 14px rgba(0, 0, 0, 0.15);\r\n\tborder-radius: 12px;\r\n\tdisplay: flex;\r\n\tflex-direction: column;\r\n\talign-items: flex-start;\r\n\tpadding: 60px 30px;\r\n}\r\n\r\n.container h3 {\r\n\tfont-family: var(--font-family);\r\n\tfont-size: 18px;\r\n\tmargin: 0 0 20px;\r\n}\r\n\r\n.container h5 {\r\n\tfont-family: var(--font-family);\r\n\tfont-size: 14px;\r\n\tfont-weight: 500;\r\n\tmargin: 0 0 30px;\r\n}\r\n\r\n.container form {\r\n\twidth: 100%;\r\n}\r\n\r\n.btnContainer {\r\n\tdisplay: flex;\r\n\tjustify-content: space-between;\r\n}\r\n\r\n@media (min-width: 556px) {\r\n\t.register {\r\n\t\tmax-width: 556px;\r\n\t}\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"register": `_register__register__SP_cx`,
	"container": `_register__container__YeEt0`,
	"btnContainer": `_register__btnContainer__MyglB`
};
export default ___CSS_LOADER_EXPORT___;
