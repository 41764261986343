import React, { useMemo } from 'react';
import styles from './Group.module.scss';

export interface GroupProps {
	children?: React.ReactNode;
	layout?: 'horizontal' | 'vertical';
}

export function Group(props: GroupProps) {
	const button_group = useMemo(() => {
		const classes = [styles.button_group];

		if (props.layout === 'vertical') {
			classes.push(styles.button_group__vertical);
		}

		return classes.join(' ');
	}, [props.layout]);

	return <div className={button_group}>{props.children}</div>;
}
