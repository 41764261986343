import styles from './Button.module.scss';
import { useMemo, useState } from 'react';
import { Icon } from '../icon/Icon';
import { IconName } from '../../types/icons.type';

import { Group } from './Group';
export type { GroupProps as ButtonGroupProps } from './Group';

interface ButtonProps {
	label?: string;
	variant?: 'primary' | 'secondary' | 'primary_ghost' | 'secondary_ghost';
	size?: 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge';
	block?: boolean;
	reverse?: boolean;
	style?: React.CSSProperties;
	icon?: IconName;
	disabled?: boolean;
	type?: 'submit' | 'button';
	onClick?: () => any;
}

export const Button = (props: ButtonProps) => {
	const [hover, setHover] = useState(false);

	const iconColor = {
		primary: {
			default: '#a3185d',
			hover: '#f1faee',
		},
		secondary: {
			default: '#1b71b2',
			hover: '#f1faee',
		},
		primary_ghost: {
			default: '#a3185d',
			hover: '#a3185d',
		},
		secondary_ghost: {
			default: '#1b71b2',
			hover: '#1b71b2',
		},
	};

	const buttonStyle = useMemo(() => {
		const classes = [styles.button];

		switch (props.variant) {
			case 'primary_ghost': {
				classes.push(styles.primary_ghost);
				break;
			}
			case 'secondary': {
				classes.push(styles.secondary);
				break;
			}
			case 'secondary_ghost': {
				classes.push(styles.secondary_ghost);
				break;
			}
			default: {
				classes.push(styles.primary);
				break;
			}
		}

		switch (props.size) {
			case 'xsmall': {
				classes.push(styles.size_xsmall);
				break;
			}
			case 'small': {
				classes.push(styles.size_small);
				break;
			}
			case 'large': {
				classes.push(styles.size_large);
				break;
			}
			case 'xlarge': {
				classes.push(styles.size_xlarge);
				break;
			}
			default: {
				classes.push(styles.size_medium);
				break;
			}
		}

		props.block && classes.push(styles.block);
		props.reverse && classes.push(styles.reverse);

		return classes.join(' ');
	}, [props.variant, props.variant, props.block]);

	return (
		<button
			className={buttonStyle}
			disabled={props.disabled}
			onMouseOver={() => setHover(true)}
			onMouseOut={() => setHover(false)}
			onClick={props.onClick}
			type={props.type ?? 'button'}
			style={props.style}
		>
			{props.icon && (
				<Icon
					icon={props.icon}
					icon_size='large'
					style={
						hover
							? {
									color: props.variant
										? iconColor[props.variant].hover
										: iconColor.primary.hover,
									transition: 'color 300ms cubic-bezier(0.23, 1, 0.32, 1)',
							  }
							: {
									color: props.variant
										? iconColor[props.variant].default
										: iconColor.primary.default,
									transition: 'color 300ms cubic-bezier(0.23, 1, 0.32, 1)',
							  }
					}
				/>
			)}
			{props.label}
		</button>
	);
};

Button.Group = Group;
