import styles from './_editPage.css';
import NewsForm from '../News/NewsForm/NewsForm';
import TourismForm from '../News/TourismForm/TourismForm';

interface EditProps {
	tourism?: boolean;
	news?: boolean;
}

const EditPage = ({ tourism, news }: EditProps) => {
	return (
		<div className={styles.container}>
			{news && <NewsForm />}
			{tourism && <TourismForm />}
		</div>
	);
};
export default EditPage;
