import { SVGProps } from 'react';

const PinkShield = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width={60}
		height={59}
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{...props}
	>
		<path
			d='m36.202 20.396-.121 1.824 2.391-1.751.218-1.92m-2.488 1.847 2.488-1.848m-2.488 1.848a2.44 2.44 0 0 1-.801-.432m3.289-1.416 6.014-4.384m0 0 1.558-2.765-3.152.87m1.594 1.895c-.882-.604-1.224-1.018-1.594-1.896m0 0-6.028 4.5m-2.387 1.78-1.996-.205 2.549-1.884 1.834.308m-2.387 1.781 2.387-1.78m-2.387 1.78c.09.507.17.753.401 1.078m.305.338-1.09.846-.308-.341 1.093-.843m.305.338 2.809-2.232-.257-.308-2.857 2.202'
			stroke='#A3185D'
			strokeWidth={0.3}
		/>
		<path
			d='M30.267 46c6.8 0 15.5-4 19-6V24.5m-19 21.5c-6.8 0-15.5-4-19-6V24.5m19 21.5V9m-19 0V1h38v8m-38 0h19m-19 0v15.5m38-15.5h-19m19 0v15.5m-38 0h38'
			stroke='#A3185D'
		/>
		<path
			d='M30.267 46c6.8 0 15.5-4 19-6V24.5m-19 21.5c-6.8 0-15.5-4-19-6V24.5m19 21.5V9m-19 0V1h38v8m-38 0h19m-19 0v15.5m38-15.5h-19m19 0v15.5m-38 0h38'
			stroke='#A3185D'
		/>
		<path
			d='M30.267 46c6.8 0 15.5-4 19-6V24.5m-19 21.5c-6.8 0-15.5-4-19-6V24.5m19 21.5V9m-19 0V1h38v8m-38 0h19m-19 0v15.5m38-15.5h-19m19 0v15.5m-38 0h38'
			stroke='#A3185D'
		/>
		<path
			d='M30.267 46c6.8 0 15.5-4 19-6V24.5m-19 21.5c-6.8 0-15.5-4-19-6V24.5m19 21.5V9m-19 0V1h38v8m-38 0h19m-19 0v15.5m38-15.5h-19m19 0v15.5m-38 0h38'
			stroke='#A3185D'
		/>
		<path
			d='m11.267 5 15 2.5c.233-.438.365-.632.616-.885l-12.7-5.277M22.348 1.336l5.407 4.61c.669-.36 1.085-.467 1.872-.561l-1.755-4.05M33.044 1.36l-2.013 4.002c.92.104 1.4.225 2.177.585l5.22-4.588M46.573 1.327l-12.49 5.28c.247.262.376.424.538.822l14.355-2.403'
			stroke='#A3185D'
			strokeWidth={0.3}
		/>
		<path
			d='M29.91 44.585a7.916 7.916 0 0 1-1.318-.69c-.548-.905-.79-1.381-1.166-2.204-.23-.736-.336-1.147-.433-1.88-1.305-2.435-2.404-3.43-4.622-4.946-1.035.005-1.752.036-2.712.16m10.1-5.798h-2.377l-.497-.325c.28-.206.374-.405.497-.82-.2-.39-.328-.587-.734-.649-.662.327-1.047.475-1.771.649l-.8-.454c-.347-.028-.6.054-1.102.28-.67.693-1.065 1.045-1.836 1.534a50.341 50.341 0 0 0-2.57 2.02m1.09 3.564c-.328.042-.684.096-1.09.163-.53-.323-.835-.743-1.383-1.534a2.35 2.35 0 0 1 0-.994c.517-.458.959-.842 1.383-1.2m1.09 3.565c.485-.296.636-.507.666-.977-.389-1.208-.76-1.785-1.756-2.587m-.705 2.51c-.176-.14-.187-.265-.097-.553.518.46.794.373 1.28.097m3.66-3.581c-.28-.008-.466.077-.816.414.266.227.425.328.766.359m.05-.773c.38.03.59.155.962.414-.364.26-.58.355-1.012.359m.05-.773c-.129.123-.202.19-.24.372-.002.123.072.206.19.4m.05-.772c.118.136.173.22.196.414-.044.175-.12.24-.246.359'
			stroke='#A3185D'
			strokeWidth={0.2}
		/>
		<path
			d='M29.91 44.585a7.92 7.92 0 0 1-1.318-.69c-.548-.905-.79-1.381-1.166-2.204-.23-.736-.335-1.147-.432-1.88-1.306-2.435-2.405-3.43-4.623-4.946-1.034.005-1.752.036-2.712.16m10.1-5.798h-2.377l-.496-.325c.278-.206.373-.405.497-.82-.201-.39-.328-.587-.735-.649-.662.327-1.046.475-1.771.649l-.8-.454c-.347-.028-.6.054-1.101.28-.671.693-1.066 1.045-1.836 1.534a50.38 50.38 0 0 0-2.571 2.02m1.09 3.564c-.328.042-.684.096-1.09.163-.53-.323-.835-.743-1.383-1.534a2.352 2.352 0 0 1 0-.994c.518-.458.96-.842 1.383-1.2m1.09 3.565c.485-.296.637-.507.666-.977-.388-1.208-.759-1.785-1.756-2.587m-.704 2.51c-.177-.14-.188-.265-.097-.553.518.46.793.373 1.278.097m3.66-3.581c-.279-.008-.464.077-.815.414.266.227.425.328.766.359m.05-.773c.38.03.59.155.962.414-.363.26-.58.355-1.012.359m.05-.773c-.129.123-.202.19-.24.372-.002.123.073.206.19.4m.05-.772c.118.136.173.22.196.414-.043.175-.119.24-.246.359'
			stroke='#A3185D'
			strokeWidth={0.2}
		/>
		<path
			d='M29.91 44.585a7.92 7.92 0 0 1-1.318-.69c-.548-.905-.79-1.381-1.166-2.204-.23-.736-.335-1.147-.432-1.88-1.306-2.435-2.405-3.43-4.623-4.946-1.034.005-1.752.036-2.712.16m10.1-5.798h-2.377l-.496-.325c.278-.206.373-.405.497-.82-.201-.39-.328-.587-.735-.649-.662.327-1.046.475-1.771.649l-.8-.454c-.347-.028-.6.054-1.101.28-.671.693-1.066 1.045-1.836 1.534a50.38 50.38 0 0 0-2.571 2.02m1.09 3.564c-.328.042-.684.096-1.09.163-.53-.323-.835-.743-1.383-1.534a2.352 2.352 0 0 1 0-.994c.518-.458.96-.842 1.383-1.2m1.09 3.565c.485-.296.637-.507.666-.977-.388-1.208-.759-1.785-1.756-2.587m-.704 2.51c-.177-.14-.188-.265-.097-.553.518.46.793.373 1.278.097m3.66-3.581c-.279-.008-.464.077-.815.414.266.227.425.328.766.359m.05-.773c.38.03.59.155.962.414-.363.26-.58.355-1.012.359m.05-.773c-.129.123-.202.19-.24.372-.002.123.073.206.19.4m.05-.772c.118.136.173.22.196.414-.043.175-.119.24-.246.359'
			stroke='#A3185D'
			strokeWidth={0.2}
		/>
		<path
			d='M29.91 44.585a7.92 7.92 0 0 1-1.318-.69c-.548-.905-.79-1.381-1.166-2.204-.23-.736-.335-1.147-.432-1.88-1.306-2.435-2.405-3.43-4.623-4.946-1.034.005-1.752.036-2.712.16m10.1-5.798h-2.377l-.496-.325c.278-.206.373-.405.497-.82-.201-.39-.328-.587-.735-.649-.662.327-1.046.475-1.771.649l-.8-.454c-.347-.028-.6.054-1.101.28-.671.693-1.066 1.045-1.836 1.534a50.38 50.38 0 0 0-2.571 2.02m1.09 3.564c-.328.042-.684.096-1.09.163-.53-.323-.835-.743-1.383-1.534a2.352 2.352 0 0 1 0-.994c.518-.458.96-.842 1.383-1.2m1.09 3.565c.485-.296.637-.507.666-.977-.388-1.208-.759-1.785-1.756-2.587m-.704 2.51c-.177-.14-.188-.265-.097-.553.518.46.793.373 1.278.097m3.66-3.581c-.279-.008-.464.077-.815.414.266.227.425.328.766.359m.05-.773c.38.03.59.155.962.414-.363.26-.58.355-1.012.359m.05-.773c-.129.123-.202.19-.24.372-.002.123.073.206.19.4m.05-.772c.118.136.173.22.196.414-.043.175-.119.24-.246.359'
			stroke='#A3185D'
			strokeWidth={0.3}
		/>
		<path
			d='M53.495 38.987c.049-2.556-.24-3.846-1.334-5.918'
			stroke='#A3185D'
		/>
		<path
			d='M51.845 44.414c1.968-5.09 2.275-8.216 2.581-13.872-.413-5.539-.596-8.414-.565-13.276'
			stroke='#A3185D'
		/>
		<path d='M53.861 17.718c-.227-3.864-.204-6.084 0-10.162' stroke='#A3185D' />
		<path
			d='M25.956 57.964c2.236-2.638 4.06-3.897 8.682-5.527 5.805-1.338 8.888-2.728 11.738-4.06 3.326-1.875 4.637-3.154 6.553-5.61'
			stroke='#A3185D'
			strokeWidth={2}
		/>
		<path
			d='M25.956 57.964c2.236-2.638 4.06-3.897 8.682-5.527 5.805-1.338 8.888-2.728 11.738-4.06 3.326-1.875 4.637-3.154 6.553-5.61'
			stroke='#A3185D'
			strokeWidth={2}
		/>
		<path
			d='M25.956 57.964c2.236-2.638 4.06-3.897 8.682-5.527 5.805-1.338 8.888-2.728 11.738-4.06 3.326-1.875 4.637-3.154 6.553-5.61'
			stroke='#A3185D'
			strokeWidth={2}
		/>
		<path
			d='M25.956 57.964c2.236-2.638 4.06-3.897 8.682-5.527 5.805-1.338 8.888-2.728 11.738-4.06 3.326-1.875 4.637-3.154 6.553-5.61'
			stroke='#A3185D'
			strokeWidth={2}
		/>
		<path d='m52.617 43.436 2.034-.669' stroke='#A3185D' />
		<path
			d='m56.987 41.06-.217.938.505.635-.885.478-.416.915-.669-.46-.92.28.216-.938-.504-.635.884-.478.417-.915.668.46.921-.28Z'
			fill='#A3185D'
		/>
		<ellipse
			cx={55.667}
			cy={42.539}
			rx={1.176}
			ry={1.437}
			transform='rotate(43.051 55.667 42.54)'
			fill='#A3185D'
		/>
		<path
			d='m47.803 41.37.724.635.798-.144.003 1.006.61.8-.722.37-.189.944-.724-.634-.798.144-.003-1.006-.61-.8.722-.37.189-.944Z'
			fill='#A3185D'
		/>
		<ellipse
			cx={48.482}
			cy={43.233}
			rx={1.176}
			ry={1.437}
			transform='rotate(-18.727 48.482 43.233)'
			fill='#A3185D'
		/>
		<path
			d='M54.344 31.172c.642-1.25 1.024-1.801 1.762-2.354'
			stroke='#A3185D'
			strokeWidth={0.5}
		/>
		<path
			d='m51.377 30.387.601.752.811-.002-.174.99.46.895-.776.239-.352.896-.601-.752-.811.001.174-.99-.46-.895.776-.238.352-.896Z'
			fill='#A3185D'
		/>
		<ellipse
			cx={51.718}
			cy={32.34}
			rx={1.176}
			ry={1.437}
			transform='rotate(-8.593 51.718 32.34)'
			fill='#A3185D'
		/>
		<path
			d='m57.447 26.057.05.961.66.472-.719.703-.148.995-.77-.258-.808.522-.05-.961-.66-.472.72-.703.148-.995.769.259.808-.523Z'
			fill='#A3185D'
		/>
		<ellipse
			cx={56.586}
			cy={27.843}
			rx={1.176}
			ry={1.437}
			transform='rotate(27.071 56.586 27.843)'
			fill='#A3185D'
		/>
		<path
			d='m50.498 17.285.849.455.745-.32.23.978.774.642-.62.525.03.962-.849-.455-.745.32-.23-.979-.774-.642.62-.524-.03-.962Z'
			fill='#A3185D'
		/>
		<ellipse
			cx={51.58}
			cy={18.946}
			rx={1.176}
			ry={1.437}
			transform='rotate(-31.762 51.58 18.946)'
			fill='#A3185D'
		/>
		<path
			d='m56.268 12.353.282.92.755.298-.526.857.098 1-.81-.063-.656.704-.282-.92-.755-.298.526-.857-.098-1 .81.063.656-.704Z'
			fill='#A3185D'
		/>
		<ellipse
			cx={55.867}
			cy={14.295}
			rx={1.176}
			ry={1.437}
			transform='rotate(12.988 55.867 14.295)'
			fill='#A3185D'
		/>
		<path
			d='m55.345 4.753-.129.954.561.586-.836.558-.33.95-.708-.396-.891.364.128-.954-.56-.586.836-.558.33-.95.708.396.89-.364Z'
			fill='#A3185D'
		/>
		<ellipse
			cx={54.168}
			cy={6.348}
			rx={1.176}
			ry={1.437}
			transform='rotate(37.721 54.168 6.348)'
			fill='#A3185D'
		/>
		<path
			d='M53.942 18.192c.274-1.517.554-2.134 1.308-2.751M54.123 23.963c-.566-1.929-.978-2.886-1.85-4.419M45.929 48.47c1.466-1.63 1.991-2.576 2.303-4.33'
			stroke='#A3185D'
			strokeWidth={0.5}
		/>
		<path
			d='M27.553 58.318c-1.025.152-1.56.01-2.34-1.267 2.052-1.858 3.257-2.555 5.454-3.5l.966.837c-1.91 1.424-2.902 2.25-4.08 3.93Z'
			fill='#A3185D'
			stroke='#A3185D'
			strokeWidth={0.5}
		/>
		<path
			d='M27.553 58.318c-1.025.152-1.56.01-2.34-1.267 2.052-1.858 3.257-2.555 5.454-3.5l.966.837c-1.91 1.424-2.902 2.25-4.08 3.93Z'
			fill='#A3185D'
			stroke='#A3185D'
			strokeWidth={0.5}
		/>
		<path
			d='M27.553 58.318c-1.025.152-1.56.01-2.34-1.267 2.052-1.858 3.257-2.555 5.454-3.5l.966.837c-1.91 1.424-2.902 2.25-4.08 3.93Z'
			fill='#A3185D'
			stroke='#A3185D'
			strokeWidth={0.5}
		/>
		<path
			d='M27.553 58.318c-1.025.152-1.56.01-2.34-1.267 2.052-1.858 3.257-2.555 5.454-3.5l.966.837c-1.91 1.424-2.902 2.25-4.08 3.93Z'
			fill='#A3185D'
			stroke='#A3185D'
			strokeWidth={0.5}
		/>
		<path d='M6.942 39.188c-.049-2.556.24-3.845 1.334-5.918' stroke='#A3185D' />
		<path
			d='M8.591 44.615c-1.967-5.09-2.275-8.215-2.58-13.872.413-5.539.595-8.414.565-13.276'
			stroke='#A3185D'
		/>
		<path d='M6.575 17.92c.228-3.865.204-6.085 0-10.163' stroke='#A3185D' />
		<path
			d='M34.481 58.165c-2.236-2.638-4.06-3.897-8.682-5.527-5.806-1.337-8.889-2.727-11.739-4.06-3.326-1.875-4.636-3.154-6.552-5.61'
			stroke='#A3185D'
			strokeWidth={2}
		/>
		<path
			d='M34.481 58.165c-2.236-2.638-4.06-3.897-8.682-5.527-5.806-1.337-8.889-2.727-11.739-4.06-3.326-1.875-4.636-3.154-6.552-5.61'
			stroke='#A3185D'
			strokeWidth={2}
		/>
		<path
			d='M34.481 58.165c-2.236-2.638-4.06-3.897-8.682-5.527-5.806-1.337-8.889-2.727-11.739-4.06-3.326-1.875-4.636-3.154-6.552-5.61'
			stroke='#A3185D'
			strokeWidth={2}
		/>
		<path
			d='M34.481 58.165c-2.236-2.638-4.06-3.897-8.682-5.527-5.806-1.337-8.889-2.727-11.739-4.06-3.326-1.875-4.636-3.154-6.552-5.61'
			stroke='#A3185D'
			strokeWidth={2}
		/>
		<path d='m7.82 43.637-2.034-.668' stroke='#A3185D' />
		<path
			d='m3.45 41.261.216.938-.504.636.884.477.417.916.668-.46.921.28-.216-.938.504-.636-.885-.478-.417-.915-.668.46-.92-.28Z'
			fill='#A3185D'
		/>
		<ellipse
			rx={1.176}
			ry={1.437}
			transform='scale(-1 1) rotate(43.051 -56.565 15.324)'
			fill='#A3185D'
		/>
		<path
			d='m12.634 41.572-.724.634-.799-.144-.002 1.006-.61.8.722.37.188.945.724-.635.799.144.002-1.005.61-.8-.721-.371-.19-.944Z'
			fill='#A3185D'
		/>
		<ellipse
			rx={1.176}
			ry={1.437}
			transform='scale(-1 1) rotate(-18.727 125.727 57.966)'
			fill='#A3185D'
		/>
		<path
			d='M6.092 31.373c-.642-1.249-1.023-1.8-1.762-2.354'
			stroke='#A3185D'
			strokeWidth={0.5}
		/>
		<path
			d='m9.06 30.588-.602.752-.81-.001.173.99-.459.895.776.238.352.896.6-.752.812.002-.174-.99.459-.896-.776-.238-.352-.896Z'
			fill='#A3185D'
		/>
		<ellipse
			rx={1.176}
			ry={1.437}
			transform='scale(-1 1) rotate(-8.593 212.21 74.294)'
			fill='#A3185D'
		/>
		<path
			d='m2.99 26.259-.05.96-.66.473.718.703.149.994.769-.258.808.523.05-.962.66-.472-.719-.703-.149-.994-.769.258-.808-.522Z'
			fill='#A3185D'
		/>
		<ellipse
			rx={1.176}
			ry={1.437}
			transform='scale(-1 1) rotate(27.071 -60.172 6.024)'
			fill='#A3185D'
		/>
		<path
			d='m9.938 17.486-.848.455-.746-.32-.229.979-.774.642.619.524-.029.962.849-.455.745.32.23-.979.773-.641-.619-.525.03-.962Z'
			fill='#A3185D'
		/>
		<ellipse
			rx={1.176}
			ry={1.437}
			transform='scale(-1 1) rotate(-31.762 29.223 25.139)'
			fill='#A3185D'
		/>
		<path
			d='m4.169 12.555-.283.92-.755.297.527.857-.098 1 .809-.063.656.704.283-.92.755-.297-.527-.857.098-1.001-.809.063-.656-.703Z'
			fill='#A3185D'
		/>
		<ellipse
			rx={1.176}
			ry={1.437}
			transform='scale(-1 1) rotate(12.988 -65.959 -12.825)'
			fill='#A3185D'
		/>
		<path
			d='m5.092 4.954.129.954-.562.586.837.558.33.95.708-.396.89.364-.128-.954.561-.586-.836-.558-.33-.95-.708.397-.891-.365Z'
			fill='#A3185D'
		/>
		<ellipse
			rx={1.176}
			ry={1.437}
			transform='scale(-1 1) rotate(37.721 -12.721 -5.9)'
			fill='#A3185D'
		/>
		<path
			d='M6.494 18.393c-.274-1.517-.553-2.133-1.307-2.75M6.314 24.165c.565-1.93.978-2.887 1.849-4.42M14.508 48.672c-1.467-1.631-1.992-2.577-2.303-4.33'
			stroke='#A3185D'
			strokeWidth={0.5}
		/>
		<path
			d='M32.883 58.519c1.025.152 1.561.01 2.341-1.267-2.053-1.858-3.258-2.555-5.454-3.5l-.967.837c1.91 1.424 2.902 2.25 4.08 3.93Z'
			fill='#A3185D'
			stroke='#A3185D'
			strokeWidth={0.5}
		/>
		<path
			d='M30.265 24.514h-.1v.1l.08 21.27v.11l.109-.01c7.07-.645 11.297-1.97 18.868-5.929l.054-.028V24.514H30.265Zm2.882 10.816c-.265-.926-.384-1.658-.341-2.395.043-.736.248-1.484.643-2.443l1.128.733.093.06.05-.098c.48-.93.871-1.379 1.771-2.087l.084-.066-.071-.08-.94-1.052c.895-.622 1.63-.982 2.415-1.148.788-.166 1.635-.137 2.755.03l-.3 1.402-.02.093.091.025c1.04.275 1.54.513 2.334 1.075l.084.06.057-.086.782-1.166c.736.632 1.223 1.179 1.572 1.797.35.619.565 1.316.747 2.254l-1.368.171-.1.013.013.1c.104.8.099 1.246 0 2.048l-.01.078.073.028 1.262.487c-.313.898-.616 1.557-1.028 2.126-.409.565-.929 1.046-1.684 1.586l-.653-1.236-.052-.098-.092.061c-.442.295-.797.485-1.177.615-.38.129-.79.198-1.342.245l-.091.007v1.556c-.977-.039-1.718-.138-2.409-.362-.69-.224-1.334-.576-2.116-1.126l.805-1.179.051-.074-.068-.059c-.428-.365-.728-.666-.976-1.004-.249-.337-.449-.714-.672-1.233l-.036-.084-.088.03-1.176.396Zm4.41-2.054c0 .885.68 2.014 1.969 2.014 1.059 0 1.921-.968 1.921-2.014 0-1.047-.781-1.969-1.921-1.969-1.249 0-1.968 1.018-1.968 1.969Z'
			fill='#A3185D'
			stroke='#A3185D'
			strokeWidth={0.2}
		/>
		<path
			d='M11.332 9.003h-.1v.1L11.18 24.47v.1H30.306V9.003H11.332Zm7.619 6.775-.06-.078-.08.06-2.44 1.853-.866-1.223 2.291-1.878.076-.063-.061-.077-1.835-2.299 1.274-1.103 1.905 2.44.061.079.079-.062 2.34-1.825.98 1.158-2.465 2.001-.077.063.061.077 4.803 6.053-1.226.982-4.76-6.158Z'
			fill='#A3185D'
			stroke='#A3185D'
			strokeWidth={0.2}
		/>
	</svg>
);

export default PinkShield;
