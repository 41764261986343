import styles from './CommercialAuth.css';

const CommercialAuth = () => {
	return (
		<section className={styles.page}>
			<div>
				<h3>¿Para qué es el trámite?</h3>
				<p>
					Para habilitar o registrar el tipo de sitio o local destinado a
					ejercer el comercio.
				</p>
				<h3>¿Quién puede realizarlo?</h3>
				<p>
					El trámite tiene que ser realizado por la persona interesada o en su
					defecto, por apoderado o gestor autorizado, quienes deberán concurrir
					con su documentación correspondiente que acredite la respresentación
					invocada.
				</p>
				<h3>¿Qué beneficio obtengo si registro mi emprendimiento?</h3>
				<p>
					Al registrar tu emprendimiento accedes a las diferentes a las
					diferentes líneas de <b>PROMOCIÓN DE EMPLEO</b> que esta ofreciendo el
					ministerio de trabajo, empleo y seguridad social mediante{' '}
					<b>Oficina de Empleo Municipal</b>.
				</p>
				<p>
					Esto te permitirá participar en entrenamientos laborales, donde se
					vincula mano de obra capacitada con la demanda <b>RRHH</b> (Recursos
					Humanos) que tu empresa necesita. Por medio de esto podrás capacitar y
					formar prácticas de trabajo a trabajadores desocupados mejorando la
					condición de empleabilidad y fortaleciendo la competencia de tu
					empresa.
				</p>
				<h3>¿A que programa pueden acceder los beneficiarios?</h3>
				<ul className={styles.ul}>
					<li>
						Programa joven con más y mejor trabajo (jóvenes de 18 a 24 años).
					</li>
					<li>
						Programa promover igualdades (mayores de 18 años con discapacidad).
					</li>
					<li>Programa que estén cobrando fondos de desempleo.</li>
					<li>
						Programa de formación profesional (desocupados mayores de 25 años
						que hayan realizado cursos de formación profesional a través de la
						oficina de empleo)
					</li>
					<li>
						Programa seguro de capacitación y empleo (mujeres víctimas de
						violencia y personal del colectivo L.G.B.T.).
					</li>
				</ul>
				<h3>Tasa de Contribuciones:</h3>
				<p>
					<b>A TENER EN CUENTA PARA INQUILINOS DE INMUEBLES</b>
				</p>
				<ol className={styles.ul}>
					<li>
						En algunos contratos el dueño los hace responsable de la
						contribución que incide sobre los inmuebles.
					</li>
					<li>
						Planos de estructura y electricidad a cargo de la persona dueña del
						inmueble:
					</li>
					<p>
						Todo local debe contar con esto, recomendamos a los inquilinos que
						verifiquen que cuenten con lo mismo, ta que no podrá ser habilitado
						si no se verifica la existencia de lo ya mencionado para no generar
						contratiempos en su habilitación.
					</p>
				</ol>
				<div>
					<h3>Requisitos para Habilitación:</h3>
					<p>Los requisitos varían según el rubro.</p>
					<br />
					<details className={styles['detail-box']}>
						<summary>
							<b>Habiltación de un Comercio:</b>
						</summary>
						<ul>
							<li>Fotocopia de DNI del titular (1° y 2° hoja).</li>
							<li>Certificado de domicilio del titular.</li>
							<li>
								Fotocopia de impuesto pago del local comercial (luz, agua,
								internet, etc.).
							</li>
							<li>
								<b>En caso de alquiler: </b>Contrato de locación.
								<br />
								<b>En caso de préstamo de propiedad: </b>Autorización del
								propietario del inmueble.
								<br />
								<b>
									En caso de relación conyugal con el propietario del inmueble:{' '}
								</b>
								Fotocopia de acta de matrimonio.
							</li>
							<li>
								Certificado de libre de deuda del titular (Juzgado de Faltas
								Municipal).
							</li>
							<li>
								Certificado de libre de deuda del local comercial (Rentas
								Municipal).
							</li>
							<li>Cetificado de fumigación (Defensa Civil).</li>
							<li>Matafuego.</li>
							<li>Carpeta de archivo colgate.</li>
							<br />
							<b>En caso que el rubro sea kiosco, despensa, pollería, etc.:</b>
							<li>Carnet de sanidad (Coordinación de Bromatología).</li>
							<li>
								Vestimenta reglamentaria (Chaquetilla, gorra, guantes) en caso
								que se manipulen alimentos.
							</li>
						</ul>
					</details>
					<details className={styles['detail-box']}>
						<summary>
							<b>Habilitación para Espectaculo Público:</b>
						</summary>
						<ul>
							<li>Fotocopia de DNI del titular (1° y 2° hoja).</li>
							<li>Certificado de domicilio</li>
							<li>
								<b>En caso de alquiler: </b>Contrato de locación.
								<br />
								<b>En caso de préstamo de propiedad: </b>Autorización del
								propietario del inmueble.
								<br />
								<b>
									En caso de relación conyugal con el propietario del inmueble:{' '}
								</b>
								Fotocopia de acta de matrimonio.
							</li>
							<li>
								Certificado de libre de deuda del titular (Juzgado de Faltas
								Municipal)
							</li>
							<li>Seguro de Espectáculos.</li>
							<br />
							<b>En caso que el espectáculo sea de gran magnitud:</b>
							<li>Ambulancia.</li>
							<li>Bomberos.</li>
						</ul>
					</details>
				</div>
			</div>
		</section>
	);
};
export default CommercialAuth;
