import { Progress } from 'antd';
import { useEffect, useMemo, useState } from 'react';

interface CustomProgressProps {
	amount: number;
	totalAmount: number;
}

export const CustomProgress: React.FC<CustomProgressProps> = ({
	amount,
	totalAmount,
}) => {
	const [percent, setPercent] = useState(0);

	const finalPercent = useMemo(
		() => Math.round((amount / totalAmount) * 100 * 100) / 100,
		[amount, totalAmount]
	);

	useEffect(() => {
		if (percent < finalPercent) {
			setPercent((prev) => {
				const newValue = prev + 1;
				if (newValue < finalPercent) {
					return newValue;
				} else {
					return finalPercent;
				}
			});
		}
	}, [percent, finalPercent]);

	return (
		<Progress strokeLinecap='square' size={['100%', 20]} percent={percent} />
	);
};
