import React, { useEffect, useState } from 'react';
import {
	ConfigProvider,
	Result,
	Table,
	Card,
	Button,
	Space,
	Tooltip,
	message,
	Popconfirm,
} from 'antd';
import type { ColumnsType, TableProps } from 'antd/es/table';
import Icon from '@ant-design/icons/lib/components/Icon';
import { FcReading } from 'react-icons/fc';
import { LuFileEdit, LuTrash2, LuFilePlus2 } from 'react-icons/lu';
import dayjs from 'dayjs';
import { CustomError, Pagination } from '../../../../types/envelope.type';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { useNavigate } from 'react-router-dom';
import {
	PrivateRoutes,
	PublicRoutes,
	TourismWithRelations,
} from '../../../../models';
import {
	deleteTourismWithTopics,
	getTourismWithRelations,
} from '../../../../services/tourism.controller';

const TourismTable: React.FC = () => {
	const [pagination, setPagination] = useState<Pagination>({
		page: 1,
		items_per_page: 5,
	});
	const [sort, setSort] = useState<{
		sorted_by?: 'created_at' | 'update_at' | 'id';
		order?: 'asc' | 'desc';
	}>({ sorted_by: 'created_at', order: 'desc' });
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [deleteLoading, setDeleteLoading] = useState<{
		loading: boolean;
		id: number | undefined;
	}>({ loading: false, id: undefined });
	const [messageApi, contextHolder] = message.useMessage();

	const tourism = useAppSelector((state) => state.tourism);

	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const successEvent = async (message: string) => {
		await messageApi.open({
			type: 'success',
			content: message,
		});
	};

	const errorEvent = async (message: string) => {
		await messageApi.open({
			type: 'error',
			content: message,
		});
	};

	useEffect(() => {
		setIsLoading(true);
		getTourismWithRelations(dispatch, {
			page: pagination.page,
			items_per_page: pagination.items_per_page,
			sort_by: sort.sorted_by,
			order: sort.order,
		})
			.catch((e) => console.error(e))
			.finally(() => setIsLoading(false));
	}, [pagination.page, pagination.items_per_page, sort.order, sort.sorted_by]);

	useEffect(() => {
		if (tourism?.pagination)
			setPagination((prev) => ({
				...prev,
				total_items: tourism.pagination?.total_items,
				total_pages: tourism.pagination?.total_pages,
			}));
	}, [tourism.pagination]);

	const handleRefetch = () => {
		setIsLoading(true);
		getTourismWithRelations(dispatch, {
			page: pagination.page,
			items_per_page: pagination.items_per_page,
			sort_by: sort.sorted_by,
			order: sort.order,
		})
			.catch((e) => console.error(e))
			.finally(() => setIsLoading(false));
	};

	const handleDeleteTourism = async (id: number) => {
		setDeleteLoading({ loading: true, id });
		const response = await deleteTourismWithTopics(id);

		if (response.success) {
			await successEvent('Se ha eliminado 1 registro');
			handleRefetch();
			setDeleteLoading({ loading: false, id: undefined });
		} else {
			await errorEvent(
				(response?.error as CustomError)?.message ??
					'Hubo un error, por favor intenta nuevamente.'
			);
			setDeleteLoading({ loading: false, id: undefined });
		}
	};

	const columns: ColumnsType<TourismWithRelations> = [
		{
			title: 'ID',
			dataIndex: 'id',
			align: 'center',
			sorter: true,
			width: '5%',
		},
		{
			title: 'RESPONSABLE',
			dataIndex: 'users',
			render: (_, record) =>
				`${record.users?.first_name} ${record.users?.last_name}`,
			width: '20%',
		},
		{
			title: 'TÍTULO',
			dataIndex: 'title',
			ellipsis: true,
			render: (value) => (
				<div
					style={{
						wordWrap: 'break-word',
						wordBreak: 'break-word',
					}}
				>
					{value}
				</div>
			),
			width: '20%',
		},
		{
			title: 'FECHA DE CREACIÓN',
			dataIndex: 'created_at',
			render: (created_at) => dayjs(created_at).format('DD/MM/YYYY HH:mm:ss'),
			align: 'center',
			sorter: true,
			width: '20%',
		},
		{
			title: 'ÚLTIMA MODIFICACIÓN',
			dataIndex: 'update_at',
			render: (value) =>
				value ? dayjs(value).format('DD/MM/YYYY HH:mm:ss') : 'No definido',
			align: 'center',
			sorter: true,
			width: '20%',
		},
		{
			title: 'ACCIONES',
			align: 'center',
			width: '15%',
			render: (_, record) => (
				<Space>
					<Tooltip
						title={
							<Space direction='vertical' style={{ textAlign: 'center' }}>
								<span>Actualizar</span>
								<span>Próximamente disponible</span>
							</Space>
						}
					>
						<Button onClick={() => null} disabled>
							<Icon rev='1.0.0' component={() => <LuFileEdit />} />
						</Button>
					</Tooltip>
					<Popconfirm
						title='Eliminar noticia de Turismo'
						description='¿Estás seguro de eliminar?'
						onConfirm={() => handleDeleteTourism(record.id)}
						okText='Si'
						cancelText='No'
					>
						<Tooltip title='Eliminar'>
							<Button
								danger
								loading={
									deleteLoading.loading && deleteLoading.id === record.id
								}
							>
								<Icon rev='1.0.0' component={() => <LuTrash2 />} />
							</Button>
						</Tooltip>
					</Popconfirm>
				</Space>
			),
		},
	];

	const onChange: TableProps<TourismWithRelations>['onChange'] = (
		pagination,
		_filters,
		sorter,
		_extra
	) => {
		setPagination({
			page: pagination.current ?? 1,
			items_per_page: pagination.pageSize ?? 5,
		});

		if (Object.keys(sorter).length) {
			setSort({
				sorted_by:
					(sorter as any)?.order !== undefined
						? (sorter as any)?.field
						: 'created_at',
				order: (sorter as any)?.order === 'ascend' ? 'asc' : 'desc',
			});
		}
	};

	return (
		<>
			{contextHolder}
			<Card
				title='Listado de noticias de Turismo'
				style={{ margin: '1.5em 0' }}
				extra={
					<Button
						type='primary'
						icon={<Icon rev='1.0.0' component={() => <LuFilePlus2 />} />}
						onClick={() =>
							navigate(
								`/${PrivateRoutes.PRIVATE}/${PrivateRoutes.EDIT}/${PublicRoutes.TOURISM}`
							)
						}
					>
						Crear noticia de Turismo
					</Button>
				}
			>
				<ConfigProvider
					renderEmpty={() => (
						<Result
							icon={<Icon rev='1.0.0' component={() => <FcReading />} />}
							title='No se han encontrado resultados'
							subTitle='Intentá cambiar los filtros o consultalo con tu desarrollador de confianza'
						/>
					)}
				>
					<Table
						rowKey={'id'}
						columns={columns}
						dataSource={isLoading ? [] : tourism.data ?? []}
						onChange={onChange}
						loading={isLoading}
						pagination={{
							pageSize: pagination.items_per_page,
							pageSizeOptions: ['5', '10', '25'],
							showSizeChanger: true,
							total: pagination?.total_items ?? 0,
							current: pagination.page,
							showTotal: (total, range) => {
								return `${range[0]} - ${range[1]} de ${total}`;
							},
						}}
					/>
				</ConfigProvider>
			</Card>
		</>
	);
};

export default TourismTable;
