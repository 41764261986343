// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._formInput__input__M1D6J {
	margin-bottom: 20px;
	display: flex;
	flex-direction: column;
}

._formInput__input__M1D6J label {
	display: block;
	margin-bottom: 10px;
	font-size: 14px;
	font-family: var(--font-family);
}

._formInput__input__M1D6J input {
	height: 40px;
	padding: 0 10px;
	background-color: var(--grayOne);
	border-bottom: 2px solid var(--darkPink);
	border-top: 0;
	border-left: 0;
	border-right: 0;
	outline: none;
}

._formInput__input__M1D6J small {
	display: block;
	color: #e74c3c;
	font-weight: 400;
	font-size: 12px;
	padding-top: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/FormInput/_formInput.css"],"names":[],"mappings":"AAAA;CACC,mBAAmB;CACnB,aAAa;CACb,sBAAsB;AACvB;;AAEA;CACC,cAAc;CACd,mBAAmB;CACnB,eAAe;CACf,+BAA+B;AAChC;;AAEA;CACC,YAAY;CACZ,eAAe;CACf,gCAAgC;CAChC,wCAAwC;CACxC,aAAa;CACb,cAAc;CACd,eAAe;CACf,aAAa;AACd;;AAEA;CACC,cAAc;CACd,cAAc;CACd,gBAAgB;CAChB,eAAe;CACf,gBAAgB;AACjB","sourcesContent":[".input {\r\n\tmargin-bottom: 20px;\r\n\tdisplay: flex;\r\n\tflex-direction: column;\r\n}\r\n\r\n.input label {\r\n\tdisplay: block;\r\n\tmargin-bottom: 10px;\r\n\tfont-size: 14px;\r\n\tfont-family: var(--font-family);\r\n}\r\n\r\n.input input {\r\n\theight: 40px;\r\n\tpadding: 0 10px;\r\n\tbackground-color: var(--grayOne);\r\n\tborder-bottom: 2px solid var(--darkPink);\r\n\tborder-top: 0;\r\n\tborder-left: 0;\r\n\tborder-right: 0;\r\n\toutline: none;\r\n}\r\n\r\n.input small {\r\n\tdisplay: block;\r\n\tcolor: #e74c3c;\r\n\tfont-weight: 400;\r\n\tfont-size: 12px;\r\n\tpadding-top: 5px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `_formInput__input__M1D6J`
};
export default ___CSS_LOADER_EXPORT___;
