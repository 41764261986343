// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._frequentServices__services__m1qEd {
	margin-top: 0.5em;
	text-align: center;
	width: 100%;
}

._frequentServices__flex__UnhIK {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	height: auto;
	gap: 0.75em;
}

._frequentServices__button__RsQc9 {
	width: 11em;
	padding: 1rem;
	border-radius: 0.5rem;
	cursor: pointer;
}
._frequentServices__button__RsQc9:hover {
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
}

._frequentServices__cursor-pointer__DHLee {
	text-decoration: none;
}

._frequentServices__cursor-pointer__DHLee svg {
	fill: dodgerblue;
	display: block;
	max-height: 5rem;
	max-width: 100%;
	height: auto;
	margin-bottom: 1rem;
	margin-left: auto;
	margin-right: auto;
}

._frequentServices__title-button__Y0OqE {
	font-family: Arial, Helvetica, sans-serif;
	margin-top: 0;
	white-space: pre-line;
	line-height: 1.6;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Home/components/FrequentServices/_frequentServices.css"],"names":[],"mappings":"AAAA;CACC,iBAAiB;CACjB,kBAAkB;CAClB,WAAW;AACZ;;AAEA;CACC,aAAa;CACb,eAAe;CACf,uBAAuB;CACvB,YAAY;CACZ,WAAW;AACZ;;AAEA;CACC,WAAW;CACX,aAAa;CACb,qBAAqB;CACrB,eAAe;AAChB;AACA;CACC,4CAA4C;AAC7C;;AAEA;CACC,qBAAqB;AACtB;;AAEA;CACC,gBAAgB;CAChB,cAAc;CACd,gBAAgB;CAChB,eAAe;CACf,YAAY;CACZ,mBAAmB;CACnB,iBAAiB;CACjB,kBAAkB;AACnB;;AAEA;CACC,yCAAyC;CACzC,aAAa;CACb,qBAAqB;CACrB,gBAAgB;AACjB","sourcesContent":[".services {\r\n\tmargin-top: 0.5em;\r\n\ttext-align: center;\r\n\twidth: 100%;\r\n}\r\n\r\n.flex {\r\n\tdisplay: flex;\r\n\tflex-wrap: wrap;\r\n\tjustify-content: center;\r\n\theight: auto;\r\n\tgap: 0.75em;\r\n}\r\n\r\n.button {\r\n\twidth: 11em;\r\n\tpadding: 1rem;\r\n\tborder-radius: 0.5rem;\r\n\tcursor: pointer;\r\n}\r\n.button:hover {\r\n\tbox-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);\r\n}\r\n\r\n.cursor-pointer {\r\n\ttext-decoration: none;\r\n}\r\n\r\n.cursor-pointer svg {\r\n\tfill: dodgerblue;\r\n\tdisplay: block;\r\n\tmax-height: 5rem;\r\n\tmax-width: 100%;\r\n\theight: auto;\r\n\tmargin-bottom: 1rem;\r\n\tmargin-left: auto;\r\n\tmargin-right: auto;\r\n}\r\n\r\n.title-button {\r\n\tfont-family: Arial, Helvetica, sans-serif;\r\n\tmargin-top: 0;\r\n\twhite-space: pre-line;\r\n\tline-height: 1.6;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"services": `_frequentServices__services__m1qEd`,
	"flex": `_frequentServices__flex__UnhIK`,
	"button": `_frequentServices__button__RsQc9`,
	"cursor-pointer": `_frequentServices__cursor-pointer__DHLee`,
	"title-button": `_frequentServices__title-button__Y0OqE`
};
export default ___CSS_LOADER_EXPORT___;
