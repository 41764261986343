import { PhoneMenuIcon } from '../phone-menu-icon/PhoneMenuIcon';
import styles from './Header.module.scss';
import { useState, useMemo, useRef } from 'react';
import useScreenSize from '../../hooks/useScreenSize';
import WhiteShield from '../../assets/svg/WhiteShield';
import { NavButtons } from '../../constants';
import { Link } from 'react-router-dom';
import { SubMenuList } from '../../types/headerTypes';
import { useAppSelector } from '../../app/hooks';
import { PrivateRoutes, PublicRoutes } from '../../models';

export const Header = () => {
	const [open, setOpen] = useState<boolean>(false);
	const headerRef = useRef<HTMLDivElement>(null);

	const userState = useAppSelector((store) => store.user);

	const { width } = useScreenSize();

	const menuStyle = useMemo(() => {
		const classes = [styles.menu];

		open && classes.push(styles.open);

		return classes.join(' ');
	}, [open]);

	const iconSize = width < 576 ? 'small' : 'medium';

	return (
		<header>
			<div className={styles.container} ref={headerRef}>
				<Link to='/' onClick={() => setOpen(false)}>
					<WhiteShield />
				</Link>

				<nav className={menuStyle}>
					{NavButtons.map(
						({ title, subItems, path }: SubMenuList, i: number) => (
							<div className={styles.menuItem} key={`${i}-${title}`}>
								<Link to={`/${path}`} onClick={() => setOpen(false)}>
									{title}
								</Link>
							</div>
						)
					)}
					{userState.name ? (
						<div className={styles.menuItem}>
							<Link to={PrivateRoutes.PRIVATE} onClick={() => setOpen(false)}>
								MI CUENTA
							</Link>
						</div>
					) : (
						<div className={styles.menuItem}>
							<Link to={PublicRoutes.LOGIN} onClick={() => setOpen(false)}>
								INGRESAR
							</Link>
						</div>
					)}
				</nav>

				<div
					className={(() => {
						const classes = [styles.menuHelper];
						open && classes.push(styles.open);
						return classes.join(' ');
					})()}
					onClick={() => setOpen((prev) => !prev)}
				/>

				{width < 768 && (
					<PhoneMenuIcon
						onClick={() => setOpen((prev) => !prev)}
						isOpen={open}
						size={iconSize}
					/>
				)}
			</div>
		</header>
	);
};
