import { useNavigate } from 'react-router-dom';
import { supabase } from '../../database/supabase';
import NewsTable from './components/NewsTable/NewsTable';
import UserTable from './components/UserTable/UserTable';
import TourismTable from './components/TourismTable/TourismTable';
import styles from './_adminDashboard.css';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { resetUser } from '../../app/states/user';
import RentsTable from './components/RentsTable/RentsTable';
import { Button, ConfigProvider, Flex, Tabs, TabsProps } from 'antd';
import Icon from '@ant-design/icons/lib/components/Icon';
import { LuLogOut } from 'react-icons/lu';
import CustomTheme from '../../theme/themeConfig';
import { PrivateRoutes, PublicRoutes } from '../../models';

const tablesToShow: Record<string, TabsProps['items']> = {
	Administrador: [
		{
			key: 'users',
			label: 'Usuarios',
			children: <UserTable key='users' />,
		},
		{
			key: 'news',
			label: 'Noticias',
			children: <NewsTable key='news' />,
		},
		{
			key: 'tourism',
			label: 'Turismo',
			children: <TourismTable key='tourism' />,
		},
		{
			key: 'rents',
			label: 'Rentas',
			children: <RentsTable key='rents' />,
		},
	],
	Prensa: [
		{
			key: 'news',
			label: 'Noticias',
			children: <NewsTable key='news' />,
		},
		{
			key: 'tourism',
			label: 'Turismo',
			children: <TourismTable key='tourism' />,
		},
	],
	Rentas: [
		{
			key: 'rents',
			label: 'Rentas',
			children: <RentsTable key='rents' />,
		},
	],
};

const AdminDashboard = () => {
	const user = useAppSelector((state) => state.user);

	const navigate = useNavigate();

	const dispatch = useAppDispatch();

	const handleLogout = async () => {
		try {
			const { error } = await supabase.auth.signOut();

			if (error) {
				alert('Hubo un error al cerrar sesión! Por favor intente nuevamente.');
			} else {
				dispatch(resetUser());
			}
			navigate(`/`, { replace: true });
		} catch (error) {
			console.error(error);
		}
	};

	const items: TabsProps['items'] = tablesToShow.hasOwnProperty(user.position)
		? tablesToShow[user.position]
		: [];

	return (
		<div className={styles.container}>
			<ConfigProvider theme={CustomTheme}>
				<Flex justify='flex-end' gap={8}>
					<Button
						onClick={() =>
							navigate(
								`/${PrivateRoutes.PRIVATE}/${PublicRoutes.UPDATE_PASSWORD}`
							)
						}
						type='default'
						style={{ width: 'fit-content', alignSelf: 'flex-end' }}
					>
						Actualizar contraseña
					</Button>
					<Button
						onClick={handleLogout}
						icon={<Icon rev='1.0.0' component={() => <LuLogOut />} />}
						type='dashed'
						style={{ width: 'fit-content', alignSelf: 'flex-end' }}
					>
						Cerrar Sesión
					</Button>
				</Flex>
			</ConfigProvider>
			<Tabs
				defaultActiveKey={(items ?? [])[0]?.key}
				items={items}
				type='card'
				tabBarStyle={{ marginBottom: 0 }}
			/>
		</div>
	);
};
export default AdminDashboard;
