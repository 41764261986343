// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.eaYTwnqHyzpYsjW14IVx{font-size:16px;display:flex;gap:.5em;width:100%}.L9bGUSWMte_TNK99fRjQ{flex-direction:column}`, "",{"version":3,"sources":["webpack://./src/components/button/Group.module.scss"],"names":[],"mappings":"AAAA,sBACC,cAAA,CACA,YAAA,CACA,QAAA,CACA,UAAA,CAGD,sBACC,qBAAA","sourcesContent":[".button_group {\r\n\tfont-size: 16px;\r\n\tdisplay: flex;\r\n\tgap: 0.5em;\r\n\twidth: 100%;\r\n}\r\n\r\n.button_group__vertical {\r\n\tflex-direction: column;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button_group": `eaYTwnqHyzpYsjW14IVx`,
	"button_group__vertical": `L9bGUSWMte_TNK99fRjQ`
};
export default ___CSS_LOADER_EXPORT___;
