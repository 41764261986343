import * as React from 'react';
import { SVGProps } from 'react';
const PaycheckIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		data-name='Layer 1'
		viewBox='0 0 64 64'
		{...props}
	>
		<path d='M61 53.09v-33a5 5 0 0 0-5-5h-9v-14h-2.28l-3 1.82-3.26-2-3.25 2-3.21-2-3.25 2-3.25-2-3.25 2-3-1.82H17v14H8a5 5 0 0 0-5 5v33H1v5a5 5 0 0 0 5 5h52a5 5 0 0 0 5-5v-5Zm-48-8v-4h4v4Zm4-6h-6v8h42v-8h-6v-18h8v28H9v-28h8Zm30 6v-4h4v4ZM22.25 5.26l3.25-2 3.24 2 3.25-2 3.25 2 3.25-2 3.26 2 3.25-2v41.83H19V3.26ZM5 20.09a3 3 0 0 1 3-3h9v2H9a2 2 0 0 0-2 2v28a2 2 0 0 0 2 2h46a2 2 0 0 0 2-2v-28a2 2 0 0 0-2-2h-8v-2h9a3 3 0 0 1 3 3v33H5Zm56 38a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3v-3h58Z' />
		<path d='M27 57.09h10v2H27zM39 57.09h2v2h-2zM23 57.09h2v2h-2zM39 29.09a2 2 0 0 1 2 2h2a4 4 0 0 0-3-3.86v-2.14h-2v2h-3v4a4 4 0 0 0 4 4 2 2 0 0 1 2 2v2h-2a2 2 0 0 1-2-2h-2A4 4 0 0 0 38 41v2.14h2v-2h3v-4a4 4 0 0 0-4-4 2 2 0 0 1-2-2v-2ZM21 41.09h12v2H21zM21 37.09h12v2H21zM21 33.09h12v2H21zM21 29.09h12v2H21zM21 25.09h12v2H21zM43 7.09H21v16h22Zm-2 4h-4v-2h4Zm-6-2v2H23v-2Zm-4 4v8h-2v-8Zm2 0h2v8h-2Zm-10 0h4v8h-4Zm14 8v-8h4v8Z' />
	</svg>
);
export default PaycheckIcon;
