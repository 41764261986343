import { supabase } from '../database/supabase';
import {
	InsertTourism,
	InsertTourismTopics,
	Tourism,
	UpdateTourism,
	UpdateTourismTopics,
} from '../models/news.model';

export const getAllTourismCountService = async () => {
	return await supabase.from('tourism').select('*', { count: 'exact' });
};

export const getAllTourismService = async (
	order: 'asc' | 'desc',
	page: number,
	items_per_page: number,
	sort_by: 'id' | 'created_at' | 'update_at'
) => {
	return await supabase
		.from('tourism')
		.select(`*, tourism_topics(*), users(*)`)
		.order(sort_by, { ascending: order === 'asc' })
		.range((page - 1) * items_per_page, page * items_per_page - 1);
};

export const getTourismByIdService = async (id: number) => {
	return await supabase
		.from('tourism')
		.select(`*, tourism_topics(*), users(*)`)
		.eq('id', id);
};

export const getLastTourismIdService = async () => {
	return await supabase
		.from('tourism')
		.select('id')
		.order('id', { ascending: false })
		.limit(1);
};

export const createTourismService = async (tourism: InsertTourism) => {
	return await supabase.from('tourism').insert(tourism).select();
};

export const getLastTourismTopicsIdService = async () => {
	return await supabase
		.from('tourism_topics')
		.select('id')
		.order('id', { ascending: false })
		.limit(1);
};

export const createTourismTopicsService = async (
	data: InsertTourismTopics[]
) => {
	return await supabase.from('tourism_topics').insert(data);
};

export const updateTourismService = async ({
	tourism_id,
	tourism_data,
}: {
	tourism_id: Tourism['id'];
	tourism_data: UpdateTourism;
}) => {
	return await supabase
		.from('tourism')
		.update(tourism_data)
		.eq('id', tourism_id)
		.select(`*, tourism_topics(*)`);
};

export const upsertTourismTopicsService = async (
	data: UpdateTourismTopics[]
) => {
	return await supabase.from('tourism_topics').upsert(data).select();
};

export const deleteTourismTopicsService = async (ids: number[]) => {
	return await supabase.from('tourism_topics').delete().in('id', ids);
};

export const deleteTourismWithTopicsService = async (
	tourism_id: Tourism['id']
) => {
	return await supabase.from('tourism').delete().eq('id', tourism_id);
};

/* --- Tipados de las funciones de Supabase --- */

// Conteo de todas las noticias de turismo
export type GetAllTourismCountResponse = Awaited<
	ReturnType<typeof getAllTourismCountService>
>;

// Petición de noticias de turismo
export type GetAllTourismResponse = Awaited<
	ReturnType<typeof getAllTourismService>
>;

// Petición de una noticia de turismo por Id
export type GetTourismByIdServiceResponse = Awaited<
	ReturnType<typeof getTourismByIdService>
>;

// Obtener el último ID de una noticia de turismo
export type GetLastTourismIdService = Awaited<
	ReturnType<typeof getLastTourismIdService>
>;

// Crear una noticia de turismo
export type CreateTourismServiceResponse = Awaited<
	ReturnType<typeof createTourismService>
>;

// Obtener el último ID de un tema de noticia de turismo
export type GetLastTourismTopicsIdServiceResponse = Awaited<
	ReturnType<typeof getLastTourismTopicsIdService>
>;

// Crear temas de una noticia de turismo
export type CreateTourismTopicsServiceResponse = Awaited<
	ReturnType<typeof createTourismTopicsService>
>;

// Actualizar una noticia de turismo
export type UpdateTourismServiceResponse = Awaited<
	ReturnType<typeof updateTourismService>
>;

// Crear temas de una noticia de turismo
export type UpsertTourismTopicsServiceResponse = Awaited<
	ReturnType<typeof upsertTourismTopicsService>
>;

// Eliminar los temas de una noticia de turismo
export type DeleteTourismTopicsServiceResponse = Awaited<
	ReturnType<typeof deleteTourismTopicsService>
>;

// Eliminar una noticia de turismo con sus temas
export type DeleteTourismWithTopicsServiceResponse = Awaited<
	ReturnType<typeof deleteTourismWithTopicsService>
>;
