import dayjs from 'dayjs';
import { useMemo } from 'react';
import useScreenSize from '../../hooks/useScreenSize';
import { Button } from '../button/Button';
import styles from './_newscard.module.scss';
import { NewsWithRelations, TourismWithRelations } from '../../models';

function isNewsWithRelations(obj: any): boolean {
	return obj.hasOwnProperty('news_topics');
}

interface NewsCardProps {
	size?: 'small' | 'medium' | 'big';
	data: NewsWithRelations | TourismWithRelations;
	onClick: (id: number) => any;
	onSecondaryAction?: (id?: number) => any;
}

const NewsCard = (props: NewsCardProps) => {
	const { width } = useScreenSize();

	const cardStyle = useMemo(() => {
		const classes = [styles.notice];

		switch (props.size) {
			case 'small': {
				classes.push(styles.size_small);
				break;
			}
			case 'big': {
				classes.push(styles.size_big);
				break;
			}
			default: {
				classes.push(styles.size_medium);
				break;
			}
		}

		return classes.join(' ');
	}, [props.size]);

	return (
		<article className={cardStyle}>
			<div className={styles.notice_img}>
				<img src={props.data?.hero_img_url ?? ''} alt='' />
			</div>
			<div className={styles.info_and_buttons}>
				<div className={styles.notice_info}>
					<div className={styles.notice_date}>
						<span>{dayjs(props.data?.created_at).format('DD/MM/YYYY')}</span>
					</div>
					<h2 className={styles.notice_title}>{props.data?.title ?? ''}</h2>
					<p>
						{isNewsWithRelations(props.data)
							? (props.data as NewsWithRelations)?.news_topics[0]?.content ?? ''
							: (props.data as TourismWithRelations)?.tourism_topics[0]
									?.content ?? ''}
					</p>
				</div>
				<Button.Group layout={width < 480 ? 'vertical' : 'horizontal'}>
					<Button
						label='Ver más'
						onClick={() => props.onClick(props.data?.id)}
						block
					/>
					{props.onSecondaryAction && (
						<Button
							label='Más noticias'
							variant='secondary'
							onClick={props.onSecondaryAction}
							block
						/>
					)}
				</Button.Group>
			</div>
		</article>
	);
};

export default NewsCard;
