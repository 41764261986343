import React, { useState, useEffect } from 'react';
import {
	Card,
	Col,
	ConfigProvider,
	DatePicker,
	Result,
	Row,
	Typography,
	Spin,
	List,
	Descriptions,
} from 'antd';
import dayjs from 'dayjs';
import {
	getRentsRelations,
	getTaxesAndContributions,
} from '../../../../services/rents.controller';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import 'dayjs/locale/es';
import locale from 'antd/locale/es_ES';
import { LoadingOutlined } from '@ant-design/icons';
import Icon from '@ant-design/icons/lib/components/Icon';
import { FcReading, FcSynchronize } from 'react-icons/fc';
import { CustomProgress } from '../CustomProgress/CustomProgress';
import styles from '../../Rents.module.scss';
import useScreenSize from '../../../../hooks/useScreenSize';

const MONTHS: Record<string, string> = {
	0: 'Enero',
	1: 'Febrero',
	2: 'Marzo',
	3: 'Abril',
	4: 'Mayo',
	5: 'Junio',
	6: 'Julio',
	7: 'Agosto',
	8: 'Septiembre',
	9: 'Octubre',
	10: 'Noviembre',
	11: 'Diciembre',
};

const antIcon = <LoadingOutlined rev='1' style={{ fontSize: 24 }} spin />;

const RentsGraph: React.FC = () => {
	const [[start_date, end_date], setDate] = useState<
		[start_date: string, end_date: string]
	>([dayjs().startOf('M').toISOString(), dayjs().endOf('M').toISOString()]);

	const [isLoading, setIsLoading] = useState<boolean>(false);

	const rentsRelations = useAppSelector((state) => state.rentsRelations);
	const taxAndContributions = useAppSelector(
		(state) => state.taxAndContributions
	);

	const dispatch = useAppDispatch();

	const { width } = useScreenSize();

	useEffect(() => {
		setIsLoading(true);
		getTaxesAndContributions(dispatch).catch((e) => console.error(e));
		getRentsRelations(dispatch, {
			start_date,
			end_date,
		})
			.catch((e) => console.error(e))
			.finally(() => setIsLoading(false));
	}, [start_date, end_date]);

	return (
		<ConfigProvider locale={locale}>
			<Card
				title={width < 575 ? 'Recaudación' : 'Detalle de recaudación'}
				extra={
					<DatePicker
						size={width < 575 ? 'small' : 'middle'}
						placeholder='Seleccionar mes'
						onChange={(date) =>
							date
								? setDate([
										date.startOf('M').toISOString(),
										date.endOf('M').toISOString(),
								  ])
								: null
						}
						picker='month'
						disabledDate={(date) => date.isAfter(dayjs(), 'month')}
					/>
				}
				className={styles.card}
			>
				{isLoading ? (
					<div
						style={{
							minHeight: '300px',
							display: 'flex',
							width: '100%',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<Spin indicator={antIcon} />
					</div>
				) : (
					<ConfigProvider
						renderEmpty={
							rentsRelations.success
								? () => (
										<Result
											icon={
												<Icon rev='1.0.0' component={() => <FcReading />} />
											}
											title='Todavía no hay registros en este mes.'
											subTitle='Intentá seleccionando otra fecha.'
										/>
								  )
								: () => (
										<Result
											icon={
												<Icon rev='1.0.0' component={() => <FcSynchronize />} />
											}
											title='Hubo un error, por favor intenta nuevamente.'
											subTitle='Prueba recargando la página o bien eligiendo otro mes.'
										/>
								  )
						}
					>
						<List
							className={styles.list}
							header={
								<Descriptions
									bordered
									layout='vertical'
									className={styles.header_descriptions}
									items={[
										{
											key: 'month',
											label: 'Período',
											labelStyle: {
												fontSize: '1.125em',
												fontWeight: '600',
												color: 'black',
												textTransform: 'uppercase',
												width: '100%',
											},
											className: styles.period,
											children: MONTHS[dayjs(start_date).month()],
											contentStyle: {
												fontSize: '1.125em',
												fontWeight: '400',
											},
										},
										{
											key: 'total_amount',
											label: 'Total recaudado',
											labelStyle: {
												fontSize: '1.125em',
												fontWeight: '600',
												color: 'black',
												textTransform: 'uppercase',
											},
											className: styles.amount,
											children: `$${
												rentsRelations.data?.total_amount.toLocaleString(
													'de-DE',
													{ maximumFractionDigits: 2 }
												) ?? '0'
											}`,
											contentStyle: {
												fontSize: '1.25em',
												fontWeight: '500',
											},
										},
									]}
								/>
							}
							dataSource={rentsRelations.data?.items}
							rowKey={'tax_and_contr_id'}
							renderItem={(item) => (
								<List.Item>
									{width < 575 ? (
										<Col style={{ width: '100%' }}>
											<Row
												gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}
												style={{ width: '100%', margin: 0 }}
											>
												<Col
													span={12}
													style={{
														backgroundColor: '#D8E6F2',
														borderTop: '12px solid #D8E6F2',
														borderBottom: '12px solid #D8E6F2',
														marginTop: '-12px',
														marginBottom: '-12px',
													}}
												>
													<Typography.Text>
														{taxAndContributions?.data?.find(
															(tac) => tac.id === item.tax_and_contr_id
														)?.name ?? ''}
													</Typography.Text>
												</Col>
												<Col
													span={12}
													style={{
														paddingLeft: '1em',
														backgroundColor: '#FAFAFA',
														borderTop: '12px solid #FAFAFA',
														borderBottom: '12px solid #FAFAFA',
														marginTop: '-12px',
														marginBottom: '-12px',
														borderRight: '1px solid rgba(5, 5, 5, 0.06)',
													}}
												>
													<Typography.Text strong>
														{`$${item.amount.toLocaleString('de-DE', {
															maximumFractionDigits: 2,
														})}`}
													</Typography.Text>
												</Col>
											</Row>
											<Row
												gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}
												style={{ width: '100%', paddingTop: '1.25em' }}
											>
												<Col span={24}>
													<CustomProgress
														amount={item.amount}
														totalAmount={rentsRelations.data?.total_amount ?? 0}
													/>
												</Col>
											</Row>
										</Col>
									) : (
										<Row
											gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}
											style={{ width: '100%' }}
										>
											<Col
												span={8}
												style={{
													backgroundColor: '#D8E6F2',
													borderTop: '12px solid #D8E6F2',
													borderBottom: '12px solid #D8E6F2',
													marginTop: '-12px',
													marginBottom: '-12px',
												}}
											>
												<Typography.Text>
													{taxAndContributions?.data?.find(
														(tac) => tac.id === item.tax_and_contr_id
													)?.name ?? ''}
												</Typography.Text>
											</Col>
											<Col
												span={4}
												style={{
													paddingLeft: '1em',
													backgroundColor: '#FAFAFA',
													borderTop: '12px solid #FAFAFA',
													borderBottom: '12px solid #FAFAFA',
													marginTop: '-12px',
													marginBottom: '-12px',
													borderRight: '1px solid rgba(5, 5, 5, 0.06)',
												}}
											>
												<Typography.Text strong>
													{`$${item.amount.toLocaleString('de-DE', {
														maximumFractionDigits: 2,
													})}`}
												</Typography.Text>
											</Col>
											<Col span={12}>
												<CustomProgress
													amount={item.amount}
													totalAmount={rentsRelations.data?.total_amount ?? 0}
												/>
											</Col>
										</Row>
									)}
								</List.Item>
							)}
						/>
					</ConfigProvider>
				)}
			</Card>
		</ConfigProvider>
	);
};

export default RentsGraph;
