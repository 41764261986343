import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../../components/button/Button';
import NewsCard from '../../../../components/NewsCard/NewsCard';
import useScreenSize from '../../../../hooks/useScreenSize';
import { NewsWithRelations, PublicRoutes } from '../../../../models';
import styles from './Carrousel.module.scss';

interface Props {
	news: NewsWithRelations[];
}

const Carrousel = ({ news }: Props) => {
	const navigate = useNavigate();

	const handleReadMore = (id: number) => {
		navigate(`/${PublicRoutes.NEWS}/${id}`);
	};

	const handleMoreNews = () => {
		navigate(`/${PublicRoutes.NEWS}`);
	};

	const [startMove, setStartMove] = useState(null);
	const [dotActive, setDotActive] = useState(0);

	const carousel = useRef<any | null>(null);

	const { width } = useScreenSize();

	const next = () => {
		if (carousel.current) {
			if (carousel.current.children.length > 0) {
				const firstSlide = carousel.current.children[0];

				carousel.current.style.transition = `300ms linear all`;
				const sizeSlide = carousel.current.children[0].offsetWidth;
				carousel.current.style.transform = `translateX(-${sizeSlide}px)`;

				const transition = () => {
					carousel.current.style.transition = `none`;
					carousel.current.style.transform = `translateX(0)`;

					carousel.current.appendChild(firstSlide);

					carousel.current.removeEventListener('transitionend', transition);
				};

				carousel.current.addEventListener('transitionend', transition);

				setDotActive(dotActive === 2 ? 0 : dotActive + 1);
			}
		}
	};

	const prev = () => {
		if (carousel.current) {
			if (carousel.current.children.length > 0) {
				const index = carousel.current.children.length - 1;
				const lastSlide = carousel.current.children[index];

				carousel.current.insertBefore(lastSlide, carousel.current.firstChild);

				carousel.current.style.transition = `none`;
				const sizeSlide = carousel.current.children[0].offsetWidth;
				carousel.current.style.transform = `translateX(-${sizeSlide}px)`;

				setTimeout(() => {
					carousel.current.style.transition = `300ms linear all`;
					carousel.current.style.transform = `translateX(0)`;
				}, 30);

				setDotActive(dotActive === 0 ? 2 : dotActive - 1);
			}
		}
	};

	const handleTouchStart = (e: any) => {
		const start = e.changedTouches[0].pageX;
		setStartMove(start);
	};

	const handleTouchEnd = (e: any) => {
		const end = e.changedTouches[0].pageX;

		startMove && startMove < end ? prev() : next();
	};

	return (
		<div className={styles.carousel}>
			<div ref={carousel} className={styles.slidesContainer}>
				{news?.map((notice, index) => (
					<div
						key={index}
						className={styles.slide}
						onTouchStart={handleTouchStart}
						onTouchEnd={handleTouchEnd}
					>
						<NewsCard
							data={notice}
							onClick={handleReadMore}
							onSecondaryAction={handleMoreNews}
						/>
					</div>
				))}
			</div>
			<div className={styles.arrows}>
				<Button
					icon='MdChevronLeft'
					size={width < 480 ? 'small' : 'medium'}
					variant='primary'
					onClick={prev}
				/>
				<div className={styles.dotContainer}>
					{news?.map((_, index) => (
						<div
							key={index}
							className={(() => {
								const classes = [styles.dot];
								dotActive === index && classes.push(styles.active);
								return classes.join(' ');
							})()}
						/>
					))}
				</div>
				<Button
					icon='MdChevronRight'
					size={width < 480 ? 'small' : 'medium'}
					variant='primary'
					onClick={next}
				/>
			</div>
		</div>
	);
};

export default Carrousel;
