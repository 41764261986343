import React, { useEffect, useMemo, useState } from 'react';
import styles from './_newsForm.module.scss';
import {
	Button,
	Card,
	Col,
	ConfigProvider,
	DatePicker,
	Form,
	FormInstance,
	Input,
	Row,
	Select,
	message,
} from 'antd';
import Icon from '@ant-design/icons/lib/components/Icon';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import locale from 'antd/locale/es_ES';
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks';
import { CustomError } from '../../../../../types/envelope.type';
import { LuArrowLeft } from 'react-icons/lu';
import { useNavigate } from 'react-router-dom';
import ImageUpload from '../../../../../components/image-upload/ImageUpload';
import { InsertNewsWithTopics } from '../../../../../models';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
	createNoticeWithTopicsController,
	getNewsCategories,
} from '../../../../../services/news.controller';
import { v4 as uuid } from 'uuid';
import { buckets } from '../../../../../models/dbStorage.model';

const { Option } = Select;

export interface NewsFormValues {
	hero_img_url: string;
	title: string;
	created_at: Date;
	author?: string;
	category_id: number;
	news_topics?: {
		subtitle?: string;
		content?: string;
		img_url?: string;
	}[];
}

const NewsForm: React.FC = () => {
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const [messageApi, contextHolder] = message.useMessage();
	const [form] = Form.useForm();
	const formRef = React.useRef<FormInstance>(null);

	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const user = useAppSelector((state) => state.user);
	const newsCategories = useAppSelector((state) => state.newsCategories);

	useEffect(() => {
		getNewsCategories(dispatch).catch((e) => console.error(e));
	}, []);

	const bucketId = useMemo(() => {
		return uuid();
	}, []);

	const successEvent = async () => {
		await messageApi.open({
			type: 'success',
			content: 'Creación exitosa!',
		});
		formRef.current?.resetFields();
	};

	const errorEvent = async (message: string) => {
		await messageApi.open({
			type: 'error',
			content: message,
		});
		formRef.current?.resetFields();
	};

	const onFinish = async (values: NewsFormValues) => {
		setIsLoading(true);
		const { created_at, news_topics, hero_img_url, ...notice } = values;

		const formData: InsertNewsWithTopics = {
			created_at: dayjs(created_at).toISOString(),
			user_id: user.id as string,
			hero_img_url: hero_img_url[0],
			bucket_reference: bucketId,
			news_topics:
				news_topics?.map((topic) => ({
					...topic,
					created_at: dayjs(created_at).toISOString(),
				})) ?? [],
			...notice,
		};

		const response = await createNoticeWithTopicsController(formData);

		if (response.success) {
			await successEvent();
			setIsLoading(false);
		} else {
			await errorEvent(
				(response?.error as CustomError)?.message ??
					'Hubo un error, por favor intenta nuevamente.'
			);
			setIsLoading(false);
		}
	};

	return (
		<div className={styles.page}>
			{contextHolder}
			<ConfigProvider locale={locale}>
				<Card
					title='Crear Noticia'
					extra={
						<Button
							type='primary'
							icon={<Icon rev='1.0.0' component={() => <LuArrowLeft />} />}
							onClick={() => navigate(-1)}
						>
							Volver
						</Button>
					}
				>
					<Row gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
						<Col span={24}>
							<Form
								form={form}
								ref={formRef}
								name='news'
								onFinish={onFinish}
								scrollToFirstError
							>
								<Col span={20}>
									<Form.Item
										name='hero_img_url'
										label='Imagen principal'
										labelCol={{ span: 4 }}
										labelAlign='left'
										getValueFromEvent={(value) => value}
										rules={[
											{
												required: true,
												message: 'Por favor seleccione una imagen.',
											},
										]}
									>
										<ImageUpload
											bucketId={bucketId}
											bucketName={buckets.NEWS}
											maxCount={1}
											maxLength={1}
										/>
									</Form.Item>
								</Col>
								<Col span={20}>
									<Form.Item
										name='title'
										label='Título'
										labelCol={{ span: 3 }}
										labelAlign='left'
										wrapperCol={{ offset: 1 }}
										rules={[
											{
												required: true,
												message: 'Por favor ingrese un título.',
											},
										]}
									>
										<Input />
									</Form.Item>
								</Col>
								<Col span={20}>
									<Form.Item
										name='created_at'
										label='Fecha'
										labelCol={{ span: 3 }}
										labelAlign='left'
										wrapperCol={{ offset: 1 }}
										rules={[
											{
												required: true,
												message: 'Por favor seleccione una fecha.',
											},
										]}
									>
										<DatePicker format={'DD/MM/YYYY'} />
									</Form.Item>
								</Col>
								<Col span={20}>
									<Form.Item
										name='category_id'
										label='Categoría'
										labelCol={{ span: 3 }}
										labelAlign='left'
										wrapperCol={{ span: 6, offset: 1 }}
										rules={[
											{
												required: true,
												message: 'Por favor seleccione una categoría.',
											},
										]}
									>
										<Select placeholder='Seleccionar categoría' allowClear>
											{newsCategories?.data?.map((item, index) => (
												<Option value={item.id} key={index}>
													{item.category}
												</Option>
											))}
										</Select>
									</Form.Item>
								</Col>
								<Col span={20}>
									<Form.Item
										name='author'
										label='Autor'
										labelCol={{ span: 3 }}
										labelAlign='left'
										wrapperCol={{ offset: 1 }}
									>
										<Input />
									</Form.Item>
								</Col>

								<Col span={24}>
									<Form.List name='news_topics'>
										{(fields, { add, remove }) => (
											<>
												{fields.map(({ key, name, ...restField }) => (
													<Row
														gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}
														key={key}
														style={{ marginBottom: 4 }}
													>
														<Col
															span={20}
															style={{
																paddingLeft: 0,
																paddingRight: 0,
																borderRight: '2px solid lightgray',
															}}
														>
															<Col span={24}>
																<Form.Item
																	{...restField}
																	name={[name, 'subtitle']}
																	label='Subtítulo'
																	labelCol={{ span: 3 }}
																	labelAlign='left'
																	wrapperCol={{ offset: 1 }}
																>
																	<Input />
																</Form.Item>
															</Col>
															<Col span={24}>
																<Form.Item
																	{...restField}
																	name={[name, 'content']}
																	label='Contenido'
																	labelCol={{ span: 3 }}
																	labelAlign='left'
																	wrapperCol={{ offset: 1 }}
																>
																	<Input.TextArea rows={5} />
																</Form.Item>
															</Col>
															<Col span={24}>
																<Form.Item
																	{...restField}
																	name={[name, 'img_url']}
																	label='Imagen/es'
																	labelCol={{ span: 3 }}
																	labelAlign='left'
																	getValueFromEvent={(value) => value}
																	wrapperCol={{ offset: 1 }}
																>
																	<ImageUpload
																		bucketId={bucketId}
																		bucketName={buckets.NEWS}
																		maxCount={1}
																	/>
																</Form.Item>
															</Col>
														</Col>
														<Col
															span={2}
															style={{
																display: 'flex',
																alignItems: 'center',
																justifyContent: 'center',
															}}
														>
															<MinusCircleOutlined
																rev='1.0.0'
																onClick={() => remove(name)}
															/>
														</Col>
													</Row>
												))}
												<Form.Item>
													<Button
														type='dashed'
														onClick={() => add()}
														icon={<PlusOutlined rev='1.0.0' />}
													>
														Agregar Tema
													</Button>
												</Form.Item>
											</>
										)}
									</Form.List>
								</Col>

								<Form.Item>
									<Button type='primary' htmlType='submit' loading={isLoading}>
										Crear
									</Button>
								</Form.Item>
							</Form>
						</Col>
					</Row>
				</Card>
			</ConfigProvider>
		</div>
	);
};

export default NewsForm;
