import { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';
import { PrivateRoutes, PublicRoutes } from '../../models';
import { RoutesWithNotFound } from '../../utilities';
import EditPage from '../AdminDashboard/pages/EditPage/EditPage';
import RentsForm from '../AdminDashboard/pages/Rents/RentsForm/RentsForm';
import Register from '../Register/Register';
import UpdatePassword from '../UpdatePassword/UpdatePassword';

const AdminDashboard = lazy(
	async () => await import('../AdminDashboard/AdminDashboard')
);

const Private = () => {
	return (
		<RoutesWithNotFound>
			<Route
				path='/'
				element={<Navigate to={PrivateRoutes.ADMIN_DASHBOARD} />}
			/>
			<Route
				path={PrivateRoutes.ADMIN_DASHBOARD}
				element={<AdminDashboard />}
			/>
			<Route path={PublicRoutes.CREATE_USER} element={<Register create />} />
			<Route path={PublicRoutes.UPDATE_PASSWORD} element={<UpdatePassword />} />
			<Route path={PrivateRoutes.EDIT}>
				<Route path={PublicRoutes.NEWS} element={<EditPage news />} />
				<Route path={PublicRoutes.TOURISM} element={<EditPage tourism />} />
				<Route path={PublicRoutes.RENTS} element={<RentsForm />} />
				<Route path={`${PublicRoutes.RENTS}/:id`} element={<RentsForm />} />
			</Route>
		</RoutesWithNotFound>
	);
};
export default Private;
