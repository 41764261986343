import { createSlice } from '@reduxjs/toolkit';
import { NewsWithRelations } from '../../models/news.model';
import { Envelope } from '../../types/envelope.type';

export const EmptyNewsWithRelationsState: Envelope<NewsWithRelations[]> = {
	success: false,
	data: [],
	error: null,
	pagination: {
		page: 1,
		items_per_page: 5,
	},
};

export const newsSlice = createSlice({
	name: 'news',
	initialState: EmptyNewsWithRelationsState,
	reducers: {
		setNews: (_state, action) => {
			return action.payload;
		},
	},
});

export const { setNews } = newsSlice.actions;

export default newsSlice.reducer;
