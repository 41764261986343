import React, { useEffect, useState } from 'react';
import {
	Button,
	Col,
	ConfigProvider,
	DatePicker,
	Divider,
	Form,
	InputNumber,
	Modal,
	Row,
	Select,
} from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { RentWithAllRelations } from '../../../../../models/rents.model';
import { ReqBodyForUpdateRent } from '../../../../../types/rents.type';
import { updateRent } from '../../../../../services/rents.controller';
import { CustomError } from '../../../../../types/envelope.type';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import locale from 'antd/locale/es_ES';
import { useAppSelector } from '../../../../../app/hooks';

export interface UpdateRentFormValues {
	date: string;
	rents_tax_and_contr: {
		tax_and_contr_id: number;
		amount: number;
	}[];
}
export interface RentsUpdateFormProps {
	data?: RentWithAllRelations;
	isOpen: boolean;
	onSuccess: (message: string) => void;
	onError: (message: string) => void;
	onClose: () => void;
	refetch: () => void;
}

const { Option } = Select;

const RentsUpdateForm: React.FC<RentsUpdateFormProps> = ({
	data,
	isOpen,
	onSuccess,
	onError,
	onClose,
	refetch,
}) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const [form] = Form.useForm();

	const taxAndContributions = useAppSelector(
		(state) => state.taxAndContributions
	);

	useEffect(() => {
		if (data) {
			form.setFieldsValue({
				date: dayjs(data.created_at),
				rents_tax_and_contr: data.rents_tax_and_contr?.map((rel) => ({
					tax_and_contr_id: rel.tax_and_contr_id,
					amount: rel.amount,
				})),
			});
		}
	}, [data]);

	const onFinish = async (values: UpdateRentFormValues) => {
		setIsLoading(true);

		const { users, ...rentData } = data as RentWithAllRelations;

		const formData: ReqBodyForUpdateRent = {
			...rentData,
			created_at: dayjs(values.date).toISOString(),
			rents_tax_and_contr: values.rents_tax_and_contr.map((rtc) => ({
				created_at: dayjs(values.date).toISOString(),
				...rtc,
			})),
		};

		const response = await updateRent(data?.id as number, formData);

		if (response.success) {
			onSuccess('Actualización exitosa!');
			setIsLoading(false);
			refetch();
			onClose();
		} else {
			onError(
				(response?.error as CustomError)?.message ??
					'Hubo un error, por favor intenta nuevamente.'
			);
			setIsLoading(false);
		}
	};

	return (
		<>
			<Modal
				title='Actualizar Registro'
				open={isOpen}
				onOk={form.submit}
				onCancel={onClose}
				cancelText='Cancelar'
				okText='Actualizar'
				width={1024}
				confirmLoading={isLoading}
			>
				<Divider />

				<ConfigProvider locale={locale}>
					<Row gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
						<Col span={24}>
							<Form
								form={form}
								name='update rent'
								onFinish={onFinish}
								scrollToFirstError
							>
								<Col span={14}>
									<Form.Item
										name='date'
										label='Fecha'
										wrapperCol={{ offset: 1 }}
										rules={[
											{
												required: true,
												message: 'Campo requerido.',
											},
										]}
									>
										<DatePicker format={'DD/MM/YYYY'} />
									</Form.Item>
								</Col>
								<Col span={20}>
									<Form.List name='rents_tax_and_contr'>
										{(fields, { add, remove }) => (
											<>
												{fields.map(({ key, name, ...restField }) => (
													<Row
														key={key}
														gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}
													>
														<Col span={14}>
															<Form.Item
																{...restField}
																name={[name, 'tax_and_contr_id']}
																label='Concepto'
																rules={[
																	{
																		required: true,
																		message: 'Campo requerido.',
																	},
																]}
															>
																<Select
																	placeholder='Seleccionar Tasa o Contribución'
																	allowClear
																>
																	{taxAndContributions?.data?.map(
																		(item, index) => (
																			<Option value={item.id} key={index}>
																				{item.name}
																			</Option>
																		)
																	)}
																</Select>
															</Form.Item>
														</Col>
														<Col span={9}>
															<Form.Item
																{...restField}
																name={[name, 'amount']}
																label='Importe'
																rules={[
																	{
																		required: true,
																		message: 'Campo requerido.',
																	},
																]}
															>
																<InputNumber
																	addonBefore='$'
																	placeholder='0'
																	min={0}
																/>
															</Form.Item>
														</Col>
														<Col span={1} style={{ paddingTop: '6px' }}>
															<MinusCircleOutlined
																rev='1.0.0'
																onClick={() => remove(name)}
															/>
														</Col>
													</Row>
												))}
												<Form.Item>
													<Button
														type='dashed'
														onClick={() => add()}
														icon={<PlusOutlined rev='1.0.0' />}
													>
														Agregar concepto
													</Button>
												</Form.Item>
											</>
										)}
									</Form.List>
								</Col>
							</Form>
						</Col>
					</Row>
				</ConfigProvider>
			</Modal>
		</>
	);
};

export default RentsUpdateForm;
