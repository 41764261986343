import React, { useState } from 'react';
import styles from './_rentsForm.module.scss';
import {
	Button,
	Card,
	Col,
	ConfigProvider,
	DatePicker,
	Form,
	FormInstance,
	InputNumber,
	Row,
	Select,
	message,
} from 'antd';
import Icon from '@ant-design/icons/lib/components/Icon';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import locale from 'antd/locale/es_ES';
import { ReqBodyForCreateRent } from '../../../../../types/rents.type';
import { useAppSelector } from '../../../../../app/hooks';
import { createRent } from '../../../../../services/rents.controller';
import { CustomError } from '../../../../../types/envelope.type';
import { LuArrowLeft } from 'react-icons/lu';
import { useNavigate } from 'react-router-dom';

const { Option } = Select;

export interface RentsFormValues {
	date: Date;
	rents_tax_and_contr: {
		tax_and_contr_id: number;
		amount: number;
	}[];
}

const RentsForm: React.FC = () => {
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const [messageApi, contextHolder] = message.useMessage();
	const [form] = Form.useForm();
	const formRef = React.useRef<FormInstance>(null);

	const navigate = useNavigate();

	const user = useAppSelector((state) => state.user);
	const taxAndContributions = useAppSelector(
		(state) => state.taxAndContributions
	);

	const successEvent = async () => {
		await messageApi.open({
			type: 'success',
			content: 'Registro exitoso!',
		});
		formRef.current?.resetFields();
	};

	const errorEvent = async (message: string) => {
		await messageApi.open({
			type: 'error',
			content: message,
		});
		formRef.current?.resetFields();
	};

	const onFinish = async (values: RentsFormValues) => {
		setIsLoading(true);
		const { date, rents_tax_and_contr } = values;

		const formData: ReqBodyForCreateRent = {
			created_at: dayjs(date).toISOString(),
			user_id: user.id as string,
			rents_tax_and_contr: rents_tax_and_contr.map((rel) => ({
				...rel,
				created_at: dayjs(date).toISOString(),
			})),
		};

		const response = await createRent(formData);

		if (response.success) {
			await successEvent();
			setIsLoading(false);
		} else {
			await errorEvent(
				(response?.error as CustomError)?.message ??
					'Hubo un error, por favor intenta nuevamente.'
			);
			setIsLoading(false);
		}
	};

	return (
		<div className={styles.page}>
			{contextHolder}
			<ConfigProvider locale={locale}>
				<Card
					title='Registro de Rentas'
					extra={
						<Button
							type='primary'
							icon={<Icon rev='1.0.0' component={() => <LuArrowLeft />} />}
							onClick={() => navigate(-1)}
						>
							Volver
						</Button>
					}
				>
					<Row gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
						<Col span={24}>
							<Form
								form={form}
								ref={formRef}
								name='rents'
								onFinish={onFinish}
								scrollToFirstError
							>
								<Col span={8}>
									<Form.Item
										name='date'
										label='Fecha'
										rules={[
											{
												required: true,
												message: 'Campo requerido.',
											},
										]}
									>
										<DatePicker format={'DD/MM/YYYY'} />
									</Form.Item>
								</Col>

								<Col span={16}>
									<Form.List name='rents_tax_and_contr'>
										{(fields, { add, remove }) => (
											<>
												{fields.map(({ key, name, ...restField }) => (
													<Row
														key={key}
														gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}
													>
														<Col span={13}>
															<Form.Item
																{...restField}
																name={[name, 'tax_and_contr_id']}
																label='Concepto'
																rules={[
																	{
																		required: true,
																		message: 'Campo requerido.',
																	},
																]}
															>
																<Select
																	placeholder='Seleccionar Tasa o Contribución'
																	allowClear
																>
																	{taxAndContributions?.data?.map(
																		(item, index) => (
																			<Option value={item.id} key={index}>
																				{item.name}
																			</Option>
																		)
																	)}
																</Select>
															</Form.Item>
														</Col>
														<Col span={10}>
															<Form.Item
																{...restField}
																name={[name, 'amount']}
																label='Importe'
																rules={[
																	{
																		required: true,
																		message: 'Campo requerido.',
																	},
																]}
															>
																<InputNumber
																	addonBefore='$'
																	placeholder='0'
																	min={0}
																/>
															</Form.Item>
														</Col>
														<Col span={1} style={{ paddingTop: '6px' }}>
															<MinusCircleOutlined
																rev='1.0.0'
																onClick={() => remove(name)}
															/>
														</Col>
													</Row>
												))}
												<Form.Item>
													<Button
														type='dashed'
														onClick={() => add()}
														icon={<PlusOutlined rev='1.0.0' />}
													>
														Agregar concepto
													</Button>
												</Form.Item>
											</>
										)}
									</Form.List>
								</Col>

								<Form.Item>
									<Button type='primary' htmlType='submit' loading={isLoading}>
										Crear Registro
									</Button>
								</Form.Item>
							</Form>
						</Col>
					</Row>
				</Card>
			</ConfigProvider>
		</div>
	);
};

export default RentsForm;
