import { useNavigate } from 'react-router-dom';
import { PrivateRoutes, PublicRoutes, User } from '../../../../models';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { useEffect, useState } from 'react';
import { getUsersController } from '../../../../services/users.controller';
import { Pagination } from '../../../../types/envelope.type';
import { Button, Card, ConfigProvider, Result } from 'antd';
import Icon from '@ant-design/icons/lib/components/Icon';
import { LuUserPlus } from 'react-icons/lu';
import { FcReading } from 'react-icons/fc';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { Table, TableProps } from 'antd/lib';

const UserTable = () => {
	const [pagination, setPagination] = useState<Pagination>({
		page: 1,
		items_per_page: 5,
	});
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const users = useAppSelector((state) => state.users);

	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		setIsLoading(true);
		getUsersController(dispatch, {
			page: pagination.page,
			items_per_page: pagination.items_per_page,
		})
			.catch((e) => console.error(e))
			.finally(() => setIsLoading(false));
	}, [pagination.page, pagination.items_per_page]);

	useEffect(() => {
		if (users?.pagination)
			setPagination((prev) => ({
				...prev,
				total_items: users.pagination?.total_items,
				total_pages: users.pagination?.total_pages,
			}));
	}, [users.pagination]);

	const columns: ColumnsType<User> = [
		{
			title: 'ID',
			dataIndex: 'id',
		},
		{
			title: 'NOMBRE Y APELLIDO',
			dataIndex: 'first_name',
			render: (_, record) => `${record.first_name} ${record.last_name}`,
		},
		{
			title: 'EMAIL',
			dataIndex: 'email',
		},
		{
			title: 'FECHA DE REGISTRO',
			dataIndex: 'created_at',
			render: (created_at) =>
				dayjs(created_at).format('DD/MM/YYYY HH:mm:ss') ?? 'No definido',
		},
		{
			title: 'CUIL/CUIT',
			dataIndex: 'cuil',
		},
		// {
		// 	title: 'ACCIONES',
		// 	align: 'center',
		// render: (_, record) => (
		// 		<Popconfirm
		// 			title='Eliminar registro de Renta'
		// 			description='¿Estás seguro de eliminar?'
		// 			onConfirm={() => handleDeleteRent(record.id)}
		// 			okText='Si'
		// 			cancelText='No'
		// 		>
		// 			<Tooltip title='Eliminar'>
		// 				<Button
		// 					danger
		// 					loading={
		// 						deleteLoading.loading && deleteLoading.id === record.id
		// 					}
		// 				>
		// 					<Icon rev='1.0.0' component={() => <LuTrash2 />} />
		// 				</Button>
		// 			</Tooltip>
		// 		</Popconfirm>
		// ),
		// },
	];

	const onChange: TableProps<User>['onChange'] = (
		pagination,
		_filters,
		_sorter,
		_extra
	) => {
		setPagination({
			page: pagination.current ?? 1,
			items_per_page: pagination.pageSize ?? 5,
		});
	};

	return (
		<>
			<Card
				title='Listado de Usuarios'
				style={{ margin: '1.5em 0' }}
				extra={
					<Button
						type='primary'
						icon={<Icon rev='1.0.0' component={() => <LuUserPlus />} />}
						onClick={() =>
							navigate(`/${PrivateRoutes.PRIVATE}/${PublicRoutes.CREATE_USER}`)
						}
					>
						Alta de Usuario
					</Button>
				}
			>
				<ConfigProvider
					renderEmpty={() => (
						<Result
							icon={<Icon rev='1.0.0' component={() => <FcReading />} />}
							title='No se han encontrado resultados'
							subTitle='Intentá cambiar los filtros o consultalo con tu desarrollador de confianza'
						/>
					)}
				>
					<Table
						rowKey={'id'}
						columns={columns}
						dataSource={users.data ?? []}
						onChange={onChange}
						loading={isLoading}
						pagination={{
							pageSize: pagination.items_per_page,
							pageSizeOptions: ['5', '10', '25'],
							showSizeChanger: true,
							total: pagination?.total_items ?? 0,
							current: pagination.page,
							showTotal: (total, range) => {
								return `${range[0]} - ${range[1]} de ${total}`;
							},
						}}
					/>
				</ConfigProvider>
			</Card>
		</>
	);
};
export default UserTable;
