import { forwardRef } from 'react';
import { UseFormRegister } from 'react-hook-form';
import { RegisterInputs } from '../../pages/Register/Register';
import styles from './_formSelect.css';

const FormSelect = forwardRef<
	HTMLSelectElement,
	{ label: string; options: string[] } & ReturnType<
		UseFormRegister<RegisterInputs>
	>
>(({ onChange, onBlur, name, label, options }, ref) => (
	<div className={styles.select}>
		<label>{label}</label>
		<select name={name} ref={ref} onChange={onChange} onBlur={onBlur}>
			{options.map((item, index) => (
				<option key={index} value={item}>
					{item}
				</option>
			))}
		</select>
	</div>
));
export default FormSelect;
