import { createSlice } from '@reduxjs/toolkit';
import { UserInfo } from '../../models';
import {
	clearLocalStorage,
	persistLocalStorage,
} from '../../utilities/LocalStorage.utility';

export const EmptyUserState: UserInfo = {
	id: null,
	name: '',
	lastName: '',
	email: '',
	cuil: '',
	position: '',
};

export const userSlice = createSlice({
	name: 'user',
	initialState: localStorage.getItem('user')
		? (JSON.parse(localStorage.getItem('user') as string) as UserInfo)
		: EmptyUserState,
	reducers: {
		createUser: (state, action) => {
			persistLocalStorage('user', action.payload);
			return action.payload;
		},
		updateUser: (state, action) => {
			const result = { ...state, ...action.payload };
			persistLocalStorage('user', result);
			return result;
		},
		resetUser: () => {
			clearLocalStorage('user');
			return EmptyUserState;
		},
	},
});

export const { createUser, updateUser, resetUser } = userSlice.actions;

export default userSlice.reducer;
