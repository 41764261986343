import { useMemo } from 'react';
import * as ri from 'react-icons/ri';
import * as md from 'react-icons/md';
import styles from './Icon.module.scss';
import { IconName, IconSize } from '../../types/icons.type';

export interface IconProps {
	icon: IconName;
	icon_size?: IconSize;
	color?: string;
	style?: React.CSSProperties;
}

export function Icon(props: IconProps) {
	const color = useMemo(() => props.color ?? '#000', [props.color]);

	const icon = useMemo(() => {
		const classes = [styles.icon];

		switch (props.icon_size) {
			case 'small': {
				classes.push(styles.icon__size__small);
				break;
			}
			case 'large': {
				classes.push(styles.icon__size__large);
				break;
			}
			case 'xlarge': {
				classes.push(styles.icon__size__xlarge);
				break;
			}
			default: {
				classes.push(styles.icon__size__medium);
				break;
			}
		}

		return classes.join(' ');
	}, [props.icon_size]);

	const Icon = useMemo(() => {
		if (props.icon in ri) {
			return ri[props.icon];
		} else if (props.icon in md) {
			return md[props.icon];
		}

		return null;
	}, [props.icon]);

	return <Icon className={icon} color={color} style={props.style} />;
}
