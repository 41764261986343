export const PublicRoutes = {
	AUTHORITIES: 'authorities',
	CREATE_USER: 'create-user',
	UPDATE_PASSWORD: 'update-password',
	COMMERCIAL_AUTH: 'commercial-authorization',
	DIRECTIONS: 'directions',
	DRIVERS_LICENSES: 'drivers-licenses',
	GOBERMENT: 'goberment',
	LODGING: 'lodging',
	LOGIN: 'login',
	NEWS: 'news',
	REGISTER: 'register',
	RENTS: 'rents',
	RESIDUE_RECOLECTION: 'residue-recolection',
	TOURISM: 'tourism',
};

export const PrivateRoutes = {
	PRIVATE: 'private',
	ADMIN_DASHBOARD: 'admin-dashboard',
	EDIT: 'edit',
};
