import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../app/store';
import { AnyAction } from 'redux';
import { Envelope } from '../types/envelope.type';
import {
	InsertNewsWithTopics,
	NewsCategories,
	NewsWithRelations,
} from '../models';
import * as newsServices from './news.service';
import { setNews } from '../app/states/news';
import { setNotice } from '../app/states/notice';
import { setNewsCategories } from '../app/states/newsCategories';

export const getNewsWithRelations = async (
	dispatch: ThunkDispatch<RootState, undefined, AnyAction>,
	queries?: {
		order?: 'asc' | 'desc';
		page?: number;
		items_per_page?: number;
		sort_by?: 'id' | 'created_at' | 'update_at';
	}
) => {
	const order = queries?.order ?? 'desc';
	const page = queries?.page ? Number(queries.page) : 1;
	const items_per_page = queries?.items_per_page
		? Number(queries.items_per_page)
		: 10;
	const sort_by =
		(queries?.sort_by as 'id' | 'created_at' | 'update_at') ?? 'created_at';

	const response: Envelope<NewsWithRelations[]> = {
		success: true,
		data: null,
		error: null,
		pagination: null,
	};

	try {
		const {
			count,
			error: countError,
			status: countStatus,
		}: newsServices.GetAllNewsCountResponse = await newsServices.getAllNewsCountService();

		if (countError) {
			response.success = false;
			response.error = {
				status: countStatus,
				message: 'Hubo un error, por favor intenta nuevamente.',
				other_info: countError,
			};
		}

		const { data, error, status }: newsServices.GetAllNewsResponse =
			await newsServices.getAllNewsService(
				order,
				page,
				items_per_page,
				sort_by
			);

		if (error) {
			response.success = false;
			response.error = {
				status,
				message: 'Hubo un error, por favor intenta nuevamente.',
				other_info: error,
			};
		}

		if (count && data) {
			response.data = data;
			response.pagination = {
				page,
				items_per_page,
				total_pages: Math.ceil(count / items_per_page),
				total_items: count,
			};

			return dispatch(setNews(response));
		}

		return dispatch(setNews(response));
	} catch (error) {
		console.error(error);
		response.success = false;
		response.error = {
			status: 500,
			message: 'Hubo un error de servidor, por favor intenta nuevamente.',
			other_info: error,
		};
		return dispatch(setNews(response));
	}
};

export const getNoticeByIdController = async (
	dispatch: ThunkDispatch<RootState, undefined, AnyAction>,
	id: number
) => {
	const response: Envelope<NewsWithRelations> = {
		success: true,
		data: null,
		error: null,
		pagination: null,
	};

	try {
		const { data, error, status }: newsServices.GetNoticeByIdServiceResponse =
			await newsServices.getNoticeByIdService(id);

		if (error) {
			response.success = false;
			response.error = {
				status,
				message: 'Hubo un error, por favor intenta nuevamente.',
				other_info: error,
			};
		}

		if (data) {
			response.data = data[0];

			dispatch(setNotice(response));
		}

		dispatch(setNotice(response));
	} catch (error) {
		console.error(error);
		response.success = false;
		response.error = {
			status: 500,
			message: 'Hubo un error de servidor, por favor intenta nuevamente.',
			other_info: error,
		};
		return dispatch(setNotice(response));
	}
};

export const getNewsCategories = async (
	dispatch: ThunkDispatch<RootState, undefined, AnyAction>
) => {
	const response: Envelope<NewsCategories[]> = {
		success: true,
		data: null,
		error: null,
		pagination: null,
	};

	try {
		const { data, error, status }: newsServices.GetNewsCategoriesService =
			await newsServices.getNewsCategoriesService();

		if (error) {
			response.success = false;
			response.error = {
				status,
				message: 'Hubo un error, por favor intenta nuevamente.',
				other_info: error,
			};
		}

		if (data) {
			response.data = data;

			return dispatch(setNewsCategories(response));
		}

		return dispatch(setNewsCategories(response));
	} catch (error) {
		console.error(error);
		response.success = false;
		response.error = {
			status: 500,
			message: 'Hubo un error de servidor, por favor intenta nuevamente.',
			other_info: error,
		};
		return dispatch(setNewsCategories(response));
	}
};

export const createNoticeWithTopicsController = async (
	noticeData: InsertNewsWithTopics
) => {
	const { news_topics, ...notice } = noticeData;

	const response: Envelope<{ status: number; statusText: string }> = {
		success: true,
		data: null,
		error: null,
		pagination: null,
	};

	try {
		const {
			data: lastNewsIdData,
			error: lastNewsIdError,
			status: lastNewsIdStatus,
		}: newsServices.GetLastNewsIdService = await newsServices.getLastNewsIdService();

		if (lastNewsIdError) {
			response.success = false;
			response.error = {
				status: lastNewsIdStatus,
				message: 'Hubo un error, por favor intenta nuevamente.',
				other_info: lastNewsIdError,
			};
			return response;
		}

		const noticeWithId = { ...notice, id: lastNewsIdData[0].id + 1 };

		const {
			data: noticeData,
			error: noticeError,
			status: noticeStatus,
		}: newsServices.CreateNoticeServiceResponse = await newsServices.createNoticeService(
			noticeWithId
		);

		if (noticeError) {
			response.success = false;
			response.error = {
				status: noticeStatus,
				message: 'Hubo un error, por favor intenta nuevamente.',
				other_info: noticeError,
			};
			return response;
		}

		const {
			data: lastIdData,
			error: lastIdError,
			status: lastIdStatus,
		}: newsServices.GetLastNewsTopicsIdServiceResponse = await newsServices.getLastNewsTopicsIdService();

		if (lastIdError) {
			response.success = false;
			response.error = {
				status: lastIdStatus,
				message: 'Hubo un error, por favor intenta nuevamente.',
				other_info: lastIdError,
			};
			return response;
		}

		const formattedTopics = news_topics.map((topic, index) => ({
			...topic,
			id: lastIdData[0].id + (index + 1),
			id_notice: noticeData[0].id,
		}));

		const {
			status,
			statusText,
			error,
		}: newsServices.CreateNoticeTopicsServiceResponse = await newsServices.createNoticeTopicsService(
			formattedTopics
		);

		if (error) {
			response.success = false;
			response.error = {
				status,
				message: 'Hubo un error, por favor intenta nuevamente.',
				other_info: error,
			};
			return response;
		}

		response.data = { status, statusText };

		return response;
	} catch (error) {
		console.error(error);
		response.success = false;
		response.error = {
			status: 500,
			message: 'Hubo un error de servidor, por favor intenta nuevamente.',
			other_info: error,
		};
		return response;
	}
};

export const deleteNoticeWithTopics = async (id: number) => {
	const response: Envelope<{ status: number; statusText: string }> = {
		success: true,
		data: null,
		error: null,
		pagination: null,
	};

	try {
		const {
			error,
			status,
			statusText,
		}: newsServices.DeleteNewsWithTopicsServiceResponse = await newsServices.deleteNewsWithTopicsService(
			+id
		);

		if (error) {
			response.success = false;
			response.error = {
				status,
				message: 'Hubo un error, por favor intenta nuevamente.',
				other_info: error,
			};

			return response;
		}

		response.data = { status, statusText };

		return response;
	} catch (error) {
		console.error(error);
		response.success = false;
		response.error = {
			status: 500,
			message: 'Hubo un error de servidor, por favor intenta nuevamente.',
			other_info: error,
		};
		return response;
	}
};
