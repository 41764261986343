import styles from './ResiduesRecolection.module.scss';
import recolectionHeader from '../../assets/img/headers_recoleccion.png';
import useScreenSize from '../../hooks/useScreenSize';

const earlyShiftNeighborhoods = [
	{
		name: 'Centro',
		days: [1, 2, 3, 4, 5],
	},
	{
		name: 'Virgen de los Desamparados',
		days: [1, 2, 3, 4, 5],
	},
	{
		name: 'Residencial',
		days: [1, 2, 3, 4, 5],
	},
	{
		name: 'Sagrado Corazón',
		days: [1, 2, 3, 4, 5],
	},
	{
		name: 'Usina',
		days: [1, 2, 3, 4, 5],
	},
	{
		name: 'Tres Esquinas',
		days: [1, 2, 3, 4, 5],
	},
	{
		name: 'Virgen del Rosario',
		days: [1, 2, 3, 4, 5],
	},
	{
		name: 'Jorge Newbery',
		days: [1, 2, 3, 4, 5],
	},
	{
		name: 'San Nicolás',
		days: [1, 3, 5],
	},
	{
		name: 'Los Baldes',
		days: [1, 3, 5],
	},
	{
		name: 'Nueva Ciudad',
		days: [1, 3, 5],
	},
	{
		name: 'Procrear',
		days: [1, 3, 5],
	},
	{
		name: 'Victoria Romero',
		days: [1, 3, 5],
	},
	{
		name: 'Daniel Elias',
		days: [1, 3, 5],
	},
	{
		name: 'Polco',
		days: [1, 5],
	},
	{
		name: 'Los Filtros',
		days: [1, 5],
	},
	{
		name: '40 Viviendas',
		days: [2, 4],
	},
	{
		name: '20 Viviendas',
		days: [2, 4],
	},
	{
		name: 'Matadero',
		days: [2, 4],
	},
	{
		name: 'Sormani',
		days: [2, 4],
	},
	{
		name: 'Industrial',
		days: [2, 4],
	},
	{
		name: 'Aeronáutico',
		days: [2, 4],
	},
];

const lateShiftNeighborhoods = [
	{
		name: 'Centro',
		days: [1, 2, 3, 4, 5],
	},
	{
		name: 'Argentino',
		days: [1, 2, 3, 4, 5],
	},
	{
		name: '20 de Junio',
		days: [1, 2, 3, 4, 5],
	},
	{
		name: 'Los Paraísos',
		days: [1, 2, 3, 4, 5],
	},
	{
		name: 'Ángel V. Peñaloza',
		days: [1, 2, 3, 4, 5],
	},
	{
		name: 'Obrero',
		days: [1, 3, 5],
	},
	{
		name: 'Inmaculada',
		days: [1, 3, 5],
	},
	{
		name: '60 Viviendas',
		days: [1, 3, 5],
	},
	{
		name: 'Anexo 60 Viviendas',
		days: [1, 3, 5],
	},
	{
		name: 'América',
		days: [1, 3, 5],
	},
	{
		name: 'Universidad',
		days: [1, 3, 5],
	},
	{
		name: 'Tiro Federal',
		days: [1, 3, 5],
	},
	{
		name: 'Hospital',
		days: [1, 3, 5],
	},
	{
		name: 'Costa Esperanza',
		days: [1, 3, 5],
	},
];

const ResiduesRecolection = () => {
	const { width } = useScreenSize();

	return (
		<section className={styles.recolection}>
			<img src={recolectionHeader} alt='' />
			<h3>Circuito del camión de recolección</h3>
			<div className={styles['table-box']}>
				<table className={styles.table}>
					<thead>
						<th colSpan={6} className={styles.shiftTurn}>
							Turno Mañana
							<br />
							de 6:00hs a 10:00hs
						</th>
						<tr>
							<th className={styles.neightbor}>Barrios</th>
							<th>{width < 480 ? 'Lun' : 'Lunes'}</th>
							<th>{width < 480 ? 'Mar' : 'Martes'}</th>
							<th>{width < 480 ? 'Mie' : 'Miércoles'}</th>
							<th>{width < 480 ? 'Jue' : 'Jueves'}</th>
							<th>{width < 480 ? 'Vie' : 'Viernes'}</th>
						</tr>
					</thead>
					<tbody>
						{earlyShiftNeighborhoods.map((neightborhood, index) => (
							<tr key={index}>
								<td>{neightborhood.name}</td>
								<td
									className={
										neightborhood.days.includes(1) ? styles.tdColor1 : ''
									}
								></td>
								<td
									className={
										neightborhood.days.includes(2) ? styles.tdColor1 : ''
									}
								></td>
								<td
									className={
										neightborhood.days.includes(3) ? styles.tdColor1 : ''
									}
								></td>
								<td
									className={
										neightborhood.days.includes(4) ? styles.tdColor1 : ''
									}
								></td>
								<td
									className={
										neightborhood.days.includes(5) ? styles.tdColor1 : ''
									}
								></td>
							</tr>
						))}
					</tbody>
					<tfoot>
						<tr>
							<th colSpan={6}>Celdas en color = días de recolección</th>
						</tr>
					</tfoot>
				</table>
			</div>
			<br />
			<div className={styles['table-box']}>
				<table className={styles.table}>
					<thead>
						<th colSpan={6} className={styles.shiftTurn}>
							Turno Tarde
							<br />
							de 19:00hs a 23:00hs
						</th>
						<tr>
							<th className={styles.neightbor}>Barrios</th>
							<th>{width < 480 ? 'Lun' : 'Lunes'}</th>
							<th>{width < 480 ? 'Mar' : 'Martes'}</th>
							<th>{width < 480 ? 'Mie' : 'Miércoles'}</th>
							<th>{width < 480 ? 'Jue' : 'Jueves'}</th>
							<th>{width < 480 ? 'Vie' : 'Viernes'}</th>
						</tr>
					</thead>
					<tbody>
						{lateShiftNeighborhoods.map((neightborhood, index) => (
							<tr key={index}>
								<td>{neightborhood.name}</td>
								<td
									className={
										neightborhood.days.includes(1) ? styles.tdColor1 : ''
									}
								></td>
								<td
									className={
										neightborhood.days.includes(2) ? styles.tdColor1 : ''
									}
								></td>
								<td
									className={
										neightborhood.days.includes(3) ? styles.tdColor1 : ''
									}
								></td>
								<td
									className={
										neightborhood.days.includes(4) ? styles.tdColor1 : ''
									}
								></td>
								<td
									className={
										neightborhood.days.includes(5) ? styles.tdColor1 : ''
									}
								></td>
							</tr>
						))}
					</tbody>
					<tfoot>
						<tr>
							<th colSpan={6}>Celdas en color = días de recolección</th>
						</tr>
					</tfoot>
				</table>
			</div>
		</section>
	);
};
export default ResiduesRecolection;
