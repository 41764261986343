import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import FormInput from '../../components/FormInput/FormInput';
import styles from './_register.css';
import { useForm, SubmitHandler } from 'react-hook-form';
import FormSelect from '../../components/FormSelect/FormSelect';
import emailjs from '@emailjs/browser';
import { PublicRoutes } from '../../models';
import { POSITIONS } from '../../constants';
import { supabase } from '../../database/supabase';
import { Button } from '../../components/button/Button';
import { message } from 'antd';

export interface RegisterInputs {
	firstName: string;
	lastName: string;
	cuil: string;
	position: string;
	email: string;
	password: string;
}

interface RegisterProps {
	create?: boolean;
}

const Register = ({ create }: RegisterProps) => {
	const [messageApi, contextHolder] = message.useMessage();

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<RegisterInputs>();

	const serviceId = process.env.EMAILJS_SERVICE_ID
		? process.env.EMAILJS_SERVICE_ID
		: '';
	const templateId = process.env.EMAILJS_TEMPLATE_ID
		? process.env.EMAILJS_TEMPLATE_ID
		: '';
	const publicKey = process.env.EMAILJS_PUBLIC_KEY;

	const form = useRef<HTMLFormElement>(null);

	const successEvent = async () => {
		await messageApi.open({
			type: 'success',
			content:
				'¡Solicitud enviada! En unos momentos te llegará un mail con los datos necesarios para ingresar.',
			duration: 5,
		});
	};

	const errorEvent = async () => {
		await messageApi.open({
			type: 'error',
			content: 'Algo salió mal. Por favor, intente nuevamente.',
			duration: 5,
		});
	};

	const onSubmit: SubmitHandler<RegisterInputs> = async (data) => {
		if (create) {
			try {
				const result = await supabase.auth.signUp({
					email: data.email,
					password: data.password,
					options: {
						data: {
							firstName: data.firstName,
							lastName: data.lastName,
							cuil: data.cuil,
							position: data.position,
						},
					},
				});

				console.log({ result });
			} catch (error) {
				console.error(error);
			}
		} else {
			try {
				const result = await emailjs.sendForm(
					serviceId,
					templateId,
					form.current as HTMLFormElement,
					publicKey
				);

				if (result.status === 200) {
					await successEvent();
					navigate(`/`);
				} else {
					await errorEvent();
				}
			} catch (e) {
				console.error(e);
			}
		}
	};

	const navigate = useNavigate();

	const handleGoToLogin = () => {
		navigate(`/${PublicRoutes.LOGIN}`, { replace: true });
	};

	return (
		<div className={styles.register}>
			{contextHolder}
			<div className={styles.container}>
				<h3>Registro</h3>
				<h5>Completá con tus datos para crear la cuenta</h5>
				<form ref={form} onSubmit={handleSubmit(onSubmit)}>
					<FormInput
						label='Nombre'
						type='text'
						name='firstName'
						register={register}
						rules={{ required: true }}
						error={errors.firstName}
						errorMessage='Este campo es requerido'
					/>
					<FormInput
						label='Apellido'
						type='text'
						name='lastName'
						register={register}
						rules={{ required: true }}
						error={errors.lastName}
						errorMessage='Este campo es requerido'
					/>
					<FormInput
						label='CUIL'
						type='text'
						name='cuil'
						register={register}
						rules={{ required: true }}
						error={errors.cuil}
						errorMessage='Este campo es requerido'
					/>
					<FormSelect
						label='Cargo'
						options={POSITIONS}
						{...register('position', { required: true })}
					/>
					<FormInput
						label='Email'
						type='text'
						name='email'
						autoComplete='new-password'
						register={register}
						rules={{ required: true }}
						error={errors.email}
						errorMessage='Este campo es requerido'
					/>
					{create && (
						<FormInput
							label='Contraseña'
							type='password'
							name='password'
							autoComplete='new-password'
							register={register}
							rules={{ required: true }}
							error={errors.password}
							errorMessage='Este campo es requerido'
						/>
					)}
					<div className={styles.btnContainer}>
						<Button
							label='Ya tengo una cuenta'
							onClick={handleGoToLogin}
							variant='primary_ghost'
						/>
						<Button label='Solicitar alta' type='submit' />
					</div>
				</form>
			</div>
		</div>
	);
};

export default Register;
