export const getCategory = (id: number) => {
	switch (id) {
		case 1:
			return 'POLITICA';
		case 2:
			return 'DEPORTE';
		case 3:
			return 'ECONOMIA';
		case 4:
			return 'POLICIAL';
		case 5:
			return 'CIENCIA Y TECNOLOGIA';
		case 6:
			return 'ENTRETENIMIENTO';
		case 7:
			return 'SALUD';
		case 8:
			return 'ARTE Y CULTURA';
		case 9:
			return 'EMPLEO';
		case 10:
			return 'INTERNACIONAL';
		case 11:
			return 'SOCIAL';
		default:
			return '';
	}
};

export const reemplazarAcentos = (text: string) => {
	const regex = /[áéíóúüÁÉÍÓÚÜñÑ]/g;
	return text.replace(regex, (match) =>
		match
			.replace(/á/g, 'a')
			.replace(/Á/g, 'A')
			.replace(/é/g, 'e')
			.replace(/É/g, 'E')
			.replace(/í/g, 'i')
			.replace(/Í/g, 'I')
			.replace(/ó/g, 'o')
			.replace(/Ó/g, 'O')
			.replace(/ú/g, 'u')
			.replace(/Ú/g, 'U')
			.replace(/ü/g, 'u')
			.replace(/Ü/g, 'U')
			.replace(/ñ/g, 'n')
			.replace(/Ñ/g, 'N')
	);
};
