import { supabase } from '../database/supabase';
import {
	InsertNotice,
	InsertNoticeTopics,
	News,
	UpdateNotice,
	UpdateNoticeTopics,
} from '../models/news.model';

export const getAllNewsCountService = async () => {
	return await supabase.from('news').select('*', { count: 'exact' });
};

export const getAllNewsService = async (
	order: 'asc' | 'desc',
	page: number,
	items_per_page: number,
	sort_by: 'id' | 'created_at' | 'update_at'
) => {
	return await supabase
		.from('news')
		.select(`*, news_topics(*), users(*), news_categories(category)`)
		.order(sort_by, { ascending: order === 'asc' })
		.range((page - 1) * items_per_page, page * items_per_page - 1);
};

export const getNoticeByIdService = async (id: number) => {
	return await supabase
		.from('news')
		.select(`*, news_topics(*), users(*), news_categories(category)`)
		.eq('id', id);
};

export const getNewsCategoriesService = async () => {
	return await supabase.from('news_categories').select();
};

export const getLastNewsIdService = async () => {
	return await supabase
		.from('news')
		.select('id')
		.order('id', { ascending: false })
		.limit(1);
};

export const createNoticeService = async (notice: InsertNotice) => {
	return await supabase.from('news').insert(notice).select();
};

export const getLastNewsTopicsIdService = async () => {
	return await supabase
		.from('news_topics')
		.select('id')
		.order('id', { ascending: false })
		.limit(1);
};

export const createNoticeTopicsService = async (data: InsertNoticeTopics[]) => {
	return await supabase.from('news_topics').insert(data);
};

export const updateNewsService = async ({
	notice_id,
	notice_data,
}: {
	notice_id: News['id'];
	notice_data: UpdateNotice;
}) => {
	return await supabase
		.from('news')
		.update(notice_data)
		.eq('id', notice_id)
		.select(`*, news_topics(*)`);
};

export const upsertNewsTopicsService = async (data: UpdateNoticeTopics[]) => {
	return await supabase.from('news_topics').upsert(data).select();
};

export const deleteNewsTopicsService = async (ids: number[]) => {
	return await supabase.from('news_topics').delete().in('id', ids);
};

export const deleteNewsWithTopicsService = async (notice_id: News['id']) => {
	return await supabase.from('news').delete().eq('id', notice_id);
};

/* --- Tipados de las funciones de Supabase --- */

// Conteo de todas las noticias
export type GetAllNewsCountResponse = Awaited<
	ReturnType<typeof getAllNewsCountService>
>;

// Petición de noticias
export type GetAllNewsResponse = Awaited<ReturnType<typeof getAllNewsService>>;

// Petición de una noticia por Id
export type GetNoticeByIdServiceResponse = Awaited<
	ReturnType<typeof getNoticeByIdService>
>;

// Petición de todas las categorías de noticias
export type GetNewsCategoriesService = Awaited<
	ReturnType<typeof getNewsCategoriesService>
>;

// Obtener el último ID de una noticia
export type GetLastNewsIdService = Awaited<
	ReturnType<typeof getLastNewsIdService>
>;

// Crear una noticia
export type CreateNoticeServiceResponse = Awaited<
	ReturnType<typeof createNoticeService>
>;

// Obtener el último ID de un tema de noticia
export type GetLastNewsTopicsIdServiceResponse = Awaited<
	ReturnType<typeof getLastNewsTopicsIdService>
>;

// Crear temas de una noticia
export type CreateNoticeTopicsServiceResponse = Awaited<
	ReturnType<typeof createNoticeTopicsService>
>;

// Actualizar una noticia
export type UpdateNewsServiceResponse = Awaited<
	ReturnType<typeof updateNewsService>
>;

// Crear temas de una noticia
export type UpsertNewsTopicsServiceResponse = Awaited<
	ReturnType<typeof upsertNewsTopicsService>
>;

// Eliminar los temas de una noticia
export type DeleteNewsTopicsServiceResponse = Awaited<
	ReturnType<typeof deleteNewsTopicsService>
>;

// Eliminar una noticia con sus temas
export type DeleteNewsWithTopicsServiceResponse = Awaited<
	ReturnType<typeof deleteNewsWithTopicsService>
>;
