// import { Request, Response } from 'express';
import { Envelope } from '../types/envelope.type';
import * as rentsServices from './rents.service';
import {
	RentWithAllRelations,
	RentWithTaxesAndContributions,
	RentsTaxAndContr,
	RentsTaxAndContrState,
	TaxesAndContributions,
} from '../models/rents.model';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../app/store';
import { AnyAction } from 'redux';
import { setRents } from '../app/states/rents';
import {
	InsertRentRelations,
	ReqBodyForCreateRent,
	ReqBodyForUpdateRent,
} from '../types/rents.type';
import dayjs from 'dayjs';
import { Database } from '../types/database.types';
import { setRentsRelations } from '../app/states/rentsRelations';
import { setTaxAndContributions } from '../app/states/taxAndContributions';

export const getRentsWithTaxesAndContributions = async (
	dispatch: ThunkDispatch<RootState, undefined, AnyAction>,
	queries?: {
		order?: 'asc' | 'desc';
		page?: number;
		items_per_page?: number;
		sort_by?: 'id' | 'created_at' | 'updated_at';
	}
) => {
	const order = queries?.order ?? 'desc';
	const page = queries?.page ? Number(queries.page) : 1;
	const items_per_page = queries?.items_per_page
		? Number(queries.items_per_page)
		: 10;
	const sort_by =
		(queries?.sort_by as 'id' | 'created_at' | 'updated_at') ?? 'created_at';

	const response: Envelope<RentWithAllRelations[]> = {
		success: true,
		data: null,
		error: null,
		pagination: null,
	};

	try {
		const {
			count,
			error: countError,
			status: countStatus,
		}: rentsServices.GetAllRentsCountServiceResponse = await rentsServices.getAllRentsCountService();

		if (countError) {
			response.success = false;
			response.error = {
				status: countStatus,
				message: 'Hubo un error, por favor intenta nuevamente.',
				other_info: countError,
			};
		}

		const {
			data,
			error,
			status,
		}: rentsServices.GetRentsWithTaxesAndContributionsResponse = await rentsServices.getRentsWithTaxesAndContributionsService(
			order,
			page,
			items_per_page,
			sort_by
		);

		if (error) {
			response.success = false;
			response.error = {
				status,
				message: 'Hubo un error, por favor intenta nuevamente.',
				other_info: error,
			};
		}

		if (count && data) {
			response.data = data;
			response.pagination = {
				page,
				items_per_page,
				total_pages: Math.ceil(count / items_per_page),
				total_items: count,
			};

			return dispatch(setRents(response));
		}

		return dispatch(setRents(response));
	} catch (error) {
		console.error(error);
		response.success = false;
		response.error = {
			status: 500,
			message: 'Hubo un error de servidor, por favor intenta nuevamente.',
			other_info: error,
		};
		return dispatch(setRents(response));
	}
};

export const getTaxesAndContributions = async (
	dispatch: ThunkDispatch<RootState, undefined, AnyAction>
) => {
	const response: Envelope<TaxesAndContributions[]> = {
		success: true,
		data: null,
		error: null,
		pagination: null,
	};

	try {
		const {
			data,
			error,
			status,
		}: rentsServices.GetTaxesAndContributionsResponse = await rentsServices.getTaxesAndContributionsService();

		if (error) {
			response.success = false;
			response.error = {
				status,
				message: 'Hubo un error, por favor intenta nuevamente.',
				other_info: error,
			};
		}

		response.data = data as TaxesAndContributions[];

		return dispatch(setTaxAndContributions(response));
	} catch (error) {
		console.error(error);
		response.success = false;
		response.error = {
			status: 500,
			message: 'Hubo un error de servidor, por favor intenta nuevamente.',
			other_info: error,
		};
		return dispatch(setTaxAndContributions(response));
	}
};

export const createRent = async (rentData: ReqBodyForCreateRent) => {
	const response: Envelope<{ status: number; statusText: string }> = {
		success: true,
		data: null,
		error: null,
		pagination: null,
	};

	try {
		const {
			data: rent,
			error: rentError,
			status: rentStatus,
		}: rentsServices.CreateRentResponse = await rentsServices.createRentService(
			rentData.user_id,
			rentData.created_at
		);

		if (rentError) {
			response.success = false;
			response.error = {
				status: rentStatus,
				message: 'Hubo un error, por favor intenta nuevamente.',
				other_info: rentError,
			};
			return response;
		}

		const {
			data: lastIdData,
			error: lastIdError,
			status: lastIdStatus,
		}: rentsServices.GetLastRentRelationServiceResponse = await rentsServices.getLastRentRelationService();

		if (lastIdError) {
			response.success = false;
			response.error = {
				status: lastIdStatus,
				message: 'Hubo un error, por favor intenta nuevamente.',
				other_info: lastIdError,
			};

			return response;
		}

		const formatedRelations: InsertRentRelations[] =
			rentData.rents_tax_and_contr.map((rel, index) => ({
				...rel,
				id: lastIdData[0].id + (index + 1),
				rents_id: rent[0].id,
			}));

		const {
			status,
			statusText,
			error,
		}: rentsServices.CreateRentsRelationsServiceResponse = await rentsServices.createRentsRelationsService(
			formatedRelations
		);

		if (error) {
			response.success = false;
			response.error = {
				status,
				message: 'Hubo un error, por favor intenta nuevamente.',
				other_info: error,
			};
			return response;
		}

		response.data = { status, statusText };

		return response;
	} catch (error) {
		console.error(error);
		response.success = false;
		response.error = {
			status: 500,
			message: 'Hubo un error de servidor, por favor intenta nuevamente.',
			other_info: error,
		};
		return response;
	}
};

export const updateRent = async (
	id: number,
	rentData: ReqBodyForUpdateRent
) => {
	const updated_at = dayjs().toISOString();
	let newId = 1;

	const formattedRentData = { ...rentData, updated_at };
	const relations = rentData.rents_tax_and_contr;

	const response: Envelope<RentWithTaxesAndContributions> = {
		success: true,
		data: null,
		error: null,
		pagination: null,
	};

	try {
		const {
			data: rent,
			error: rentError,
			status: rentStatus,
		}: rentsServices.UpdateRentServiceResponse = await rentsServices.updateRentService(
			{
				rent_id: +id,
				rent_data: formattedRentData,
			}
		);

		if (rentError) {
			response.success = false;
			response.error = {
				status: rentStatus,
				message: 'Hubo un error, por favor intenta nuevamente.',
				other_info: rentError,
			};

			return response;
		}

		let idRelToDelete: number[] | null = null;
		let lastId: number | null = null;

		if (relations.length < rent[0]?.rents_tax_and_contr.length) {
			const idsToKeep = relations.map((rel) => rel.tax_and_contr_id);

			idRelToDelete = (
				rent[0] as RentWithTaxesAndContributions
			)?.rents_tax_and_contr
				.filter((rtc) => !idsToKeep.includes(rtc?.tax_and_contr_id))
				.map((rtc) => rtc.id);
		} else {
			const {
				data: lastIdData,
				error: lastIdError,
				status: lastIdStatus,
			}: rentsServices.GetLastRentRelationServiceResponse = await rentsServices.getLastRentRelationService();

			if (lastIdError) {
				response.success = false;
				response.error = {
					status: lastIdStatus,
					message: 'Hubo un error, por favor intenta nuevamente.',
					other_info: lastIdError,
				};

				return response;
			}

			lastId = lastIdData[0].id;
		}

		if (idRelToDelete) {
			const { error: deleteError, status: deleteStatus } =
				await rentsServices.deleteRentRelationService(idRelToDelete);

			if (deleteError) {
				response.success = false;
				response.error = {
					status: deleteStatus,
					message: 'Hubo un error, por favor intenta nuevamente.',
					other_info: deleteError,
				};

				return response;
			}
		}

		const relationsWithId = relations.map((rel) => {
			let relId = 0;

			const previousId = rent[0].rents_tax_and_contr.find(
				(rtc: Database['public']['Tables']['rents_tax_and_contr']['Row']) =>
					rtc.tax_and_contr_id === rel.tax_and_contr_id
			)?.id;

			if (previousId) {
				relId = previousId;
			} else if (lastId) {
				relId = +lastId + newId;
				newId++;
			}

			return {
				...rel,
				id: relId,
				updated_at,
				rents_id: rent[0].id,
			};
		});

		const {
			data,
			error,
			status,
		}: rentsServices.UpsertRentRelationsServiceResponse = await rentsServices.upsertRentRelationsService(
			relationsWithId
		);

		if (error) {
			response.success = false;
			response.error = {
				status,
				message: 'Hubo un error, por favor intenta nuevamente.',
				other_info: error,
			};

			return response;
		}

		response.data = { ...rent[0], rents_tax_and_contr: data };

		return response;
	} catch (error) {
		console.error(error);
		response.success = false;
		response.error = {
			status: 500,
			message: 'Hubo un error de servidor, por favor intenta nuevamente.',
			other_info: error,
		};
		return response;
	}
};

export const deleteRent = async (id: number) => {
	const response: Envelope<{ status: number; statusText: string }> = {
		success: true,
		data: null,
		error: null,
		pagination: null,
	};

	try {
		const {
			error,
			status,
			statusText,
		}: rentsServices.DeleteRentWithRelationsServiceResponse = await rentsServices.deleteRentWithRelationsService(
			+id
		);

		if (error) {
			response.success = false;
			response.error = {
				status,
				message: 'Hubo un error, por favor intenta nuevamente.',
				other_info: error,
			};

			return response;
		}

		response.data = { status, statusText };

		return response;
	} catch (error) {
		console.error(error);
		response.success = false;
		response.error = {
			status: 500,
			message: 'Hubo un error de servidor, por favor intenta nuevamente.',
			other_info: error,
		};
		return response;
	}
};

export const getRentsRelations = async (
	dispatch: ThunkDispatch<RootState, undefined, AnyAction>,
	queries?: {
		start_date: string;
		end_date: string;
	}
) => {
	const start_date =
		(queries?.start_date as string) ?? dayjs().startOf('month').toISOString();
	const end_date =
		(queries?.end_date as string) ?? dayjs().endOf('month').toISOString();

	const response: Envelope<RentsTaxAndContrState> = {
		success: true,
		data: null,
		error: null,
		pagination: null,
	};

	try {
		const {
			data,
			error,
			status,
		}: rentsServices.GetRentsRelationsServiceResponse = await rentsServices.getRentsRelationsService(
			{
				start_date,
				end_date,
			}
		);

		if (error) {
			response.success = false;
			response.error = {
				status,
				message: 'Hubo un error, por favor intenta nuevamente.',
				other_info: error,
			};
		}

		if (data) {
			const groupedByTaxAndContrId = (data as RentsTaxAndContr[]).reduce(
				(result: RentsTaxAndContrState, rtc): RentsTaxAndContrState => {
					const resultFound = result.items.find(
						(res) => res.tax_and_contr_id === rtc.tax_and_contr_id
					);
					result.total_amount += rtc.amount as number;

					if (!resultFound) {
						result.items.push({
							tax_and_contr_id: rtc.tax_and_contr_id as number,
							amount: rtc.amount as number,
						});
					} else {
						const newAmount = (rtc.amount as number) + resultFound.amount;
						result.items.forEach((res) => {
							if (res.tax_and_contr_id === rtc.tax_and_contr_id) {
								res.amount = newAmount;
							}
						});
					}
					return result;
				},
				{ total_amount: 0, items: [] }
			);

			response.data = groupedByTaxAndContrId;

			return dispatch(setRentsRelations(response));
		}

		return dispatch(setRentsRelations(response));
	} catch (error) {
		console.error(error);
		response.success = false;
		response.error = {
			status: 500,
			message: 'Hubo un error de servidor, por favor intenta nuevamente.',
			other_info: error,
		};
		return dispatch(setRentsRelations(response));
	}
};
