// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CommercialAuth__page__o4K0A {
	max-width: 1024px;
	margin: 0 auto;
	padding: 0 1em;
}

.CommercialAuth__page__o4K0A h3 {
	color: dodgerblue;
	margin: 0.5em 0;
	margin-top: 1.25em;
}

.CommercialAuth__page__o4K0A summary {
	margin-bottom: 0.5em;
}

.CommercialAuth__detail-box__U0vGa {
	background-color: aliceblue;
	cursor: pointer;
	color: dodgerblue;
	margin-top: 0.25rem;
	padding: 1rem;
	border-top: 2px solid white;
	border-bottom: 1px solid gray;
	border-radius: 0.5rem;
	box-shadow: 5px 5px 15px 5px #acacac;
}

.CommercialAuth__detail-box__U0vGa ul,
.CommercialAuth__detail-box__U0vGa ol,
.CommercialAuth__ul__NyzlC {
	margin-inline-start: 1.2em;
}

.CommercialAuth__detail-box__U0vGa li {
	color: black;
}

@media (min-width: 1024px) {
	.CommercialAuth__page__o4K0A {
		padding: 0;
	}
}
`, "",{"version":3,"sources":["webpack://./src/pages/CommercialAuth/CommercialAuth.css"],"names":[],"mappings":"AAAA;CACC,iBAAiB;CACjB,cAAc;CACd,cAAc;AACf;;AAEA;CACC,iBAAiB;CACjB,eAAe;CACf,kBAAkB;AACnB;;AAEA;CACC,oBAAoB;AACrB;;AAEA;CACC,2BAA2B;CAC3B,eAAe;CACf,iBAAiB;CACjB,mBAAmB;CACnB,aAAa;CACb,2BAA2B;CAC3B,6BAA6B;CAC7B,qBAAqB;CACrB,oCAAoC;AACrC;;AAEA;;;CAGC,0BAA0B;AAC3B;;AAEA;CACC,YAAY;AACb;;AAEA;CACC;EACC,UAAU;CACX;AACD","sourcesContent":[".page {\r\n\tmax-width: 1024px;\r\n\tmargin: 0 auto;\r\n\tpadding: 0 1em;\r\n}\r\n\r\n.page h3 {\r\n\tcolor: dodgerblue;\r\n\tmargin: 0.5em 0;\r\n\tmargin-top: 1.25em;\r\n}\r\n\r\n.page summary {\r\n\tmargin-bottom: 0.5em;\r\n}\r\n\r\n.detail-box {\r\n\tbackground-color: aliceblue;\r\n\tcursor: pointer;\r\n\tcolor: dodgerblue;\r\n\tmargin-top: 0.25rem;\r\n\tpadding: 1rem;\r\n\tborder-top: 2px solid white;\r\n\tborder-bottom: 1px solid gray;\r\n\tborder-radius: 0.5rem;\r\n\tbox-shadow: 5px 5px 15px 5px #acacac;\r\n}\r\n\r\n.detail-box ul,\r\n.detail-box ol,\r\n.ul {\r\n\tmargin-inline-start: 1.2em;\r\n}\r\n\r\n.detail-box li {\r\n\tcolor: black;\r\n}\r\n\r\n@media (min-width: 1024px) {\r\n\t.page {\r\n\t\tpadding: 0;\r\n\t}\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": `CommercialAuth__page__o4K0A`,
	"detail-box": `CommercialAuth__detail-box__U0vGa`,
	"ul": `CommercialAuth__ul__NyzlC`
};
export default ___CSS_LOADER_EXPORT___;
