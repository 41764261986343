import { FC, InputHTMLAttributes } from 'react';
import { FieldError, RegisterOptions, UseFormRegister } from 'react-hook-form';
import { RegisterInputs } from '../../pages/Register/Register';
import styles from './_formInput.css';

interface FormInputProps extends InputHTMLAttributes<HTMLInputElement> {
	name: 'firstName' | 'lastName' | 'cuil' | 'position' | 'email' | 'password';
	label: string;
	register: UseFormRegister<RegisterInputs>;
	rules?: RegisterOptions;
	error?: FieldError;
	errorMessage?: string;
}

const FormInput: FC<FormInputProps> = ({
	name,
	label,
	register,
	rules,
	error,
	errorMessage,
	...rest
}) => {
	return (
		<div className={styles.input}>
			<label htmlFor={name}>{label}</label>
			<input {...register(name, rules)} {...rest} />
			{error !== undefined && <small>{errorMessage}</small>}
		</div>
	);
};

export default FormInput;
