import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		xmlSpace='preserve'
		style={{
			enableBackground: 'new 0 0 512 512',
		}}
		viewBox='0 0 512 512'
		{...props}
	>
		<path d='M416 48c-44.188 0-80 35.813-80 80 0 11.938 2.625 23.281 7.312 33.438L416 304l72.688-142.563C493.375 151.281 496 139.938 496 128c0-44.187-35.812-80-80-80zm0 128c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm23.938 151.469 29.125 58.219-73.844 36.906-24.75-123.812 4.156-4.156.438-.438-15.25-30L352 272l-96-64-96 64-64-64L0 400l128 64 128-64 128 64 128-64-54-107.969-18.062 35.438zM116.75 422.594l-73.813-36.906L104.75 262l32.625 32.625 4.156 4.156-24.781 123.813zM240 372.219l-89.5 44.75 23.125-115.594 4.125-2.75 62.25-41.5v115.094zm32 0V257.125l62.25 41.5 4.094 2.75 23.125 115.594L272 372.219z' />
	</svg>
);
export default SvgComponent;
