import { createSlice } from '@reduxjs/toolkit';
import { TaxesAndContributions } from '../../models/rents.model';
import { Envelope } from '../../types/envelope.type';

export const EmptyTaxAndContributionsState: Envelope<TaxesAndContributions[]> =
	{
		success: false,
		data: [],
		error: null,
		pagination: null,
	};

export const taxAndContributionsSlice = createSlice({
	name: 'taxAndContributions',
	initialState: EmptyTaxAndContributionsState,
	reducers: {
		setTaxAndContributions: (_state, action) => {
			return action.payload;
		},
	},
});

export const { setTaxAndContributions } = taxAndContributionsSlice.actions;

export default taxAndContributionsSlice.reducer;
