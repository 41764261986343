import { supabase } from '../database/supabase';

export const getSecretariesInfo = async () => {
	try {
		const { data, error } = await supabase
			.from('secretarial_information')
			.select();

		if (error) console.error(error);

		if (data) return data;
	} catch (error) {
		console.error(error);
	}
};
