import { createSlice } from '@reduxjs/toolkit';
import { NewsCategories } from '../../models/news.model';
import { Envelope } from '../../types/envelope.type';

export const EmptyNewsCategoriesState: Envelope<NewsCategories[]> = {
	success: false,
	data: [],
	error: null,
	pagination: null,
};

export const newsCategoriesSlice = createSlice({
	name: 'newsCategories',
	initialState: EmptyNewsCategoriesState,
	reducers: {
		setNewsCategories: (_state, action) => {
			return action.payload;
		},
	},
});

export const { setNewsCategories } = newsCategoriesSlice.actions;

export default newsCategoriesSlice.reducer;
