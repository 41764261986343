import { createSlice } from '@reduxjs/toolkit';
import { TourismWithRelations } from '../../models/news.model';
import { Envelope } from '../../types/envelope.type';

export const EmptyTourismWithRelationsState: Envelope<TourismWithRelations[]> =
	{
		success: false,
		data: [],
		error: null,
		pagination: {
			page: 1,
			items_per_page: 5,
		},
	};

export const tourismSlice = createSlice({
	name: 'tourism',
	initialState: EmptyTourismWithRelationsState,
	reducers: {
		setTourism: (_state, action) => {
			return action.payload;
		},
	},
});

export const { setTourism } = tourismSlice.actions;

export default tourismSlice.reducer;
