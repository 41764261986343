import PinkShield from '../../assets/svg/PinkShield';
import FacebookIcon from '../../assets/svg/FacebookIcon';
import styles from './_footer.css';

const Footer = () => {
	return (
		<footer>
			<div className={styles.footer}>
				<div className={styles.footerLogo}>
					<PinkShield />
					<p>Municipalidad de Chamical</p>
				</div>
				<div className={styles.footerText}>
					<div className={styles.socialMedia}>
						<span>Seguinos</span>
						<div className={styles.icons}>
							<a
								href='https://www.facebook.com/DptoChamical/'
								rel='noreferrer'
								target='_blank'
							>
								<FacebookIcon />
							</a>
						</div>
					</div>
					<p>Juan Domingo Perón 50</p>
					<p>Chamical, La Rioja</p>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
