import Title from '../../components/Titlte/Title';
import Carrousel from './components/Carrousel/Carrousel';
import Hero from './components/Hero/Hero';
import TourismCard from '../../components/TourismCard/TourismCard';
import styles from './_home.css';
import { useEffect, useState } from 'react';
import UsefulPhones from './components/UsefulPhones/UsefulPhones';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useNavigate } from 'react-router-dom';
import { PublicRoutes } from '../../models';
import Loader from '../../components/loader/Loader';
import FrequentServices from './components/FrequentServices/FrequentServices';
import { getNewsWithRelations } from '../../services/news.controller';
import { getTourismWithRelations } from '../../services/tourism.controller';

const Home = () => {
	const [isLoading, setIsLoading] = useState(false);

	const news = useAppSelector((state) => state.news?.data);
	const tourisms = useAppSelector((state) => state.tourism?.data);

	const dispatch = useAppDispatch();

	const navigate = useNavigate();

	useEffect(() => {
		setIsLoading(true);
		getNewsWithRelations(dispatch, {
			page: 1,
			items_per_page: 3,
			sort_by: 'created_at',
			order: 'desc',
		}).catch((e) => console.error(e));
		getTourismWithRelations(dispatch, {
			page: 1,
			items_per_page: 3,
			sort_by: 'created_at',
			order: 'desc',
		})
			.catch((e) => console.error(e))
			.finally(() => setIsLoading(false));
	}, []);

	const handleGoToTourimsNotice = (id: number) => {
		navigate(`/${PublicRoutes.TOURISM}/${id}`);
	};

	return (
		<>
			{isLoading && <Loader />}
			<Hero />
			<div className={styles.body}>
				<div>
					<Title text={'Servicios Frecuentes'} />
					<FrequentServices />
				</div>

				<div style={{ width: 'calc(100% + 2em)' }}>
					<Title text={'Actualidad'} />
					<Carrousel news={news ?? []} />
				</div>

				<div>
					<Title text={'Turismo'} />
					{tourisms?.length && tourisms?.length >= 3 && (
						<div className={styles.tourism}>
							<TourismCard
								title={tourisms[0]?.title ?? ''}
								description={
									tourisms[0]?.tourism_topics[0]?.content?.trim() ?? ''
								}
								image={tourisms[0]?.hero_img_url ?? ''}
								onClick={() => handleGoToTourimsNotice(tourisms[0].id)}
							/>
							<TourismCard
								title={tourisms[1]?.title ?? ''}
								description={
									tourisms[1]?.tourism_topics[0]?.content?.trim() ?? ''
								}
								image={tourisms[1]?.hero_img_url ?? ''}
								onClick={() => handleGoToTourimsNotice(tourisms[1].id)}
							/>
							<TourismCard
								title={tourisms[2]?.title ?? ''}
								description={
									tourisms[2]?.tourism_topics[0]?.content?.trim() ?? ''
								}
								image={tourisms[2]?.hero_img_url ?? ''}
								onClick={() => handleGoToTourimsNotice(tourisms[2].id)}
							/>
						</div>
					)}
				</div>
				<UsefulPhones />
			</div>
		</>
	);
};

export default Home;
