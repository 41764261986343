// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._editPage__container__TcyOg {
	align-self: center;
	max-width: 1024px;
	width: 80%;
}
`, "",{"version":3,"sources":["webpack://./src/pages/AdminDashboard/pages/EditPage/_editPage.css"],"names":[],"mappings":"AAAA;CACC,kBAAkB;CAClB,iBAAiB;CACjB,UAAU;AACX","sourcesContent":[".container {\r\n\talign-self: center;\r\n\tmax-width: 1024px;\r\n\twidth: 80%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `_editPage__container__TcyOg`
};
export default ___CSS_LOADER_EXPORT___;
