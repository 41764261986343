// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._home__body__ZCnNB {
	width: 100%;
	max-width: 1024px;
	padding: 0px 1em;
	display: flex;
	flex-direction: column;
	gap: 2em;
	align-items: center;
	margin: 0 auto;
}

._home__tourism__vcoJ5 {
	width: 100%;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
	column-gap: 1.5em;
	row-gap: 1em;
	margin-top: 25px;
	margin-bottom: 10px;
}

@media (min-width: 480px) {
	._home__body__ZCnNB {
		padding: 0 2em;
	}
}

@media (min-width: 768px) {
	._home__body__ZCnNB {
		padding: 0 1em;
	}
}
`, "",{"version":3,"sources":["webpack://./src/pages/Home/_home.css"],"names":[],"mappings":"AAAA;CACC,WAAW;CACX,iBAAiB;CACjB,gBAAgB;CAChB,aAAa;CACb,sBAAsB;CACtB,QAAQ;CACR,mBAAmB;CACnB,cAAc;AACf;;AAEA;CACC,WAAW;CACX,aAAa;CACb,2DAA2D;CAC3D,iBAAiB;CACjB,YAAY;CACZ,gBAAgB;CAChB,mBAAmB;AACpB;;AAEA;CACC;EACC,cAAc;CACf;AACD;;AAEA;CACC;EACC,cAAc;CACf;AACD","sourcesContent":[".body {\r\n\twidth: 100%;\r\n\tmax-width: 1024px;\r\n\tpadding: 0px 1em;\r\n\tdisplay: flex;\r\n\tflex-direction: column;\r\n\tgap: 2em;\r\n\talign-items: center;\r\n\tmargin: 0 auto;\r\n}\r\n\r\n.tourism {\r\n\twidth: 100%;\r\n\tdisplay: grid;\r\n\tgrid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));\r\n\tcolumn-gap: 1.5em;\r\n\trow-gap: 1em;\r\n\tmargin-top: 25px;\r\n\tmargin-bottom: 10px;\r\n}\r\n\r\n@media (min-width: 480px) {\r\n\t.body {\r\n\t\tpadding: 0 2em;\r\n\t}\r\n}\r\n\r\n@media (min-width: 768px) {\r\n\t.body {\r\n\t\tpadding: 0 1em;\r\n\t}\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": `_home__body__ZCnNB`,
	"tourism": `_home__tourism__vcoJ5`
};
export default ___CSS_LOADER_EXPORT___;
