import * as React from 'react';
import { SVGProps } from 'react';
const CommercialAuthIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		xmlSpace='preserve'
		viewBox='0 0 512 512'
		{...props}
	>
		<path d='M154.8 424.7h202.4c12.8 0 23.2-10.4 23.2-23.2V139.3c0-12.8-10.4-23.2-23.2-23.2h-29.8V94.8c0-4.1-3.4-7.5-7.5-7.5s-7.5 3.4-7.5 7.5v21.3h-48.9V94.8c0-4.1-3.4-7.5-7.5-7.5s-7.5 3.4-7.5 7.5v21.3h-48.9V94.8c0-4.1-3.4-7.5-7.5-7.5s-7.5 3.4-7.5 7.5v21.3h-29.8c-12.8 0-23.2 10.4-23.2 23.2v262.1c0 12.8 10.4 23.3 23.2 23.3zm-8.2-285.4c0-4.5 3.7-8.2 8.2-8.2h29.8v21.5c0 4.1 3.4 7.5 7.5 7.5s7.5-3.4 7.5-7.5v-21.5h48.9v21.5c0 4.1 3.4 7.5 7.5 7.5s7.5-3.4 7.5-7.5v-21.5h48.9v21.5c0 4.1 3.4 7.5 7.5 7.5s7.5-3.4 7.5-7.5v-21.5h29.8c4.5 0 8.2 3.7 8.2 8.2v262.1c0 4.5-3.7 8.2-8.2 8.2H154.8c-4.5 0-8.2-3.7-8.2-8.2V139.3z' />
		<path d='M181.9 219.4c1.5 1.5 3.4 2.2 5.3 2.2s3.8-.7 5.3-2.2l26.5-26.5c2.9-2.9 2.9-7.7 0-10.6-2.9-2.9-7.7-2.9-10.6 0l-21.2 21.2-7.7-7.7c-2.9-2.9-7.7-2.9-10.6 0-2.9 2.9-2.9 7.7 0 10.6l13 13zM238.1 208.3h99.7c4.1 0 7.5-3.4 7.5-7.5s-3.4-7.5-7.5-7.5h-99.7c-4.1 0-7.5 3.4-7.5 7.5s3.4 7.5 7.5 7.5zM181.9 289c1.5 1.5 3.4 2.2 5.3 2.2s3.8-.7 5.3-2.2l26.5-26.5c2.9-2.9 2.9-7.7 0-10.6s-7.7-2.9-10.6 0L187.2 273l-7.7-7.7c-2.9-2.9-7.7-2.9-10.6 0-2.9 2.9-2.9 7.7 0 10.6l13 13.1zM238.1 277.9h99.7c4.1 0 7.5-3.4 7.5-7.5s-3.4-7.5-7.5-7.5h-99.7c-4.1 0-7.5 3.4-7.5 7.5s3.4 7.5 7.5 7.5zM181.9 358.5c1.5 1.5 3.4 2.2 5.3 2.2s3.8-.7 5.3-2.2L219 332c2.9-2.9 2.9-7.7 0-10.6s-7.7-2.9-10.6 0l-21.2 21.2-7.7-7.7c-2.9-2.9-7.7-2.9-10.6 0-2.9 2.9-2.9 7.7 0 10.6l13 13zM238.1 347.5h99.7c4.1 0 7.5-3.4 7.5-7.5s-3.4-7.5-7.5-7.5h-99.7c-4.1 0-7.5 3.4-7.5 7.5s3.4 7.5 7.5 7.5z' />
	</svg>
);
export default CommercialAuthIcon;
