import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../app/store';
import { AnyAction } from 'redux';
import { User } from '../models';
import { Envelope } from '../types/envelope.type';
import * as usersServices from './users.service';
import { setUsers } from '../app/states/users';

export async function getUsersController(
	dispatch: ThunkDispatch<RootState, undefined, AnyAction>,
	queries?: {
		order?: 'asc' | 'desc';
		page?: number;
		items_per_page?: number;
	}
) {
	const order = queries?.order ?? 'asc';
	const page = queries?.page ? Number(queries.page) : 1;
	const items_per_page = queries?.items_per_page
		? Number(queries.items_per_page)
		: 10;

	const response: Envelope<User[]> = {
		success: true,
		data: null,
		error: null,
		pagination: null,
	};

	try {
		const {
			count,
			error: countError,
			status: countStatus,
		}: usersServices.AllUsersCountResponse = await usersServices.getAllUsersCountService();

		if (countError) {
			response.success = false;
			response.error = {
				status: countStatus,
				message: 'Hubo un error, por favor intenta nuevamente.',
				other_info: countError,
			};
		}

		const { data, error, status }: usersServices.GetAllUsersResponse =
			await usersServices.getAllUsersService(order, page, items_per_page);

		if (error) {
			response.success = false;
			response.error = {
				status,
				message: 'Hubo un error, por favor intenta nuevamente.',
				other_info: error,
			};
		}

		if (data && count) {
			response.data = data;
			response.pagination = {
				page,
				items_per_page,
				total_pages: Math.ceil(count / items_per_page),
				total_items: count,
			};

			return dispatch(setUsers(response));
		}

		return dispatch(setUsers(response));
	} catch (error) {
		console.error(error);
		response.success = false;
		response.error = {
			status: 500,
			message: 'Hubo un error de servidor, por favor intenta nuevamente.',
			other_info: error,
		};
		return dispatch(setUsers(response));
	}
}

// export async function getUserByIdController(
//     dispatch: ThunkDispatch<RootState, undefined, AnyAction>,
//     id: string,
// ) {
// 	const response: Envelope<User> = {
// 		success: true,
// 		data: null,
// 		error: null,
// 		pagination: null,
// 	};

// 	try {
// 		const { data, error, status }: usersServices.GetUserByIdResponse =
// 			await usersServices.getUserById(id);

// 		if (error) {
//             response.success = false;
// 			response.error = {
// 				status,
// 				message: 'Hubo un error, por favor intenta nuevamente.',
// 				other_info: error,
// 			};
//         }

// 		if (data) {
// 			response.data = data[0];

// 			return dispatch(setUser);
// 		}

// 		return dispatch(setUser);
// 	} catch (error) {
// 		if (error instanceof UsersError) {
// 			return res.status(+error.code).json(error);
// 		} else {
// 			return res.status(500).json({
// 				description: 'Error de servidor',
// 			});
// 		}
// 	}
// }
