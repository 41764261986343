import { useEffect, useState } from 'react';
import PinkShield from '../../assets/svg/PinkShield';
import Loader from '../../components/loader/Loader';
import Title from '../../components/Titlte/Title';
import { Secretaries } from '../../models/gobermentInfo.model';
import { getSecretariesInfo } from '../../services/gobermentInfo.service';
import Hero from '../Home/components/Hero/Hero';
import styles from './_gobermentInfo.css';

const GobermentInfo = () => {
	const [secretaries, setSecretaries] = useState<Secretaries[]>([]);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setIsLoading(true);
		getSecretariesInfo()
			.then((data) => data && setSecretaries(data))
			.catch((e) => console.error(e))
			.finally(() => {
				setTimeout(() => {
					setIsLoading(false);
				}, 1000);
			});
	}, []);

	const frontClass = [styles.face, styles.front].join(' ');
	const backClass = [styles.face, styles.back].join(' ');

	return (
		<div>
			{isLoading && <Loader />}
			<Hero />
			<section className={styles.services}>
				<div className={styles.subServices}>
					<Title text='Autoridades' />
				</div>
				<div className={styles.authorities}>
					<div className={styles.cardAuthorities}>
						<h4>INTENDENTE</h4>
						<p>Dora Rodriguez</p>
					</div>
					<div className={styles.cardAuthorities}>
						<h4>VICEINTENDENTE</h4>
						<p>Martín Godoy</p>
					</div>
				</div>
				<div className={styles.subServices}>
					<Title text='Áreas de Gobierno' />
				</div>
				<div className={styles.containerS}>
					{secretaries.map((item) => (
						<div key={item.id} className={styles.boxS}>
							<div className={frontClass}>
								<PinkShield className={styles.logo} />
								<h3>{item.secretarial_name}</h3>
							</div>
							<div className={backClass}>
								{item.secretary ? (
									<div>
										<p>
											<b>Dirección: </b>
											{item.address}
										</p>
										<p>
											<b>Horario: </b>
											{item.schedule}
										</p>
										{item.email && (
											<p>
												<b>Email: </b>
												{item.email}
											</p>
										)}
										<p>
											<b>Secretario/a: </b>
											{item.secretary}
										</p>
									</div>
								) : (
									<p>No hay información adicional</p>
								)}
							</div>
						</div>
					))}
				</div>
			</section>
		</div>
	);
};
export default GobermentInfo;
