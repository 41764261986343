import { createSlice } from '@reduxjs/toolkit';
import { NewsWithRelations } from '../../models/news.model';
import { Envelope } from '../../types/envelope.type';

export const EmptyNoticeState: Envelope<NewsWithRelations> = {
	success: false,
	data: null,
	error: null,
	pagination: null,
};

export const noticeSlice = createSlice({
	name: 'notice',
	initialState: EmptyNoticeState,
	reducers: {
		setNotice: (_state, action) => {
			return action.payload;
		},
	},
});

export const { setNotice } = noticeSlice.actions;

export default noticeSlice.reducer;
