import { supabase } from '../database/supabase';
import { RentWithTaxesAndContributions } from '../models/rents.model';
import {
	InsertRentRelations,
	ReqBodyForCreateRent,
	ReqBodyForUpdateRent,
} from '../types/rents.type';

export const getAllRentsCountService = async () => {
	return await supabase.from('rents').select('*', { count: 'exact' });
};

export const getRentsWithTaxesAndContributionsService = async (
	order: 'asc' | 'desc',
	page: number,
	items_per_page: number,
	sort_by: 'id' | 'created_at' | 'updated_at'
) => {
	return await supabase
		.from('rents')
		.select(`*, rents_tax_and_contr(*), users(*)`)
		.order(sort_by, { ascending: order === 'asc' })
		.range((page - 1) * items_per_page, page * items_per_page - 1);
};

export const getTaxesAndContributionsService = async () => {
	return await supabase.from('taxes_and_contributions').select();
};

export const getRentWithRelationByIdService = async (id: number) => {
	return await supabase
		.from('rents')
		.select(`*, rents_tax_and_contr(*)`)
		.eq('id', id);
};

export const createRentService = async (
	user_id: ReqBodyForCreateRent['user_id'],
	created_at: ReqBodyForCreateRent['created_at']
) => {
	return await supabase.from('rents').insert({ user_id, created_at }).select();
};

export const createRentsRelationsService = async (
	data: InsertRentRelations[]
) => {
	return await supabase.from('rents_tax_and_contr').insert(data);
};

export const updateRentService = async ({
	rent_id,
	rent_data,
}: {
	rent_id: ReqBodyForUpdateRent['id'];
	rent_data: ReqBodyForUpdateRent;
}) => {
	const { user_id, created_at, updated_at } = rent_data;
	return await supabase
		.from('rents')
		.update({ user_id, created_at, updated_at })
		.eq('id', rent_id)
		.select(`*, rents_tax_and_contr(*)`);
};

export const getLastRentRelationService = async () => {
	return await supabase
		.from('rents_tax_and_contr')
		.select('id')
		.order('id', { ascending: false })
		.limit(1);
};

export const upsertRentRelationsService = async (
	data: ReqBodyForUpdateRent['rents_tax_and_contr']
) => {
	return await supabase.from('rents_tax_and_contr').upsert(data).select();
};

export const deleteRentRelationService = async (ids: number[]) => {
	return await supabase.from('rents_tax_and_contr').delete().in('id', ids);
};

export const deleteRentWithRelationsService = async (
	rent_id: RentWithTaxesAndContributions['id']
) => {
	return await supabase.from('rents').delete().eq('id', rent_id);
};

export const getRentsRelationsService = async (input: {
	start_date: string;
	end_date: string;
}) => {
	return await supabase
		.from('rents_tax_and_contr')
		.select()
		.gte('created_at', input.start_date)
		.lte('created_at', input.end_date);
};

/* --- Tipados de las funciones de Supabase --- */

// Contador de rentas
export type GetAllRentsCountServiceResponse = Awaited<
	ReturnType<typeof getAllRentsCountService>
>;

// Petición de rentas
export type GetRentsWithTaxesAndContributionsResponse = Awaited<
	ReturnType<typeof getRentsWithTaxesAndContributionsService>
>;

// Petición de Tasas y Contribuciones
export type GetTaxesAndContributionsResponse = Awaited<
	ReturnType<typeof getTaxesAndContributionsService>
>;

// Petición de una renta por Id
export type GetRentWithRelationByIdServiceResponse = Awaited<
	ReturnType<typeof getRentWithRelationByIdService>
>;

// Crear una renta
export type CreateRentResponse = Awaited<ReturnType<typeof createRentService>>;

// Crear los registros de las relaciones entre Rentas y las Tasas y Contribuciones
export type CreateRentsRelationsServiceResponse = Awaited<
	ReturnType<typeof createRentsRelationsService>
>;

// Actualizar una renta
export type UpdateRentServiceResponse = Awaited<
	ReturnType<typeof updateRentService>
>;

// Obtener el último Id de las relaciones de Rentas
export type GetLastRentRelationServiceResponse = Awaited<
	ReturnType<typeof getLastRentRelationService>
>;

// Actualizar las relaciones de una renta
export type UpsertRentRelationsServiceResponse = Awaited<
	ReturnType<typeof upsertRentRelationsService>
>;

// Actualizar las relaciones de una renta
export type DeleteRentRelationServiceResponse = Awaited<
	ReturnType<typeof deleteRentRelationService>
>;

// Eliminar una renta y sus correspondientes relaciones
export type DeleteRentWithRelationsServiceResponse = Awaited<
	ReturnType<typeof deleteRentWithRelationsService>
>;

// Obtener las relaciones de una renta dentro de un rango de fechas
export type GetRentsRelationsServiceResponse = Awaited<
	ReturnType<typeof getRentsRelationsService>
>;
