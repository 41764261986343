import * as React from 'react';
import { SVGProps } from 'react';
import styles from './Loader.module.scss';

const Loader = (props: SVGProps<SVGSVGElement>) => (
	<div className={styles.loaderContainer}>
		<svg className={styles.pl} width={240} height={240} {...props}>
			<circle
				className={[styles.pl__ring, styles.pl__ring__a].join(' ')}
				cx={120}
				cy={120}
				r={105}
				fill='none'
				stroke='#000'
				strokeWidth={20}
				strokeDasharray='0 660'
				strokeDashoffset={-330}
				strokeLinecap='round'
			/>
			<circle
				className={[styles.pl__ring, styles.pl__ring__b].join(' ')}
				cx={120}
				cy={120}
				r={35}
				fill='none'
				stroke='#000'
				strokeWidth={20}
				strokeDasharray='0 220'
				strokeDashoffset={-110}
				strokeLinecap='round'
			/>
			<circle
				className={[styles.pl__ring, styles.pl__ring__c].join(' ')}
				cx={85}
				cy={120}
				r={70}
				fill='none'
				stroke='#000'
				strokeWidth={20}
				strokeDasharray='0 440'
				strokeLinecap='round'
			/>
			<circle
				className={[styles.pl__ring, styles.pl__ring__d].join(' ')}
				cx={155}
				cy={120}
				r={70}
				fill='none'
				stroke='#000'
				strokeWidth={20}
				strokeDasharray='0 440'
				strokeLinecap='round'
			/>
		</svg>
	</div>
);

export default Loader;
