import { configureStore } from '@reduxjs/toolkit';
import userSliceReducer from './states/user';
import newsSliceReducer from './states/news';
import newsCategoriesSliceReducer from './states/newsCategories';
import uiSliceReducer from './states/uiState';
import tourismSliceReducer from './states/tourism';
import usersSliceReducer from './states/users';
import rentsSliceReducer from './states/rents';
import rentsRelationsSliceReducer from './states/rentsRelations';
import taxAndContributionsReducer from './states/taxAndContributions';
import noticeSliceReducer from './states/notice';
import individualTourismReducer from './states/individualTourism';

export const store = configureStore({
	reducer: {
		individualTourism: individualTourismReducer,
		news: newsSliceReducer,
		newsCategories: newsCategoriesSliceReducer,
		notice: noticeSliceReducer,
		rents: rentsSliceReducer,
		rentsRelations: rentsRelationsSliceReducer,
		taxAndContributions: taxAndContributionsReducer,
		tourism: tourismSliceReducer,
		ui: uiSliceReducer,
		user: userSliceReducer,
		users: usersSliceReducer,
	},
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
