// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h2 {
	font-size: 2rem;
	font-weight: 750;
	color: #009ee2;
	margin: 2rem 0;
}

._UsefulPhones__services__kXUK7 {
	width: 100%;
	padding: 1rem 0;
}
._UsefulPhones__subServices__l5q0v {
	text-align: center;
	padding-bottom: 2rem;
	color: #4c4c4c;
}
._UsefulPhones__containerS__zRvGD {
	width: 100%;
	margin: 0 auto;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
	grid-gap: 0.5rem;
}
._UsefulPhones__boxS__g3pIW {
	padding: 1rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-radius: 0.5em;
	box-shadow: 0px 0px 55px 5px rgb(0 0 0 / 6%);
}
._UsefulPhones__boxS__g3pIW img {
	width: 100px;
}

h3 {
	color: #009ee2;
	margin: 2px 0;
	font-family: 'Lato', sans-serif;
	font-size: 15px;
	line-height: 15px;
	font-weight: 700;
	margin-top: 0;
	text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Home/components/UsefulPhones/_UsefulPhones.css"],"names":[],"mappings":"AAAA;CACC,eAAe;CACf,gBAAgB;CAChB,cAAc;CACd,cAAc;AACf;;AAEA;CACC,WAAW;CACX,eAAe;AAChB;AACA;CACC,kBAAkB;CAClB,oBAAoB;CACpB,cAAc;AACf;AACA;CACC,WAAW;CACX,cAAc;CACd,aAAa;CACb,2DAA2D;CAC3D,gBAAgB;AACjB;AACA;CACC,aAAa;CACb,aAAa;CACb,sBAAsB;CACtB,mBAAmB;CACnB,uBAAuB;CACvB,oBAAoB;CACpB,4CAA4C;AAC7C;AACA;CACC,YAAY;AACb;;AAEA;CACC,cAAc;CACd,aAAa;CACb,+BAA+B;CAC/B,eAAe;CACf,iBAAiB;CACjB,gBAAgB;CAChB,aAAa;CACb,kBAAkB;AACnB","sourcesContent":["h2 {\r\n\tfont-size: 2rem;\r\n\tfont-weight: 750;\r\n\tcolor: #009ee2;\r\n\tmargin: 2rem 0;\r\n}\r\n\r\n.services {\r\n\twidth: 100%;\r\n\tpadding: 1rem 0;\r\n}\r\n.subServices {\r\n\ttext-align: center;\r\n\tpadding-bottom: 2rem;\r\n\tcolor: #4c4c4c;\r\n}\r\n.containerS {\r\n\twidth: 100%;\r\n\tmargin: 0 auto;\r\n\tdisplay: grid;\r\n\tgrid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));\r\n\tgrid-gap: 0.5rem;\r\n}\r\n.boxS {\r\n\tpadding: 1rem;\r\n\tdisplay: flex;\r\n\tflex-direction: column;\r\n\talign-items: center;\r\n\tjustify-content: center;\r\n\tborder-radius: 0.5em;\r\n\tbox-shadow: 0px 0px 55px 5px rgb(0 0 0 / 6%);\r\n}\r\n.boxS img {\r\n\twidth: 100px;\r\n}\r\n\r\nh3 {\r\n\tcolor: #009ee2;\r\n\tmargin: 2px 0;\r\n\tfont-family: 'Lato', sans-serif;\r\n\tfont-size: 15px;\r\n\tline-height: 15px;\r\n\tfont-weight: 700;\r\n\tmargin-top: 0;\r\n\ttext-align: center;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"services": `_UsefulPhones__services__kXUK7`,
	"subServices": `_UsefulPhones__subServices__l5q0v`,
	"containerS": `_UsefulPhones__containerS__zRvGD`,
	"boxS": `_UsefulPhones__boxS__g3pIW`
};
export default ___CSS_LOADER_EXPORT___;
