import { createSlice } from '@reduxjs/toolkit';
import { Envelope } from '../../types/envelope.type';
import { User } from '../../models';

export const EmptyUsersState: Envelope<User[]> = {
	success: true,
	data: [],
	error: null,
	pagination: null,
};

export const usersSlice = createSlice({
	name: 'users',
	initialState: EmptyUsersState,
	reducers: {
		setUsers: (_state, action) => {
			return action.payload;
		},
	},
});

export const { setUsers } = usersSlice.actions;

export default usersSlice.reducer;
