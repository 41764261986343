import React, { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Modal, Upload, message } from 'antd';
import type { RcFile, UploadProps } from 'antd/es/upload';
import type { UploadFile } from 'antd/es/upload/interface';
import {
	deleteImg,
	getUploadUrl,
	uploadFileByUrl,
} from '../../services/dbStorage.service';
import { reemplazarAcentos } from '../../utilities/format.utility';

const getBase64 = (file: RcFile): Promise<string> =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result as string);
		reader.onerror = (error) => reject(error);
	});

export interface ImageUploadProps {
	bucketId: string;
	bucketName: string;
	maxLength?: number;
	maxCount?: number;
	value?: string[];
	onChange?: (value: string[]) => void;
}

const ImageUpload: React.FC<ImageUploadProps> = ({
	bucketId,
	bucketName,
	maxLength,
	maxCount,
	value,
	onChange,
}) => {
	const [messageApi, contextHolder] = message.useMessage();

	const [previewOpen, setPreviewOpen] = useState(false);
	const [previewImage, setPreviewImage] = useState('');
	const [previewTitle, setPreviewTitle] = useState('');
	const [fileList, setFileList] = useState<UploadFile[]>([]);

	const [uploadUrl, setUploadUrl] = useState<string | null>(null);

	const [imgUrl, setImgUrl] = useState<string[]>([]);

	const errorEvent = async (message: string) => {
		await messageApi.open({
			type: 'error',
			content: message,
		});
	};

	const handleCancel = () => setPreviewOpen(false);

	const triggerChange = (changedValue: {
		imgUrlToAdd?: string;
		imgNameToDelete?: string;
	}) => {
		if (changedValue.imgUrlToAdd) {
			onChange?.([...imgUrl, changedValue.imgUrlToAdd]);
		} else if (changedValue.imgNameToDelete) {
			const imgUrlToDeleteFounded = imgUrl.find((img) =>
				img.includes(changedValue.imgNameToDelete ?? '')
			);

			if (imgUrlToDeleteFounded) {
				const urlsFiltered = imgUrl.filter(
					(img) => img !== imgUrlToDeleteFounded
				);
				setImgUrl(urlsFiltered);
				onChange?.(urlsFiltered);
			}
		}
	};

	const handlePreview = async (file: UploadFile) => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj as RcFile);
		}

		setPreviewImage(file.url ?? (file.preview as string));
		setPreviewOpen(true);
		setPreviewTitle(
			file.name ?? file.url?.substring(file.url?.lastIndexOf('/') + 1)
		);
	};

	const handleChange: UploadProps['onChange'] = ({
		file,
		fileList: newFileList,
	}) => {
		const imgFoundedByUid = fileList.find((img) => img.uid === file.uid);
		const imgFoundedByName = fileList.find((img) => img.name === file.name);

		if (imgFoundedByUid) {
			triggerChange({
				imgNameToDelete: encodeURI(reemplazarAcentos(imgFoundedByUid?.name)),
			});
			deleteImg(bucketName, [
				`${bucketId}/${reemplazarAcentos(file.name)}`,
			]).catch((e) => console.error(e));
			return setFileList((prev) => prev.filter((img) => img.uid !== file.uid));
		} else if (imgFoundedByName) {
			return setFileList((prev) => prev);
		} else {
			setFileList((prev) => {
				return [...prev, ...newFileList];
			});
		}
	};

	const uploadButton = (
		<div>
			<PlusOutlined rev='1' />
			<div style={{ marginTop: 8 }}>Cargar</div>
		</div>
	);

	return (
		<>
			{contextHolder}
			<Upload
				action={uploadUrl ?? ''}
				beforeUpload={async (file) => {
					try {
						const { signedUrl, token, path, error } = await getUploadUrl({
							bucketName,
							path: `${bucketId}/${reemplazarAcentos(file.name)}`,
						});
						if (error) {
							await errorEvent(error);
							return false;
						}

						setUploadUrl(signedUrl);

						const url = await uploadFileByUrl({
							bucketName,
							path,
							token,
							file,
						});
						setImgUrl((prev) => [...prev, url]);
						triggerChange({ imgUrlToAdd: url });

						return false;
					} catch (error) {
						console.error(error);
					}
				}}
				listType='picture-card'
				fileList={fileList}
				onPreview={handlePreview}
				onChange={handleChange}
				maxCount={maxCount}
			>
				{fileList.length >= (maxLength ?? 10) ? null : uploadButton}
			</Upload>
			<Modal
				open={previewOpen}
				title={previewTitle}
				footer={null}
				onCancel={handleCancel}
			>
				<img
					alt='previsualización de imagen'
					style={{ width: '100%' }}
					src={previewImage}
				/>
			</Modal>
		</>
	);
};

export default ImageUpload;
