// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._tourismCard__container__QhDrB {
	max-width: 100%;
	max-height: fit-content;
	display: flex;
	flex-direction: column;
	padding: 8px;
	border-radius: 16px;
	background-color: white;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
	box-shadow: 0 0.25em 0.75em rgba(0, 0, 0, 0.2);
}

._tourismCard__container__QhDrB:hover {
	transform: scale(105%);
}

._tourismCard__imgContainer__oIzV3 {
	flex: 1;
}

._tourismCard__imgContainer__oIzV3 img {
	width: 100%;
	height: auto;
	border-radius: 8px;
	object-fit: cover;
}

._tourismCard__textContainer__qUn2I {
	flex: 1;
	justify-content: flex-start;
}

._tourismCard__textContainer__qUn2I h3 {
	font-family: var(--font-family);
	font-size: 16px;
	font-weight: 600;
	margin-top: 5px;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

._tourismCard__textContainer__qUn2I p {
	font-family: var(--font-family);
	color: var(--graySix);
	margin-top: 5px;
	font-size: 14px;
	line-height: 1em;
	font-weight: 400;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 4;
	-webkit-box-orient: vertical;
}
`, "",{"version":3,"sources":["webpack://./src/components/TourismCard/_tourismCard.css"],"names":[],"mappings":"AAAA;CACC,eAAe;CACf,uBAAuB;CACvB,aAAa;CACb,sBAAsB;CACtB,YAAY;CACZ,mBAAmB;CACnB,uBAAuB;CACvB,eAAe;CACf,gCAAgC;CAChC,8CAA8C;AAC/C;;AAEA;CACC,sBAAsB;AACvB;;AAEA;CACC,OAAO;AACR;;AAEA;CACC,WAAW;CACX,YAAY;CACZ,kBAAkB;CAClB,iBAAiB;AAClB;;AAEA;CACC,OAAO;CACP,2BAA2B;AAC5B;;AAEA;CACC,+BAA+B;CAC/B,eAAe;CACf,gBAAgB;CAChB,eAAe;CACf,gBAAgB;CAChB,oBAAoB;CACpB,qBAAqB;CACrB,4BAA4B;AAC7B;;AAEA;CACC,+BAA+B;CAC/B,qBAAqB;CACrB,eAAe;CACf,eAAe;CACf,gBAAgB;CAChB,gBAAgB;CAChB,gBAAgB;CAChB,oBAAoB;CACpB,qBAAqB;CACrB,4BAA4B;AAC7B","sourcesContent":[".container {\r\n\tmax-width: 100%;\r\n\tmax-height: fit-content;\r\n\tdisplay: flex;\r\n\tflex-direction: column;\r\n\tpadding: 8px;\r\n\tborder-radius: 16px;\r\n\tbackground-color: white;\r\n\tcursor: pointer;\r\n\ttransition: all 0.2s ease-in-out;\r\n\tbox-shadow: 0 0.25em 0.75em rgba(0, 0, 0, 0.2);\r\n}\r\n\r\n.container:hover {\r\n\ttransform: scale(105%);\r\n}\r\n\r\n.imgContainer {\r\n\tflex: 1;\r\n}\r\n\r\n.imgContainer img {\r\n\twidth: 100%;\r\n\theight: auto;\r\n\tborder-radius: 8px;\r\n\tobject-fit: cover;\r\n}\r\n\r\n.textContainer {\r\n\tflex: 1;\r\n\tjustify-content: flex-start;\r\n}\r\n\r\n.textContainer h3 {\r\n\tfont-family: var(--font-family);\r\n\tfont-size: 16px;\r\n\tfont-weight: 600;\r\n\tmargin-top: 5px;\r\n\toverflow: hidden;\r\n\tdisplay: -webkit-box;\r\n\t-webkit-line-clamp: 2;\r\n\t-webkit-box-orient: vertical;\r\n}\r\n\r\n.textContainer p {\r\n\tfont-family: var(--font-family);\r\n\tcolor: var(--graySix);\r\n\tmargin-top: 5px;\r\n\tfont-size: 14px;\r\n\tline-height: 1em;\r\n\tfont-weight: 400;\r\n\toverflow: hidden;\r\n\tdisplay: -webkit-box;\r\n\t-webkit-line-clamp: 4;\r\n\t-webkit-box-orient: vertical;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `_tourismCard__container__QhDrB`,
	"imgContainer": `_tourismCard__imgContainer__oIzV3`,
	"textContainer": `_tourismCard__textContainer__qUn2I`
};
export default ___CSS_LOADER_EXPORT___;
