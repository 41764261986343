import { createSlice } from '@reduxjs/toolkit';
import { RentWithAllRelations } from '../../models/rents.model';
import { Envelope } from '../../types/envelope.type';

export const EmptyRentsState: Envelope<RentWithAllRelations[]> = {
	success: false,
	data: [],
	error: null,
	pagination: {
		page: 1,
		items_per_page: 5,
	},
};

export const rentsSlice = createSlice({
	name: 'rents',
	initialState: EmptyRentsState,
	reducers: {
		setRents: (_state, action) => {
			return action.payload;
		},
	},
});

export const { setRents } = rentsSlice.actions;

export default rentsSlice.reducer;
